import { Inertia } from '@inertiajs/inertia';
import { Box, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import ChartDashboard from '../../Components/ChartDashboard';
import MainLayout from '../../Layouts/MainLayout';
import { portfolioResultState, userLicenceState } from '../../Recoil/Atoms/PortfolioAtoms';
import { trackEvent } from '../../Services/PortfolioService';

const Warning = ({}) => {
  const userLicence = useRecoilValue(userLicenceState);
  const [portfolioResult, setPortfolioResult] = useRecoilState(portfolioResultState);

  useEffect(() => {
    if (!userLicence) {
      Inertia.get('/');
    }
    trackEvent('Warning opened', userLicence.id);
  }, []);

  const onNext = () => Inertia.get(route('portfolio.index'));
  const onBack = () => Inertia.get(route('archive.index'));

  return (
    <MainLayout subHeaderTitle="ursprünglicher Portfoliovorschlag" onRightBtnClick={onNext} onLeftBtnClick={onBack} rightBtnName="Neues Portfolio">
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <ChartDashboard portfolioResult={portfolioResult} showKundenprofil={true} />
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'row', bgcolor: '#EBEBEB', borderRadius: '6px', mt: '20px', p: '15px', width: '100%', marginTop: 'auto' }}>
        <Box sx={{ flex: '0 0 auto', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <img src={`/images/Bestehender_Vertrag.png`} alt="" height="101px" />
        </Box>
        <Box sx={{ flex: 1, marginLeft: '15px' }}>
          <Typography variant="h5"> Der ursprüngliche Portfoliovorschlag hat das erwartbare Risiko überschritten. Es bestehen zwei Handlungspoptionen</Typography>
          <Typography>
            <b>1. Portfolioallokation beibehalten:</b>
            <br />
            Das erwartbare Risiko ist zwar überschritten, allerdings befindet es sich innerhalb des aktuellen Risikobudgets des Kunden.
            <br />
            <b>2. Portfolioallokation anpassen:</b>
            <br />
            Sie können das Kundenprofil verändern und so das erwartbare Risiko senken.
          </Typography>
        </Box>
      </Box>
    </MainLayout>
  );
};

export default Warning;
