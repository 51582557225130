import React from "react";
import { Range, getTrackBackground, Direction } from "react-range";
import { Box, Tooltip, Typography } from "@mui/material";
import { useWindowSize } from "../../Hooks/CustomHooks";

const SliderQuestionComponent = ({ id, question, values, step, stepperItems, min, max, direction = "right", onChange, thumbColor, tooltipText }) => {
  const rangeValue = [values[0].value];
  const shouldShowTooltip = values[0]?.label === "sehr sicherheitsorientiert" || values[0]?.label === "stabil";
  const windowSize = useWindowSize();
  const itemWidth = (windowSize.width - 500) / stepperItems.filter((item) => item.label).length;

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Typography sx={{ mb: "4px" }}>{`${id}. ${question}`}</Typography>
      <div style={{ display: "flex", justifyContent: "center", height: 60 }}>
        <Range
          direction={direction === "left" ? Direction.Left : Direction.Right}
          values={rangeValue}
          step={step}
          min={min}
          max={max}
          onChange={(rangeValue) => onChange(stepperItems.find((x) => x.value === rangeValue[0]))}
          renderMark={({ props, index }) => {
            if (stepperItems[index] && stepperItems[index].label) {
              return (
                <div
                  {...props}
                  style={{
                    ...props.style,
                    height: "25px",
                    width: "25px",
                    borderRadius: "50%",
                    backgroundColor: "#ccc",
                    outline: "0",
                  }}
                >
                  <div style={{ position: "absolute", top: 30, left: -itemWidth / 2 + 10, width: itemWidth, textAlign: "center", display: "flex", flexDirection: "column" }}>
                    <div>{stepperItems[index].label}</div>
                    {stepperItems[index].label2 && <div style={{ fontSize: 13 }}>{stepperItems[index].label2}</div>}
                    {stepperItems[index].label3 && <div style={{ fontSize: 13 }}>{stepperItems[index].label3}</div>}
                  </div>
                </div>
              );
            }
            if (stepperItems[index].onlyMark) {
              return (
                <div
                  {...props}
                  style={{
                    ...props.style,
                    height: "16px",
                    width: "2px",
                    backgroundColor: "#ccc",
                    outline: "0",
                  }}
                />
              );
            }
            return <div {...props} />;
          }}
          renderTrack={({ props, children }) => (
            <div
              onMouseDown={props.onMouseDown}
              onTouchStart={props.onTouchStart}
              style={{
                ...props.style,
                height: "36px",
                display: "flex",
                width: "calc(100% - 80px)",
              }}
            >
              <div
                ref={props.ref}
                style={{
                  height: "4px",
                  width: "100%",
                  borderRadius: "4px",
                  background: getTrackBackground({
                    values: rangeValue,
                    colors: ["#ccc", "#ccc"],
                    min: min,
                    max: max,
                  }),
                  alignSelf: "center",
                }}
              >
                {children}
              </div>
            </div>
          )}
          renderThumb={({ props }) => (
            <Tooltip key={props.key} title={tooltipText || ""} arrow open={shouldShowTooltip}>
              <div
                {...props}
                style={{
                  ...props.style,
                  height: "30px",
                  width: "30px",
                  borderRadius: "50%",
                  backgroundColor: thumbColor || "#C94141",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  outline: "0",
                }}
              />
            </Tooltip>
          )}
        />
      </div>
    </Box>
  );
};

export default SliderQuestionComponent;
