import { InertiaApp } from '@inertiajs/inertia-react';
import { createTheme, responsiveFontSizes, ThemeProvider } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';
import React from 'react';
import { appPalette, appTheme } from './theme/theme';

const Main = () => {
  const el = document.getElementById('app');
  const muiTheme = React.useMemo(() => {
    let theme = { ...appTheme };

    theme.palette = appPalette;
    theme = createTheme(theme);
    return responsiveFontSizes(theme, {
      breakpoints: ['sm', 'md'],
      factor: 2,
      variants: ['h1', 'h2', 'h3']
    });
  }, []);

  return (
    <ThemeProvider theme={muiTheme}>
      <SnackbarProvider maxSnack={3} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
        <InertiaApp initialPage={JSON.parse(el.dataset.page)} resolveComponent={(name) => require(`./Pages/${name}`).default} />
      </SnackbarProvider>
    </ThemeProvider>
  );
};

export default Main;
