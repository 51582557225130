import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogActions, DialogTitle, DialogContent, Box } from '@mui/material';
import zIndex from '@mui/material/styles/zIndex';

ContentDialogInit.propTypes = {};

export function ContentDialogInit(props) {
  const [openCD, setOpenCD] = useState(false);
  const [tca, setTca] = useState({});
  const [CDActions, setCDActions] = useState(null);

  return {
    openCD,
    setOpenCD,
    tca,
    setTca,
    CDActions,
    setCDActions
  };
}

ContentDialog.propTypes = {
  openCD: PropTypes.bool.isRequired,
  setOpenCD: PropTypes.func.isRequired,
  tca: PropTypes.object,
  maxWidth: PropTypes.string,
  centered: PropTypes.bool,
  CDActions: PropTypes.object
};

ContentDialog.defaultProps = {
  centered: false,
  CDActions: null
};

export function ContentDialog(props) {
  const { openCD, setOpenCD, tca, centered, CDActions } = props;

  const handleClose = () => {
    setOpenCD(false);
  };

  return (
    <Dialog
      open={openCD}
      onClose={handleClose}
      maxWidth={props.tca.maxWidth || props.maxWidth || 'md'}
      fullWidth={props.notFullWidth ? false : true}
      PaperProps={{ sx: { borderRadius: '16px', height: '500px' } }}>
      <DialogTitle>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          {tca?.title}
          {CDActions?.top && CDActions.top}
        </Box>
      </DialogTitle>
      <DialogContent
        dividers
        sx={
          centered
            ? {
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center'
              }
            : {}
        }>
        {tca?.content}
      </DialogContent>
      {tca.actions && <DialogActions sx={{ padding: '16px' }}>{tca?.actions}</DialogActions>}
      {CDActions?.bottom && <DialogActions sx={{ padding: '16px' }}>{CDActions.bottom}</DialogActions>}
    </Dialog>
  );
}
