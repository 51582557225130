import axios from 'axios';

export const getLicenceFromKey = async (key) => {
  try {
    const response = await axios.get(`/api/licence/${key}`);
    return response.data.licence;
  } catch (error) {}
};

export const getTarifList = async (insurance_company_ids) => {
  try {
    const response = await axios.post('/api/tariflist', { insurance_company_ids });
    return {
      insuranceCompanies: response.data.insuranceCompanies,
      tarifList: response.data.tarifList
    };
  } catch (error) {}
};

export const calculate = async (insurance_company_id, tarif, jahren, zahlweise, kundenprofil, umsetzung, esg, nutze_sicherungsvermoegen) => {
  try {
    const response = await axios.post('/api/portfolio/calculate', { insurance_company_id, tarif, jahren, zahlweise, kundenprofil, umsetzung, esg, nutze_sicherungsvermoegen });
    const result = response.data.result;
    result.input = response.data.input;
    return result;
  } catch (error) {}
};

export const compare = async (jahren, zahlweise, kundenprofil, umsetzung, esg) => {
  try {
    const response = await axios.post('/portfolio/compare', { jahren, zahlweise, kundenprofil, umsetzung, esg });
    return response.data.result;
  } catch (error) {}
};

export const trackEvent = async (trigger, licence_id) => {
  try {
    const response = await axios.post('/api/tracking', { trigger, licence_id });
    return response.data.success;
  } catch (error) {}
};
