import MainLayout from '../../Layouts/MainLayout'
import { wpState } from '../../Recoil/Atoms/PortfolioAtoms'
import { useRecoilValue } from 'recoil'
import React, { useEffect, useState } from 'react'

const InvestmentInfo = ({ label }) => {
  const wp = useRecoilValue(wpState)
  const [item, setItem] = useState({})

  useEffect(() => {
    if (wp) {
      const menu = wp.menus.find(menu => menu.navLabel == 'Investment')
      const item = menu.items.find(item => item.label == label)
      setItem(item);
    }
  }, [wp])

  return (
    <MainLayout subHeaderTitle={item.content?.name}>
      <div dangerouslySetInnerHTML={{ __html: item.content?.seiteninhalt }} />
    </MainLayout>
  )
}

export default InvestmentInfo