import React from 'react'
import { Box, IconButton, Tooltip, Typography } from '@mui/material'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'

const SubHeader = ({ title, infoText }) => {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'row', pt: '20px', pb: '30px' }}>
            <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
                {title}
            </Typography>
            {
                infoText &&
                <Tooltip title={infoText} arrow>
                    <IconButton sx={{ ml: '10px' }}>
                        <InfoOutlinedIcon />
                    </IconButton>
                </Tooltip>
            }
        </Box>
    )
}

export default SubHeader