import React, { useEffect } from "react";
import { Inertia } from "@inertiajs/inertia";
import MainLayout from "../../Layouts/MainLayout";
import { Box, Checkbox } from "@mui/material";
import { BREADCRUMBS_PORTFOLIO } from "../../Utils/Constants";
import { useRecoilState, useRecoilValue } from "recoil";
import { risikoProfilingState, umsetzungState, whiteLabelState } from "../../Recoil/Atoms/PortfolioAtoms";
import { umsetzungPassivState } from "../../Recoil/Selectors/PortfolioSelectors";
import AktivPassivSVG from "../../Components/AktivPassivSVG";

const ETF = ({ visited }) => {
  const [umsetzung, setUmsetzung] = useRecoilState(umsetzungState);
  const passivDisabled = useRecoilValue(umsetzungPassivState);
  const risikoProfiling = useRecoilValue(risikoProfilingState);
  const whiteLabel = useRecoilValue(whiteLabelState);
  const rightBtnDisabled = umsetzung === undefined;

  useEffect(() => {
    if (risikoProfiling.question3.answer === undefined || risikoProfiling.question4.answer === undefined) {
      Inertia.get("/");
    }
  }, []);

  useEffect(() => {
    if (umsetzung === "Passiv" && passivDisabled) {
      setUmsetzung(undefined);
    }
  }, [umsetzung, passivDisabled]);

  const onNext = () => Inertia.get(route("portfolio.vertragsdetails"));

  const onBack = () => Inertia.get(route("portfolio.ergebnisESG"));

  const handleOnChange = (umsetzung) => () => setUmsetzung(umsetzung);

  return (
    <MainLayout breadcrumbs={BREADCRUMBS_PORTFOLIO} visitedPages={visited} subHeaderTitle="Aktiv / Passiv" onRightBtnClick={onNext} onLeftBtnClick={onBack} rightBtnDisabled={rightBtnDisabled}>
      <Box sx={{ pb: "30px", marginLeft: "30px", marginRight: "30px", width: "90%" }}>
        <Box>
          Hier können Sie entscheiden, ob Ihr Portfoliovorschlag aus einer Mischung von aktiv gemanagten Fonds, passiven Fonds und ETFs oder ausschließlich aus ETFs und passiven Fonds zusammengesetzt
          werden soll. Sollte eine der Auswahlmöglichkeiten nicht anwählbar sein, ist hierfür aktuell kein individuelles Portfolio abrufbar.
        </Box>
        {whiteLabel.canSelectNurAktiv && (
          <Box>
            <b>Beraterhinweis</b>
            <br />
            Die Auswahl passiver Fonds, wie z. B. ETF, ist ab folgenden Bedingungswerken der Tarife seit 10/2017 möglich:
            <ul>
              <li>CleVesto Select ab Bedingungswerk L-FXS-8</li>
              <li>CleVesto Favorites I ab Bedingungswerk L-FSI-33</li>
            </ul>
          </Box>
        )}
        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center", mt: "80px" }}>
          {whiteLabel.canSelectNurAktiv ? (
            <Box
              onClick={handleOnChange("nur Aktiv")}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                cursor: passivDisabled ? "default" : "pointer",
                pt: "40px",
                pb: "30px",
                px: "70px",
                mx: "20px",
                bgcolor: `${umsetzung === "nur Aktiv" ? "white" : "#EBEBEB"}`,
                border: `3px solid ${umsetzung === "nur Aktiv" ? "#C94141" : "transparent"}`,
                borderRadius: "12px",
                opacity: 1,
                "&:hover": { border: "3px solid #C94141" },
              }}
            >
              <AktivPassivSVG icon="nur Aktiv" umsetzung={umsetzung} />
              <Box>
                <Checkbox checked={umsetzung === "nur Aktiv"} sx={{ mt: "40px", "& .MuiSvgIcon-root": { fontSize: 40 }, "&.Mui-checked": { color: "#C94141" } }} />
              </Box>
            </Box>
          ) : (
            <></>
          )}
          <Box
            onClick={handleOnChange("Aktiv")}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              cursor: "pointer",
              pt: "40px",
              pb: "30px",
              px: "70px",
              mx: "20px",
              bgcolor: `${umsetzung === "Aktiv" ? "white" : "#EBEBEB"}`,
              border: `3px solid ${umsetzung === "Aktiv" ? "#C94141" : "transparent"}`,
              borderRadius: "12px",
              "&:hover": { border: "3px solid #C94141" },
            }}
          >
            <AktivPassivSVG icon="Aktiv" umsetzung={umsetzung} />
            <Box>
              <Checkbox checked={umsetzung === "Aktiv"} sx={{ mt: "40px", "& .MuiSvgIcon-root": { fontSize: 40 }, "&.Mui-checked": { color: "#C94141" } }} />
            </Box>
          </Box>
          {whiteLabel.canSelectETF ? (
            <Box
              onClick={passivDisabled ? undefined : handleOnChange("Passiv")}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                cursor: passivDisabled ? "default" : "pointer",
                pt: "40px",
                pb: "30px",
                px: "70px",
                mx: "20px",
                bgcolor: `${umsetzung === "Passiv" ? "white" : "#EBEBEB"}`,
                border: `3px solid ${umsetzung === "Passiv" ? "#C94141" : "transparent"}`,
                borderRadius: "12px",
                opacity: passivDisabled ? 0.6 : 1,
                "&:hover": { border: passivDisabled ? "3px solid transparent" : "3px solid #C94141" },
              }}
            >
              <AktivPassivSVG icon="Passiv" umsetzung={umsetzung} />
              <Box>
                <Checkbox checked={umsetzung === "Passiv"} disabled={passivDisabled} sx={{ mt: "40px", "& .MuiSvgIcon-root": { fontSize: 40 }, "&.Mui-checked": { color: "#C94141" } }} />
              </Box>
            </Box>
          ) : (
            <></>
          )}
        </Box>
      </Box>
    </MainLayout>
  );
};

export default ETF;
