import React, { useState } from 'react'
import { Box, Button, Grid } from '@mui/material'
import InputComponent from '../../../Components/Controls/InputComponent'
import { Inertia } from '@inertiajs/inertia'
import AdminLayout from '../../../Layouts/AdminLayout'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'

const Edit = ({ role }) => {
    const [name, setName] = useState(role.name)

    const handleOnChange = e => setName(e.target.value)

    const onUpdate = () => Inertia.put(route('admin.role.update', role.id), { name })

    const onBack = () => Inertia.get(route('admin.roles'))

    return (
        <AdminLayout subHeaderTitle="Rolle bearbeiten">
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Button
                    variant="contained"
                    sx={{
                        bgcolor: 'black',
                        color: 'white',
                        textTransform: 'none',
                        maxWidth: '150px',
                        height: '40px',
                        mb: '20px',
                        '&:hover': { bgcolor: 'black' }
                    }}
                    startIcon={<KeyboardArrowLeftIcon />}
                    onClick={onBack}
                >
                    Zurück
                </Button>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <InputComponent
                            label="Name"
                            name="name"
                            value={name}
                            onChange={handleOnChange}
                        />
                    </Grid>
                </Grid>
                <Button
                    variant="contained"
                    sx={{
                        bgcolor: '#C94141',
                        color: 'white',
                        textTransform: 'none',
                        minWidth: '120px',
                        height: '40px',
                        mt: '20px',
                        '&:hover': { bgcolor: '#C94141' }
                    }}
                    onClick={onUpdate}
                >
                    Aktualisieren
                </Button>
            </Box>
        </AdminLayout>
    )
}

export default Edit