import React, { useState } from 'react'
import { Box, Button, Grid, Typography } from '@mui/material'
import { Inertia } from '@inertiajs/inertia'
import AdminLayout from '../../../Layouts/AdminLayout'
import SelectComponent from '../../../Components/Controls/SelectComponent'
import { usePage } from '@inertiajs/inertia-react'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'

const Create = ({ insuranceCompanies }) => {
    const { errors } = usePage().props
    const [selectedFile, setSelectedFile] = useState(null)
    const [company, setCompany] = useState(null)
    const insuranceCompanyOptions = insuranceCompanies.map(x => { return { label: x.name, value: x.id } })

    const handleOnSelect = company => setCompany(company)

    const onFileChange = event => setSelectedFile(event.target.files[0])

    const onUpload = () => Inertia.post(route('admin.fond.store'), { insurance_company_id: company?.value, file: selectedFile })

    const onBack = () => Inertia.get(route('admin.fonds'))

    return (
        <AdminLayout subHeaderTitle="Fonds hinterlegen">
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Button
                    variant="contained"
                    sx={{
                        bgcolor: 'black',
                        color: 'white',
                        textTransform: 'none',
                        maxWidth: '150px',
                        height: '40px',
                        mb: '20px',
                        '&:hover': { bgcolor: 'black' }
                    }}
                    startIcon={<KeyboardArrowLeftIcon />}
                    onClick={onBack}
                >
                    Zurück
                </Button>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <SelectComponent
                            label="Versicherung"
                            name="company"
                            value={company}
                            onChange={handleOnSelect}
                            options={insuranceCompanyOptions}
                            errorMsg={errors.insurance_company_id}
                        />
                    </Grid>
                    <Grid item xs={8}>
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <Button
                                variant="contained"
                                component="label"
                                sx={{
                                    bgcolor: '#C94141',
                                    color: 'white',
                                    textTransform: 'none',
                                    height: '40px',
                                    minWidth: '150px',
                                    mr: '10px',
                                    '&:hover': { bgcolor: '#C94141' }
                                }}
                            >
                                Fonds auswählen
                                <input
                                    type="file"
                                    hidden
                                    onChange={onFileChange}
                                />
                            </Button>
                            {
                                selectedFile &&
                                <Typography>{selectedFile.name}</Typography>
                            }
                        </Box>
                    </Grid>
                </Grid>
                <Button
                    variant="contained"
                    sx={{
                        bgcolor: '#C94141',
                        color: 'white',
                        textTransform: 'none',
                        minWidth: '120px',
                        height: '40px',
                        mt: '20px',
                        '&:hover': { bgcolor: '#C94141' }
                    }}
                    onClick={onUpload}
                >
                    Erstellen
                </Button>
            </Box>
        </AdminLayout>
    )
}

export default Create