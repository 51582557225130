import axios from 'axios';

export const checkISIN = async (isin) => {
  axios.defaults.baseURL = '/';
  try {
    const res = await axios.get('/samperform/check_isin/' + isin);
    return res.data;
  } catch (error) {
    //console.log('error', error);
    return { status: 'NOK' };
  }
};

export const calculateExisting = async (rows, beginn) => {
  axios.defaults.baseURL = '/';
  try {
    const res = await axios.post('/samperform/calculate_existing', { positionen: rows, beginn: beginn });
    return res.data;
  } catch (error) {
    //console.log('error', error);
    return { status: 'NOK' };
  }
};

export const ocrExtract = async (filedata, filename) => {
  axios.defaults.baseURL = '/';
  try {
    const res = await axios.post('/samperform/ocr_extract', { filedata: filedata, filename: filename });

    return res.data;
  } catch (error) {
    //console.log('error', error);
    return { status: 'NOK' };
  }
};
