import { Inertia } from '@inertiajs/inertia';
import { DeleteForeverOutlined, EditOutlined } from '@mui/icons-material';
import { Box, Button, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { v4 as uuidv4 } from 'uuid';
import InputComponent from '../../Components/Controls/InputComponent';
import { ContentDialog, ContentDialogInit } from '../../Components/Shared/ContentDialog';
import MainLayout from '../../Layouts/MainLayout';
import { existingPolicyState, isFastLaneState, userLicenceState, whiteLabelState } from '../../Recoil/Atoms/PortfolioAtoms';
import { calculateExisting, checkISIN } from '../../Services/OptimizeExistingService';
import { BREADCRUMBS_OPTIMIZATION, INFO_TEXTS } from '../../Utils/Constants';
import UploadComponent from './Components/UploadComponent';
import UploadOCRComponent from './Components/UploadOCRComponent';
import Grid from '@mui/material/Unstable_Grid2';

const OptimizeExisting = ({ visited }) => {
  const whiteLabel = useRecoilValue(whiteLabelState);
  const { openCD, setOpenCD, tca, setTca } = ContentDialogInit();
  const [isFastLane, setIsFastLane] = useRecoilState(isFastLaneState);
  const userLicence = useRecoilValue(userLicenceState);
  const [isLoading, setIsLoading] = useState(false);
  const [existingPolicy, setExistingPolicy] = useRecoilState(existingPolicyState);
  const [rows, setRows] = useState([]);
  const [newRow, setNewRow] = useState({ id: uuidv4(), isin: '', fondsname: '', gewichtung: '' });
  const [summe, setSumme] = useState(0);
  const rightBtnDisabled = summe !== 100;
  const { enqueueSnackbar } = useSnackbar();
  const sxCell = { pt: '4px', pb: '2px' };

  useEffect(() => {
    setIsFastLane(false);
    if (existingPolicy) {
      setRows(existingPolicy.fonds);
    }
    if (!userLicence) {
      Inertia.get('/');
    }
  }, []);

  useEffect(() => {
    setSumme(rows.reduce((acc, row) => acc + Number(row.gewichtung), 0));
  }, [rows]);

  const onNext = async () => {
    const today = new Date();
    const beginn = today.setFullYear(today.getFullYear() - 5);
    setIsLoading(true);
    const calcResult = await calculateExisting(rows, beginn);
    setIsLoading(false);
    setExistingPolicy({
      ...existingPolicy,
      fonds: rows,
      selected_years: 5,
      calcResult5: {
        beginn: beginn,
        entwicklung: calcResult.entwicklung,
        mdd: calcResult.mdd,
        perf_kumm: calcResult.performance.kummuliert,
        perf_pro_jahr: calcResult.performance.pro_jahr
      },
      calcResult3: {
        beginn: null,
        entwicklung: [],
        mdd: null,
        perf_kumm: null,
        perf_pro_jahr: null
      }
    });
    Inertia.get(route('portfolio.ergebnisOptimizeExisting'));
  };

  const handelAddNewChange = (e) => {
    setNewRow({ ...newRow, [e.target.name]: e.target.value });
  };

  const handleOnChange = (e) => {
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    setExistingPolicy({ ...existingPolicy, [e.target.name]: value });
  };

  const addRow = async (e) => {
    setIsLoading(true);
    const checkResult = await checkISIN(newRow.isin);
    setIsLoading(false);
    if (checkResult.status === 'OK') {
      setRows([...rows, { ...newRow, fondsname: checkResult.kurzname }]);
      setNewRow({ id: uuidv4(), isin: '', fondsname: '', gewichtung: '' });
    } else {
      enqueueSnackbar('ungültige ISIN', { variant: 'error' });
    }
  };

  const removeRow = (id) => {
    setRows(rows.filter((row) => row.id !== id));
  };

  const editRow = (id) => {
    setNewRow(rows.find((row) => row.id === id));
    setRows(rows.filter((row) => row.id !== id));
  };

  const showUpload = () => {
    setOpenCD(true);
    setTca({
      title: 'Fondsliste (Excel) hochladen',
      content: <UploadComponent setRows={setRows} setOpenCD={setOpenCD} />,
      actions: null
    });
  };

  const showUploadPDF = () => {
    setOpenCD(true);
    setTca({
      title: 'automatischer Import (PDF, Bilder)',
      content: <UploadOCRComponent setRows={setRows} setOpenCD={setOpenCD} />,
      actions: null
    });
  };

  return (
    <MainLayout
      breadcrumbs={BREADCRUMBS_OPTIMIZATION}
      visitedPages={visited}
      isLoading={isLoading}
      subHeaderTitle="Auswertung eines bestehenden Portfolios"
      infoText={INFO_TEXTS.OPTIMIZE_EXISTING}
      rightBtnName="Berechnen"
      onRightBtnClick={onNext}
      rightBtnDisabled={rightBtnDisabled}>
      <Box sx={{ pb: '30px', ml: '30px', mr: '30px', width: '100%' }}>
        <Typography>
          Hier haben Sie die Möglichkeit, das Investment eines bestehenden Vertrages zu überprüfen. Das Ergebnis veranschaulicht, wie sich das Investment bzw. Portfolio eines bestehenden Vertrages in
          der Vergangenheit entwickelt hat. Dabei wird die durchschnittliche Rendite p.a. sowie der Maximum Drawdown (MDD) für den betrachteten Zeitraum angezeigt.
        </Typography>
        <Grid container="true" spacing={2} sx={{ pt: '20px', pb: '20px' }}>
          <Grid sm={12} md={6} lg={6} xl={6} sx={{ paddingTop: '8px', width: '100%' }}>
            <InputComponent label="Versicherer" name="versicherer" value={existingPolicy.versicherer} onChange={handleOnChange} />{' '}
          </Grid>
          <Grid sm={12} md={6} lg={6} xl={6} sx={{ paddingTop: '8px', width: '100%' }}>
            <InputComponent label="Tarif" name="tarif" value={existingPolicy.tarif} onChange={handleOnChange} />{' '}
          </Grid>
          <Grid sm={12} md={12} lg={12} xl={12} sx={{ paddingTop: '8px', width: '100%' }}>
            <Button variant="contained" sx={{ bgcolor: '#C94141', color: 'white', textTransform: 'none', '&:hover': { bgcolor: '#C94141' } }} onClick={(e) => showUpload()}>
              Import (Excel)
            </Button>
            {whiteLabel.company === 'ergo' && (
              <Button variant="contained" sx={{ marginLeft: '20px', bgcolor: '#C94141', color: 'white', textTransform: 'none', '&:hover': { bgcolor: '#C94141' } }} onClick={(e) => showUploadPDF()}>
                autom. Import (PDF, Bild)
              </Button>
            )}
          </Grid>
        </Grid>

        <Typography variant="h5">Fondsaufteilung im bestehenden Vertrag</Typography>
        <Table>
          <TableHead>
            <TableRow key="row-header">
              <TableCell></TableCell>
              <TableCell>ISIN</TableCell>
              <TableCell>Fondsname</TableCell>
              <TableCell>Gewichtung in %</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow key="row-new" sx={{ borderBottomWidth: '2px' }}>
              <TableCell sx={sxCell}></TableCell>
              <TableCell sx={sxCell}>
                <InputComponent name="isin" value={newRow.isin} onChange={handelAddNewChange} />
              </TableCell>
              <TableCell style={{ width: '500px' }} sx={sxCell}>
                <InputComponent disabled={true} name="fondsname" value={newRow.fondsname} onChange={handelAddNewChange} />
              </TableCell>
              <TableCell style={{ width: '150px' }} sx={sxCell}>
                <InputComponent suffix="%" isNumberFormat={true} name="gewichtung" value={newRow.gewichtung} onChange={handelAddNewChange} />
              </TableCell>
              <TableCell align="center" valign="middle" sx={sxCell}>
                <Button
                  disabled={!newRow.gewichtung || !newRow.isin}
                  variant="contained"
                  sx={{ bgcolor: '#C94141', color: 'white', textTransform: 'none', '&:hover': { bgcolor: '#C94141' } }}
                  onClick={(e) => addRow()}>
                  hinzufügen
                </Button>
              </TableCell>
            </TableRow>
            {rows.map((row, index) => (
              <TableRow key={row.id}>
                <TableCell sx={sxCell}>{index + 1}</TableCell>
                <TableCell sx={sxCell}>
                  <InputComponent disabled={true} name="isin" value={row.isin} />
                </TableCell>
                <TableCell style={{ maxWidth: '500px' }} sx={sxCell}>
                  <InputComponent disabled={true} name="fondsname" value={row.fondsname} />
                </TableCell>
                <TableCell style={{ width: '150px' }} sx={sxCell}>
                  <InputComponent disabled={true} suffix="%" isNumberFormat={true} name="gewichtung" value={row.gewichtung} />
                </TableCell>
                <TableCell align="center" valign="middle" sx={sxCell}>
                  <IconButton onClick={() => editRow(row.id)}>
                    <Tooltip title="bearbeiten">
                      <EditOutlined />
                    </Tooltip>
                  </IconButton>
                  <IconButton onClick={() => removeRow(row.id)}>
                    <Tooltip title="löschen">
                      <DeleteForeverOutlined />
                    </Tooltip>
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
            <TableRow key="row-sum">
              <TableCell sx={sxCell}></TableCell>
              <TableCell sx={sxCell}></TableCell>
              <TableCell sx={sxCell}></TableCell>
              <TableCell style={{ width: '150px' }} sx={sxCell}>
                <InputComponent disabled={true} suffix="%" isNumberFormat={true} name="gewichtung" value={summe} />
              </TableCell>
              <TableCell sx={sxCell}></TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Box>
      <ContentDialog openCD={openCD} setOpenCD={setOpenCD} tca={tca} centered={true} />
    </MainLayout>
  );
};

export default OptimizeExisting;
