import MainLayout from '../../Layouts/MainLayout'
import { wpState } from '../../Recoil/Atoms/PortfolioAtoms'
import { useRecoilValue } from 'recoil'
import React, { useEffect, useState } from 'react'
import SelectComponent from '../../Components/Controls/SelectComponent'
import { Box, Button, Typography, IconButton, Avatar } from '@mui/material'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'

const VersichererInfo = ({ }) => {
  const wp = useRecoilValue(wpState)
  const [versicherer, setVersicherer] = useState({})
  const [versichererName, setVersichererName] = useState('')
  const [activeVersicherer, setActiveVersicherer] = useState('')
  const [options, setOptions] = useState({})

  useEffect(() => {
    if (wp) {
      const menu = wp.menus.find(menu => menu.navLabel == 'Info-Center')
      const vu = menu.items.find(menu => menu.type == 'special_versicherer_und_tarifinformationen')
      setVersicherer(vu)
      let vuOptions = []
      vu.content.map(item => vuOptions.push({ value: item.name, label: item.name }))
      setOptions(vuOptions)
      setVersichererName(vuOptions[0])
      setActiveVersicherer(vu.content.find(item => item.name == vuOptions[0].value))
    }
  }, [wp])

  const handleOnSelect = selectItem => {
    setVersichererName(selectItem)
    setActiveVersicherer(versicherer.content.find(item => item.name == selectItem.value))
  }

  const navigateToLink = link => {
    window.open(link, '_blank')
  }

  return (
    <MainLayout subHeaderTitle="Versicherer und Tarifinformation">
      <SelectComponent
        label="Versicherer"
        name="versichererName"
        value={versichererName}
        onChange={handleOnSelect}
        width="250px"
        options={options}
      />
      <Typography variant="h5" sx={{ pt: '8px' }}>{activeVersicherer?.name}</Typography>
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', py: '8px' }}>
        <img src={activeVersicherer?.logo} />
        {
          activeVersicherer?.tarifrechner_url &&
          <Button
            variant="contained"
            sx={{ bgcolor: '#C94141', color: 'white', textTransform: 'none', '&:hover': { bgcolor: '#C94141' } }}
            onClick={() => navigateToLink(activeVersicherer.tarifrechner_url)}
          >
            {activeVersicherer.tarifrechner_bezeichnung}
          </Button>
        }
      </Box>
      <hr />
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', py: '8px' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', flex: 3 }}>
          <div dangerouslySetInnerHTML={{ __html: activeVersicherer?.seiteninhalt }} />
          {
            activeVersicherer?.uploads?.map((item, index) => (
              <Box key={`upload_${index}`} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', p: '6px', width: '70%', borderBottom: '1px solid #EBEBEB' }}>
                <PictureAsPdfIcon sx={{ fontSize: 28, mr: '10px' }} />
                <Typography>{item.name}</Typography>
                <IconButton
                  sx={{ bgcolor: 'black', borderRadius: '5px', p: '6px', ml: 'auto', '&:hover': { bgcolor: 'rgba(0, 0, 0, 0.6)' } }}
                  onClick={() => navigateToLink(item['datei-upload'])}
                >
                  <ArrowDownwardIcon sx={{ color: 'white' }} />
                </IconButton>
              </Box>
            ))
          }
        </Box>
        {
          activeVersicherer?.ansprechpartner && activeVersicherer.ansprechpartner.length > 0 &&
          <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1, ml: '40px' }}>
            <Typography>Ihr Ansprechpartner</Typography>
            {
              activeVersicherer.ansprechpartner.map((item, index) => (
                <Box key={`ansprechpartner_${index}`} sx={{ display: 'flex', flexDirection: 'column', bgcolor: 'white', p: '20px', mt: '10px' }}>
                  <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                      <Typography variant="h4" sx={{ fontWeight: 'bold' }}>{item.name}</Typography>
                      <Typography>{item.rolleposition}</Typography>
                    </Box>
                    {
                      item.foto &&
                      <Avatar src={item.foto} sx={{ width: 70, height: 70 }} />
                    }
                  </Box>
                  <hr />
                  <div dangerouslySetInnerHTML={{ __html: item.details }} />
                </Box>
              ))
            }
          </Box>
        }
      </Box>
    </MainLayout>
  )
}

export default VersichererInfo