import React, { useState } from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import { Inertia } from '@inertiajs/inertia';
import AdminLayout from '../../../Layouts/AdminLayout';
import SelectComponent from '../../../Components/Controls/SelectComponent';
import { usePage } from '@inertiajs/inertia-react';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import InputComponent from '../../../Components/Controls/InputComponent';

const Create = ({ insuranceCompanies }) => {
  const { errors } = usePage().props;
  const [selectedFile, setSelectedFile] = useState(null);
  const [company, setCompany] = useState(null);
  const companyOptions = insuranceCompanies.map((x) => {
    return { label: x.name, value: x.id };
  });
  const [tarif, setTarif] = useState(null);

  const [calculationState, setCalculationState] = useState({
    mb_laufend: 50,
    mb_einmal: 10000,
    sort: 0,
    fpr_classic_rating: 0,
    fpr_nachh_rating: 0
  });

  const companyItem = insuranceCompanies.find((x) => x.id === company?.value);
  const allOptions =
    companyItem?.fonds?.map((x) => {
      return { label: x.tarif, value: x.id };
    }) || [];
  const uniqueTarifs = [];
  const tarifOptions = allOptions.filter((item) => {
    if (!uniqueTarifs.includes(item.label)) {
      uniqueTarifs.push(item.label);
      return true;
    }
    return false;
  });

  const handleOnSelectTarif = (tarif) => {
    setTarif(tarif);
  };
  const handleOnSelectCompany = (company) => {
    setCompany(company);
    setTarif(null);
  };

  const handleOnChange = (e) => {
    setCalculationState({ ...calculationState, [e.target.name]: e.target.value });
  };

  const onCreate = () =>
    Inertia.post(route('admin.calculation.store'), {
      insurance_company_id: company?.value,
      tarif: tarif?.label,
      mb_einmal: calculationState.mb_einmal,
      mb_laufend: calculationState.mb_laufend,
      sort: calculationState.sort,
      file: selectedFile,
      fpr_classic_rating: calculationState.fpr_classic_rating,
      fpr_nachh_rating: calculationState.fpr_nachh_rating
    });

  const onFileChange = (event) => setSelectedFile(event.target.files[0]);

  const onBack = () => Inertia.get(route('admin.calculations'));

  const buttonSX = { bgcolor: '#C94141', color: 'white', textTransform: 'none', minWidth: '150px', height: '40px', '&:hover': { bgcolor: '#C94141' } };

  return (
    <AdminLayout subHeaderTitle="Portfolio hinterlegen">
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Button
          variant="contained"
          sx={{
            ...buttonSX,
            bgcolor: 'black',
            color: 'white',
            maxWidth: '150px',
            mb: '20px',
            '&:hover': { bgcolor: 'black' }
          }}
          startIcon={<KeyboardArrowLeftIcon />}
          onClick={onBack}>
          Zurück
        </Button>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <SelectComponent label="Versicherung" name="company" value={company} onChange={handleOnSelectCompany} options={companyOptions} errorMsg={errors.insurance_company_id} />
          </Grid>
          {company && (
            <>
              <Grid item xs={12}>
                <SelectComponent label="Tarif" name="tarif" value={tarif} onChange={handleOnSelectTarif} options={tarifOptions} errorMsg={errors.tarif} />
              </Grid>
              <Grid item xs={12}>
                <InputComponent label="Sortierung" name="sort" value={calculationState.sort} onChange={handleOnChange} />
              </Grid>
              <Grid item xs={12}>
                <InputComponent label="minimaler Beitrag (laufend)" name="mb_laufend" value={calculationState.mb_laufend} onChange={handleOnChange} />
              </Grid>
              <Grid item xs={12}>
                <InputComponent label="minimaler Beitrag (einmalig)" name="mb_einmal" value={calculationState.mb_einmal} onChange={handleOnChange} />
              </Grid>
              <Grid item xs={12}>
                <InputComponent label="FPR Classic Rating" name="fpr_classic_rating" value={calculationState.fpr_classic_rating} onChange={handleOnChange} />
              </Grid>
              <Grid item xs={12}>
                <InputComponent label="FPR Nachh. Rating" name="fpr_nachh_rating" value={calculationState.fpr_nachh_rating} onChange={handleOnChange} />
              </Grid>
            </>
          )}
          <Grid item xs={8}>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <Button variant="contained" component="label" sx={{ ...buttonSX }}>
                JSON auswählen
                <input type="file" hidden onChange={onFileChange} />
              </Button>
              {selectedFile && <Typography>{selectedFile.name}</Typography>}
            </Box>
          </Grid>
        </Grid>
        <Button
          variant="contained"
          sx={{
            ...buttonSX,
            mt: '20px'
          }}
          onClick={onCreate}>
          Erstellen
        </Button>
      </Box>
    </AdminLayout>
  );
};

export default Create;
//114
