import React, { useEffect } from "react";
import { Inertia } from "@inertiajs/inertia";
import MainLayout from "../../Layouts/MainLayout";
import { Box, FormControlLabel, Grid, Radio, Typography } from "@mui/material";
import { BREADCRUMBS_PORTFOLIO, ESG_PROFILING_EU_KRITERIEN_SOZIALE_QUESTIONS, ESG_PROFILING_EU_KRITERIEN_UMWELT_QUESTIONS, ESG_PROFILING_RAHMEN_QUESTIONS, INFO_TEXTS } from "../../Utils/Constants";
import { useRecoilState, useRecoilValue } from "recoil";
import { newEsgProfilingState, risikoProfilingState } from "../../Recoil/Atoms/PortfolioAtoms";
import QuestionsTableComponent from "../../Components/Shared/QuestionsTableComponent";

const ESGProfilingScreenFour = ({ visited }) => {
  const [newEsgProfiling, setNewEsgProfiling] = useRecoilState(newEsgProfilingState);
  const risikoProfiling = useRecoilValue(risikoProfilingState);

  useEffect(() => {
    if (risikoProfiling.question3.answer === undefined || risikoProfiling.question4.answer === undefined) {
      Inertia.get("/");
    }
  }, []);

  const onRadioBtnChange = (event, stateName) =>
    setNewEsgProfiling({
      ...newEsgProfiling,
      [stateName]: { answer: event.target.value },
      rahmen_question1: { answer: undefined },
      rahmen_question2: { answer: undefined },
      rahmen_question3: { answer: undefined },
      rahmen_question4: { answer: undefined },
      rahmen_question5: { answer: undefined },
      rahmen_question6: { answer: undefined },
      rahmen_question7: { answer: undefined },
      rahmen_question8: { answer: undefined },
      rahmen_question9: { answer: undefined },
      rahmen_question10: { answer: undefined },
      rahmen_question11: { answer: undefined },
      rahmen_question12: { answer: undefined },
      rahmen_question13: { answer: undefined },
      rahmen_question14: { answer: undefined },
      rahmen_question15: { answer: undefined },
      rahmen_question16: { answer: undefined },
      rahmen_question17: { answer: undefined },
      umwelt_question1: { answer: undefined },
      umwelt_question2: { answer: undefined },
      umwelt_question3: { answer: undefined },
      umwelt_question4: { answer: undefined },
      umwelt_question5: { answer: undefined },
      umwelt_question6: { answer: undefined },
      umwelt_question7: { answer: undefined },
      soziale_question1: { answer: undefined },
      soziale_question2: { answer: undefined },
      soziale_question3: { answer: undefined },
      soziale_question4: { answer: undefined },
    });

  const onNext = () => Inertia.get(route("portfolio.ergebnisESG"));

  const onBack = () => Inertia.get(route("portfolio.esgProfilingScreenThree"));

  return (
    <MainLayout
      breadcrumbs={BREADCRUMBS_PORTFOLIO}
      visitedPages={visited}
      subHeaderTitle="ESG-Profiling"
      rightBtnName="zum Ergebnis"
      infoText={INFO_TEXTS.ESG_PROFILING}
      onRightBtnClick={onNext}
      onLeftBtnClick={onBack}
    >
      <Box sx={{ pb: "30px", marginLeft: "30px", marginRight: "30px", width: "90%" }}>
        <Typography variant="h4" sx={{ color: "#a9a9a9", pb: "20px" }}>
          IV. Präferenzen - besondere Schwerpunkte
        </Typography>
        <Typography sx={{ color: "#6e6e6e" }}>Hinweis:</Typography>
        <Typography sx={{ color: "#6e6e6e", pb: "20px" }}>
          Je enger Sie Ihre Nachhaltigkeitspräferenzen fassen, umso geringer ist die Auswahl an Fonds, die alle diese Kriterien erfüllt. Dies kann Rendite und Risiko Ihrer Anlage negativ beeinflussen.
          Da das Angebot an nachhaltigen Fonds innerhalb der Fondspalette begrenzt ist, können eventuell Ihre detaillierten Präferenzen nicht alle gleichzeitig erfüllt werden. In diesem Fall
          berücksichtigt samperform zuerst die Renditechance und das gewünschte Risikoprofil, dann den gewünschten Grad der Nachhaltigkeit, dann den gewünschten Mindestanteil an nachhaltigen Anlagen
          und erst danach weitere Präferenzen.
        </Typography>
        <Box>
          <Typography sx={{ fontWeight: "bold", pb: "20px" }}>6. Ich möchte folgende besonderen Schwerpunkte setzten:</Typography>
          <Grid container spacing={2} sx={{ pb: "10px" }}>
            <Grid item xs={9} sx={{ display: "flex", alignItems: "center" }}>
              <Typography sx={{ fontWeight: "bold" }}>Im Rahmen der 17 UN SDGs</Typography>
            </Grid>
            <Grid item xs={1} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <Typography>unwichtig</Typography>
            </Grid>
            <Grid item xs={1} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <Typography>wichtig</Typography>
            </Grid>
            <Grid item xs={1} sx={{ display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center" }}>
              <Typography>sehr wichtig</Typography>
            </Grid>
          </Grid>
          <QuestionsTableComponent questions={ESG_PROFILING_RAHMEN_QUESTIONS} />
          <Typography sx={{ fontWeight: "bold", pt: "20px", pb: "10px" }}>Nach EU-Kriterien</Typography>
          <Typography sx={{ pb: "10px" }}>Umweltziele:</Typography>
          <QuestionsTableComponent questions={ESG_PROFILING_EU_KRITERIEN_UMWELT_QUESTIONS} />
          <Typography sx={{ py: "10px" }}>Soziale Ziele:</Typography>
          <QuestionsTableComponent questions={ESG_PROFILING_EU_KRITERIEN_SOZIALE_QUESTIONS} />
          <FormControlLabel
            label={<Typography sx={{ color: "rgba(0, 0, 0, 0.87)" }}>Bei allen als „unwichtig“ eingestuften Themen, akzeptiere ich Verstöße und nicht nachhaltiges Verhalten</Typography>}
            sx={{ py: "20px" }}
            control={
              <Radio
                disabled={true}
                sx={{ color: "#69B977", "&.Mui-checked": { color: "#69B977" }, "&.Mui-disabled": { color: "#69B977" } }}
                checked={newEsgProfiling["question8"].answer === "Ja"}
                value="Ja"
              />
            }
          />
          <FormControlLabel
            sx={{ py: "20px" }}
            label={
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography>Für mich besteht hier eine Abhängigkeit von der jeweiligen Branche.</Typography>
                <Typography>
                  So ist mir bekannt, dass z.B. bei Energieerzeugern der Aspekt CO2 eine wichtige Rolle spielt oder bei Unternehmen der Chemiebranche z.B. Giftmüll. Bei Dienstleistungsunternehmen wie
                  z.B. Versicherungsunternehmen stehen dagegen faire Kundenbehandlung oder bei Internetunternehmen wie z.B. Facebook Datenschutz und CO2 im Vordergrund.
                </Typography>
              </Box>
            }
            control={
              <Radio
                sx={{ color: "#69B977", "&.Mui-checked": { color: "#69B977" } }}
                checked={newEsgProfiling["question8"].answer === "Nein"}
                value="Nein"
                onChange={(e) => onRadioBtnChange(e, "question8")}
              />
            }
          />
        </Box>
      </Box>
    </MainLayout>
  );
};

export default ESGProfilingScreenFour;
