import React from 'react'
import { Box } from '@mui/material'

const LoginLayout = ({ justify = 'space-between', children }) => {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'row', mt: '70px', mx: 'auto', borderRadius: '6px', width: '1100px' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: justify, width: '50%', bgcolor: '#FFFFFF', p: '30px' }}>
                {children}
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                <img src="/images/Login_Image.png" alt="" width="550px" />
            </Box>
        </Box>
    )
}

export default LoginLayout