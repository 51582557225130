import React, { useEffect } from "react";
import { Inertia } from "@inertiajs/inertia";
import MainLayout from "../../Layouts/MainLayout";
import { Box, FormControlLabel, Radio, Typography } from "@mui/material";
import { BREADCRUMBS_PORTFOLIO, INFO_TEXTS } from "../../Utils/Constants";
import { useRecoilState, useRecoilValue } from "recoil";
import { newEsgProfilingState, risikoProfilingState } from "../../Recoil/Atoms/PortfolioAtoms";
import SliderQuestionComponent from "../../Components/Shared/SliderQuestionComponent";
import Grid from "@mui/material/Unstable_Grid2";

const ESGProfilingScreenTwo = ({ visited }) => {
  const [newEsgProfiling, setNewEsgProfiling] = useRecoilState(newEsgProfilingState);
  const risikoProfiling = useRecoilValue(risikoProfilingState);

  useEffect(() => {
    if (risikoProfiling.question3.answer === undefined || risikoProfiling.question4.answer === undefined) {
      Inertia.get("/");
    }
  }, []);

  const onRadioBtnChange = (event, stateName) => {
    const radioBtnValue = event.target.value;
    const question4 = getSliderDefaultValue(radioBtnValue);
    setNewEsgProfiling({ ...newEsgProfiling, [stateName]: { answer: radioBtnValue }, question4 });
  };

  const getSliderDefaultValue = (radioBtnValue) => {
    switch (radioBtnValue) {
      case "neutral_nachhaltig":
        return [{ value: 0, label: "0%" }];
      case "nachhaltig":
        return [{ value: 20, label: "20%" }];
      case "stark_nachhaltig":
        return [{ value: 40, label: "40%" }];
      default:
        return [{ value: 0, label: "0%" }];
    }
  };

  const onSliderChange = (value, stateName) => setNewEsgProfiling({ ...newEsgProfiling, [stateName]: [value] });

  const onNext = () => Inertia.get(route(newEsgProfiling.question3.answer === "neutral_nachhaltig" ? "portfolio.ergebnisESG" : "portfolio.esgProfilingScreenThree"));

  const onBack = () => Inertia.get(route("portfolio.esgProfiling"));

  const TreeTile = (props) => {
    return (
      <Grid
        xs={10}
        sm={5}
        md={3}
        lg={2}
        xl={2}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <img src={"/images/" + props.image} alt="" width="200px" />
          <Typography>{props.label}</Typography>
        </Box>
      </Grid>
    );
  };

  return (
    <MainLayout breadcrumbs={BREADCRUMBS_PORTFOLIO} visitedPages={visited} subHeaderTitle="ESG-Profiling" infoText={INFO_TEXTS.ESG_PROFILING} onRightBtnClick={onNext} onLeftBtnClick={onBack}>
      <Box sx={{ pb: "30px", marginLeft: "30px", marginRight: "30px", width: "90%" }}>
        <Typography variant="h4" sx={{ color: "#a9a9a9", pb: "10px" }}>
          II. Präferenzen - Grad der Nachhaltigkeit
        </Typography>
        <Typography sx={{ fontWeight: "bold", pb: "20px" }}>
          Für den Begriff Nachhaltigkeit gibt es unterschiedliche Definitionen. samperform verwendet zur Beurteilung das IVA ESG-Konsensrating, um eine größtmögliche Übereinstimmung zu erreichen. Dazu
          werden alle auswählbaren Fonds untersucht und in folgende Kategorien eingeteilt:
        </Typography>

        <Grid container="true" spacing={2} columns={10} justifyContent="center">
          <TreeTile image="one_tree.png" label="nachhaltigkeitsschädlich" />
          <TreeTile image="two_trees.png" label="nicht nachhaltig" />
          <TreeTile image="three_trees.png" label="neutral nachhaltig" />
          <TreeTile image="four_trees.png" label="nachhaltig" />
          <TreeTile image="five_trees.png" label="stark nachhaltig (ESG plus)" />
        </Grid>

        <Box sx={{ display: "flex", flexDirection: "column", pb: "20px" }}>
          <Typography sx={{ pb: "10px" }}>3. Meine Anlage soll folgender Kategorie entsprechen:</Typography>
          <FormControlLabel
            label="neutral nachhaltig - es sollen nur die wichtigsten Risiken aus Nachhaltigkeitsfaktoren berücksichtigt werden"
            control={
              <Radio
                sx={{ color: "#69B977", "&.Mui-checked": { color: "#69B977" } }}
                checked={newEsgProfiling["question3"].answer === "neutral_nachhaltig"}
                value="neutral_nachhaltig"
                onChange={(e) => onRadioBtnChange(e, "question3")}
              />
            }
          />
          <FormControlLabel
            label="nachhaltig - es soll ein Beitrag zur Nachhaltigkeit erreicht werden"
            control={
              <Radio
                sx={{ color: "#69B977", "&.Mui-checked": { color: "#69B977" } }}
                checked={newEsgProfiling["question3"].answer === "nachhaltig"}
                value="nachhaltig"
                onChange={(e) => onRadioBtnChange(e, "question3")}
              />
            }
          />
          <FormControlLabel
            label="stark nachhaltig (ESG plus) - es soll ein wesentlicher Beitrag zur Nachhaltigkeit erreicht werden"
            control={
              <Radio
                sx={{ color: "#69B977", "&.Mui-checked": { color: "#69B977" } }}
                checked={newEsgProfiling["question3"].answer === "stark_nachhaltig"}
                value="stark_nachhaltig"
                onChange={(e) => onRadioBtnChange(e, "question3")}
              />
            }
          />
        </Box>
        <Box>
          <SliderQuestionComponent
            id={4}
            question={"Ich wünsche eine Mindestquote an nachhaltigen Anlagen in Höhe von:"}
            values={newEsgProfiling["question4"]}
            stepperItems={[
              { value: 0, label: "0%" },
              { value: 20, label: "20%" },
              { value: 40, label: "40%" },
              { value: 60, label: "60%" },
            ]}
            step={20}
            min={0}
            max={60}
            thumbColor="#69B977"
            onChange={(value) => onSliderChange(value, "question4")}
          />
        </Box>
      </Box>
    </MainLayout>
  );
};

export default ESGProfilingScreenTwo;
