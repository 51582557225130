import React from 'react'
import MainLayout from '../../Layouts/MainLayout'
import { wpState } from '../../Recoil/Atoms/PortfolioAtoms'
import { useRecoilValue } from 'recoil'

const Datenschutz = ({ }) => {
  const wp = useRecoilValue(wpState)

  return (
    <MainLayout subHeaderTitle={wp?.footer.datenschutz.content.name}>
      <div dangerouslySetInnerHTML={{ __html: wp?.footer.datenschutz.content.seiteninhalt }} />
    </MainLayout>
  )
}

export default Datenschutz