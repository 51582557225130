import React from 'react'
import { Inertia } from '@inertiajs/inertia'
import { Box, Breadcrumbs } from '@mui/material'

const BreadcrumbsComponent = ({ breadcrumbs, visitedPages }) => {
    const breadcrumbsItems = breadcrumbs.map(x => ({ ...x, visited: visitedPages.includes(x.id) }))
    const navigateTo = (breadcrumb) => {
        if (breadcrumb.visited) {
            Inertia.get(breadcrumb.url)
        }
    }

    return (
        <Breadcrumbs separator=">" sx={{ paddingTop: '20px' }}>
            {breadcrumbsItems.map(item => (
                <Box
                    component="span"
                    key={`breadcrumb_${item.id}`}
                    sx={{ cursor: item.visited ? 'pointer' : 'default', color: item.visited ? 'black' : '#B2B2B2' }}
                    onClick={() => navigateTo(item)}
                >
                    {item.label}
                </Box>
            ))}
        </Breadcrumbs>
    )
}

export default BreadcrumbsComponent