import React from 'react'
import { Box, Typography } from '@mui/material'
import { Inertia } from '@inertiajs/inertia'

const InfoFooter = ({ showInfoFooter = true, hideFooter }) => {
  const footerContainerStyle = hideFooter ?
    { display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', py: '10px', mt: 'auto', px: '90px', bgcolor: '#FFFFFF' }
    :
    { display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', pb: '10px', px: '90px', bgcolor: '#FFFFFF' }

  if (!showInfoFooter) {
    return null
  }

  const navigateTo = (url) => () => {
    Inertia.get(url);
  }

  return (
    <Box sx={footerContainerStyle}>
      <Typography sx={{ cursor: 'pointer', '&:hover': { textDecoration: 'underline' } }} onClick={navigateTo('/portfolio/impressum')}>Impressum</Typography>
      <Typography sx={{ cursor: 'pointer', '&:hover': { textDecoration: 'underline' } }} onClick={navigateTo('/portfolio/datenschutz')}>Datenschutz</Typography>
      <Typography sx={{ cursor: 'pointer', '&:hover': { textDecoration: 'underline' } }} onClick={navigateTo('/portfolio/avv')}>AVV</Typography>
      <Typography sx={{ cursor: 'pointer', '&:hover': { textDecoration: 'underline' } }} onClick={navigateTo('/portfolio/nutzungsbedingungen')}>Nutzungsbedingungen</Typography>
    </Box>
  )
}

export default InfoFooter