import React, { Fragment } from 'react';
import Link from '@mui/material/Link';
import MainLayout from '../../Layouts/MainLayout';
import { Box, Checkbox, Grid, Typography, IconButton, Avatar, Radio, RadioGroup, FormControlLabel, Tooltip } from '@mui/material';
import SelectComponent from '../../Components/Controls/SelectComponent';
import InputComponent from '../../Components/Controls/InputComponent';
import { useRecoilState, useRecoilValue } from 'recoil';
import { userDataState, saveLastRouteState, userLicenceState } from '../../Recoil/Atoms/PortfolioAtoms';
import { Inertia } from '@inertiajs/inertia';
import { usePage } from '@inertiajs/inertia-react';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Info } from '@mui/icons-material';
import { trackEvent } from '../../Services/PortfolioService';

const Register = () => {
  const { errors } = usePage().props;
  const [userData, setUserData] = useRecoilState(userDataState);
  const [saveLastRoute, setSaveLastRouteState] = useRecoilState(saveLastRouteState);
  const userLicence = useRecoilValue(userLicenceState);
  const {
    licence_type,
    unternehmen,
    vermittlerregisternummer,
    anrede,
    name,
    vorname,
    strasse,
    plz,
    ort,
    telefon,
    email,
    password,
    password_confirmation,
    confirm_insurance,
    confirm_contract,
    confirm_transmission,
    photo,
    logo
  } = userData;

  const handleOnSelect = (selectElement) => (item) => setUserData({ ...userData, [selectElement]: item });

  const handleOnChange = (e) => {
    setUserData({
      ...userData,
      [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value
    });
  };

  const handleUploadPress = (imageInputId) => document.getElementById(imageInputId).click();

  const onChangePhoto = (e, propName) => {
    const fileInput = e.target;
    if (!!fileInput.files && fileInput.files.length > 0) {
      const file = fileInput.files[0];
      const reader = new FileReader();
      reader.onload = function (e) {
        setUserData({ ...userData, [propName]: e.target.result });
      };
      reader.onerror = function (stuff) {
        console.log(stuff.getMessage());
      };
      reader.readAsDataURL(file);
    } else {
      setUserData({ ...userData, [propName]: null });
    }
  };

  const onRegister = () => {
    console.log('onRegister');
    Inertia.post(
      route('register'),
      {
        ...userData,
        anrede: anrede?.value,
        licence_id: userLicence?.id,
        saveLastRoute
      },
      {
        onSuccess: () => {
          trackEvent('Login', userLicence.id);
          setSaveLastRouteState(false);
        }
      }
    );
  };

  const navigateTo = (url) => () => {
    Inertia.get(url);
  };

  return (
    <MainLayout
      rightBtnName="Kostenlos Registrieren"
      onRightBtnClick={onRegister}
      subHeaderTitle={
        <Box component="span" sx={{ fontWeight: 'bold' }}>
          Registrierung s
          <Box component="span" sx={{ color: '#C94141' }}>
            a
          </Box>
          mperform
        </Box>
      }>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <RadioGroup row defaultValue="Basic" name="licence_type" onChange={handleOnChange}>
          <FormControlLabel
            value="Basic"
            control={<Radio sx={{ '&.Mui-checked': { color: '#C94141' } }} />}
            label={
              <span style={{ fontWeight: 600, fontSize: '20px' }}>
                Basic
                <Tooltip
                  title={
                    <Fragment>
                      <Typography>Es stehen alle Grundfunktionen inklusive Speichern zur Verfügung.</Typography>
                    </Fragment>
                  }>
                  <Info sx={{ mt: '-15px', height: '20px', color: '#000000DE' }}></Info>
                </Tooltip>
              </span>
            }
          />

          {userLicence?.can_premium === 1 && (
            <>
              <FormControlLabel
                value="Premium"
                control={<Radio sx={{ '&.Mui-checked': { color: '#C94141' } }} />}
                label={
                  <span style={{ fontWeight: 600, fontSize: '20px' }}>
                    Premium
                    <Tooltip
                      title={
                        <Fragment>
                          <Typography>Sie haben Zugriff auf erweiterte- und Sonderfunktionen.</Typography>
                        </Fragment>
                      }>
                      <Info sx={{ mt: '-15px', height: '20px', color: '#000000DE' }}></Info>
                    </Tooltip>
                  </span>
                }
              />
            </>
          )}

          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: errors.licence_type ? 'space-between' : 'start' }}>
            {errors.licence_type && <Typography sx={{ pb: '2px', color: 'red' }}>{errors.licence_type.charAt(0).toUpperCase() + errors.licence_type.slice(1)}</Typography>}
          </Box>
        </RadioGroup>
        <Box sx={{ mt: '20px', mb: '20px' }}>Vielen Dank, dass Sie samperform nutzen möchten. Hier können Sie sich registrieren und sofort loslegen.</Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'start'
          }}>
          <IconButton onClick={() => handleUploadPress('userPhoto')} sx={{ p: 0 }}>
            <input id="userPhoto" hidden type="file" accept="image/*" multiple={false} onChange={(e) => onChangePhoto(e, 'photo')} />
            {photo ? (
              <Avatar src={photo} sx={{ width: 70, height: 70 }} />
            ) : (
              <Avatar sx={{ width: 70, height: 70 }}>
                <AccountCircleIcon sx={{ width: 70, height: 70 }} />
              </Avatar>
            )}
          </IconButton>
          <IconButton onClick={() => handleUploadPress('logoPhoto')} sx={{ p: 0, ml: '20px' }}>
            <input id="logoPhoto" hidden type="file" accept="image/*" multiple={false} onChange={(e) => onChangePhoto(e, 'logo')} />
            {logo ? (
              <Avatar src={logo} sx={{ width: 70, height: 70 }} />
            ) : (
              <Avatar sx={{ width: 70, height: 70, bgcolor: 'white' }}>
                <Typography sx={{ color: 'black' }}>Logo</Typography>
              </Avatar>
            )}
          </IconButton>
        </Box>
        <hr style={{ width: '100%' }} />
        <Grid container spacing={3}>
          <Grid item xs={7}>
            <InputComponent label="Unternehmen *" name="unternehmen" value={unternehmen} onChange={handleOnChange} errorMsg={errors.unternehmen} />
          </Grid>
          <Grid item xs={5}>
            <InputComponent
              label="IHK Vermittlerregisternummer *"
              name="vermittlerregisternummer"
              placeholder="mind. 15 Zeichen"
              value={vermittlerregisternummer}
              onChange={handleOnChange}
              errorMsg={errors.vermittlerregisternummer}
            />
          </Grid>
          <Grid item xs={2}>
            <SelectComponent
              label="Anrede *"
              name="anrede"
              value={anrede}
              onChange={handleOnSelect('anrede')}
              options={[
                { value: 'Herr', label: 'Herr' },
                { value: 'Frau', label: 'Frau' }
              ]}
              errorMsg={errors.anrede}
            />
          </Grid>
          <Grid item xs={5}>
            <InputComponent label="Name *" name="name" value={name} onChange={handleOnChange} errorMsg={errors.name} />
          </Grid>
          <Grid item xs={5}>
            <InputComponent label="Vorname *" name="vorname" value={vorname} onChange={handleOnChange} errorMsg={errors.vorname} />
          </Grid>
          <Grid item xs={7}>
            <InputComponent label="Strasse und Hausnummer" name="strasse" value={strasse} onChange={handleOnChange} />
          </Grid>
          <Grid item xs={2}>
            <InputComponent label="PLZ" name="plz" value={plz} onChange={handleOnChange} />
          </Grid>
          <Grid item xs={3}>
            <InputComponent label="Ort" name="ort" value={ort} onChange={handleOnChange} />
          </Grid>
          <Grid item xs={3}>
            <InputComponent label="Telefon *" name="telefon" value={telefon} onChange={handleOnChange} errorMsg={errors.telefon} />
          </Grid>
          <Grid item xs={3}>
            <InputComponent label="E-Mail *" name="email" value={email} onChange={handleOnChange} errorMsg={errors.email} />
          </Grid>
          <Grid item xs={3}>
            <InputComponent type="password" label="Passwort *" name="password" value={password} onChange={handleOnChange} errorMsg={errors.password} />
          </Grid>
          <Grid item xs={3}>
            <InputComponent
              type="password"
              label="Passwort Bestätigung *"
              name="password_confirmation"
              value={password_confirmation}
              onChange={handleOnChange}
              errorMsg={errors.password_confirmation}
            />
          </Grid>
          <Grid item xs={12}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center'
              }}>
              <Checkbox
                sx={{
                  ml: '-9px',
                  mr: '5px',
                  mt: '-8px',
                  alignSelf: 'start',
                  '&.Mui-checked': { color: '#C94141' }
                }}
                name="confirm_insurance"
                checked={confirm_insurance}
                onChange={handleOnChange}
              />
              <Typography>
                Ja, ich bestätige, dass ich ausschließlich als Berater oder Vermittler von Versicherungen und/oder Finanzdienstleistungen (z. B. Versicherungsberater, Versicherungsmakler oder
                Mehrfachagent) tätig bin.
              </Typography>
            </Box>
            {errors.confirm_insurance && <Typography sx={{ pb: '2px', color: 'red' }}>{errors.confirm_insurance}</Typography>}
          </Grid>
          <Grid item xs={12}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center'
              }}>
              <Checkbox
                sx={{
                  ml: '-9px',
                  mr: '5px',
                  alignSelf: 'start',
                  '&.Mui-checked': { color: '#C94141' }
                }}
                name="confirm_contract"
                checked={confirm_contract}
                onChange={handleOnChange}
              />
              <Typography>
                Ja, ich habe die{' '}
                <Link href="#" onClick={navigateTo('/portfolio/nutzungsbedingungen')}>
                  Nutzungsbedingungen
                </Link>{' '}
                inklusive des Haftungsausschlusses gelesen und bin damit einverstanden.
              </Typography>
            </Box>
            {errors.confirm_contract && <Typography sx={{ pb: '2px', color: 'red' }}>{errors.confirm_contract}</Typography>}
          </Grid>
          <Grid item xs={12}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center'
              }}>
              <Checkbox
                sx={{
                  ml: '-9px',
                  mr: '5px',
                  mt: '-8px',
                  alignSelf: 'start',
                  '&.Mui-checked': { color: '#C94141' }
                }}
                name="confirm_transmission"
                checked={confirm_transmission}
                onChange={handleOnChange}
              />
              <Typography>
                Ja, die{' '}
                <Link href="#" onClick={navigateTo('/portfolio/datenschutz')}>
                  Datenschutzerklärung
                </Link>{' '}
                und den durch die Registrierung zustande kommenden{' '}
                <Link href="#" onClick={navigateTo('/portfolio/avv')}>
                  Auftragsverarbeitungsvertrag (AVV)
                </Link>{' '}
                nach Art. 28 DSGVO habe ich gelesen und stimme diesen zu. Ich kann diese Erklärung jederzeit durch eine Nachricht an service@samperform.de widerrufen.
              </Typography>
            </Box>
            {errors.confirm_transmission && <Typography sx={{ pb: '2px', color: 'red' }}>{errors.confirm_transmission}</Typography>}
          </Grid>
        </Grid>
        <br />* Pflichtfeld
      </Box>
    </MainLayout>
  );
};

export default Register;
