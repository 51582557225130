import React from 'react';
import { Box, Typography } from '@mui/material';
import Select from 'react-select';

const customStyles = {
  menu: (provided, state) => ({
    ...provided,
    width: state.selectProps.width
  }),
  option: (provided, state) => ({
    ...provided,
    borderLeft: state.isSelected ? '4px solid #c94141' : '4px solid transparent',
    backgroundColor: state.isFocused && state.isSelected ? '#FFDEDE' : state.isFocused ? '#EBEBEB' : state.isSelected ? '#FFDEDE' : 'white',
    color: 'black',
    fontSize: 14,
    '&:active': { backgroundColor: '#FFDEDE' }
  }),
  control: (provided, state) => ({
    ...provided,
    borderColor: state.isFocused ? '#c94141' : '#EBEBEB',
    boxShadow: state.isFocused ? '0 0 0 1px #c94141' : 'none',
    '&:hover': { borderColor: '#c94141' },
    width: state.selectProps.width,
    fontSize: 14
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    width: 0
  }),
  multiValue: (provided, state) => ({
    ...provided,
    fontSize: 16
  })
};

const SelectComponent = ({ name, label, value, onChange, options, errorMsg, info, isMulti = false, width = '100%' }) => {
  return (
    <Box sx={{ pb: '8px' }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: errorMsg ? 'space-between' : 'start' }}>
        <Typography sx={{ pb: '2px' }}>{label}</Typography>
        {info}
        {errorMsg && <Typography sx={{ pb: '2px', color: 'red' }}>{errorMsg.charAt(0).toUpperCase() + errorMsg.slice(1)}</Typography>}
      </Box>
      <Select name={name} placeholder="" styles={customStyles} width={width} value={value} onChange={onChange} options={options} isSearchable={false} isMulti={isMulti} />
    </Box>
  );
};

export default SelectComponent;
