import React, { Fragment, useState } from "react";
import { Inertia } from "@inertiajs/inertia";
import { AppBar, Box, Button, CssBaseline, Divider, IconButton, ListItemIcon, Menu, MenuItem, Toolbar, Typography, Avatar } from "@mui/material";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import LogoutIcon from "@mui/icons-material/Logout";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { usePage } from "@inertiajs/inertia-react";
import { useRecoilValue, useResetRecoilState } from "recoil";
import { userDataState, userLicenceState, companyKeyState, wpState } from "../../Recoil/Atoms/PortfolioAtoms";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

const profileItems = [
  { label: "Account Einstellungen", url: route("user.profile"), icon: <ManageAccountsIcon sx={{ color: "rgba(255, 255, 255, 0.54)" }} /> },
  { label: "Abmelden", url: route("logout"), icon: <LogoutIcon sx={{ color: "rgba(255, 255, 255, 0.54)" }} /> },
];

const Header = () => {
  const wp = useRecoilValue(wpState);
  const userLicence = useRecoilValue(userLicenceState);
  const companyKey = useRecoilValue(companyKeyState);
  const { user } = usePage().props.auth;
  const resetUser = useResetRecoilState(userDataState);
  const [menus, setMenus] = useState([]);
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);

  const handleOpenNavMenu = (index) => (event) => {
    menus[index] = true;
    setMenus(menus);
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = (index) => () => {
    menus[index] = false;
    setMenus(menus);
    setAnchorElNav(null);
  };

  const handleOpenUserMenu = (event) => setAnchorElUser(event.currentTarget);

  const handleCloseUserMenu = () => setAnchorElUser(null);

  const navigateRegister = () => {
    Inertia.get(route("register"));
  };

  const navigateTo =
    (url, index, method = "get") =>
    () => {
      menus[index] = false;
      setMenus(menus);
      setAnchorElNav(null);
      setAnchorElUser(null);
      if (url.includes("logout")) {
        resetUser();
      } else {
        if (companyKey) url += "?company=" + companyKey;
      }
      method === "get" ? Inertia.get(url) : Inertia.post(url);
    };

  const navigateToSpecial = (item, index) => () => {
    let url = "/";
    let data = {};
    if (item.type) {
      switch (item.type) {
        case "static":
          url = route("portfolio.investmentinfo");
          data = { label: item.label };
          break;
        case "special_fonds_informationen":
          url = route("portfolio.fondsinfo");
          break;
        case "special_versicherer_und_tarifinformationen":
          url = route("portfolio.versicherinfo");
          break;
      }
    } else {
      url = item.url;
      if (url !== "/") url = route(url);
    }
    menus[index] = false;
    setMenus(menus);
    setAnchorElNav(null);
    setAnchorElUser(null);
    Inertia.get(url, data);
  };

  return (
    <AppBar position="fixed" sx={{ bgcolor: "#FFFFFF", boxShadow: "none", border: "1px solid #EBEBEB" }}>
      <CssBaseline />
      <Toolbar sx={{ display: "flex" }}>
        <Box sx={{ display: "flex", alignItems: "center", cursor: "pointer" }} onClick={navigateTo("/")}>
          <img src="/images/SAM_Logo.png" alt="" width="34px" />
          <Box component="span" sx={{ pl: "30px", color: "black", fontWeight: "bold", fontSize: "24px" }}>
            s
            <Box component="span" sx={{ color: "#C94141" }}>
              a
            </Box>
            mperform
          </Box>
        </Box>

        <Box sx={{ display: "flex", marginLeft: "auto" }}>
          {!location.href.includes("/login") &&
            !location.href.includes("/forgot-password") &&
            wp?.menus?.map((page, index) => (
              <Fragment key={`page_${page.navLabel}`}>
                <Button
                  onClick={page.items ? handleOpenNavMenu(index) : navigateTo(page.url)}
                  endIcon={page.items ? <KeyboardArrowDownIcon sx={{ color: menus[index] ? "#C94141" : "black" }} /> : undefined}
                  sx={{ ml: "10px", color: "black", display: "block", textTransform: "none", display: "flex", flexDirection: "row" }}
                >
                  {page.navLabel}
                </Button>
                <Menu
                  sx={{ mt: "45px" }}
                  anchorEl={anchorElNav}
                  anchorOrigin={{ vertical: "top", horizontal: "right" }}
                  transformOrigin={{ vertical: "top", horizontal: "right" }}
                  open={Boolean(menus[index])}
                  onClose={handleCloseNavMenu(index)}
                >
                  {!!page.items &&
                    page.items.map((item) => (
                      <MenuItem key={`menu_${item.label}`} onClick={navigateToSpecial(item, index)} sx={{ "&:hover": { color: "#C94141" } }}>
                        <Typography textAlign="center">{item.label}</Typography>
                      </MenuItem>
                    ))}
                </Menu>
              </Fragment>
            ))}
        </Box>

        <Box sx={{ display: "flex", ml: "60px" }}>
          {user ? (
            <>
              <IconButton>
                <NotificationsIcon sx={{ color: "black" }} />
              </IconButton>
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0, ml: "10px" }}>
                {user.photo_thumb ? (
                  <Avatar src={user.photo_thumb} sx={{ width: 42, height: 42 }} />
                ) : (
                  <Avatar sx={{ width: 42, height: 42 }}>
                    <AccountCircleIcon sx={{ width: 42, height: 42 }} />
                  </Avatar>
                )}
              </IconButton>
              <Menu
                sx={{ mt: "45px" }}
                anchorEl={anchorElUser}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
                PaperProps={{
                  sx: { bgcolor: "black" },
                }}
              >
                <MenuItem sx={{ cursor: "default" }}>
                  {user.photo_thumb ? (
                    <Avatar src={user.photo_thumb} sx={{ width: 42, height: 42 }} />
                  ) : (
                    <Avatar sx={{ width: 42, height: 42 }}>
                      <AccountCircleIcon sx={{ color: "white", width: 42, height: 42 }} />
                    </Avatar>
                  )}
                  <Typography sx={{ ml: "10px", color: "white" }}>
                    {user.name}, {user.vorname}
                  </Typography>
                </MenuItem>
                <Divider sx={{ color: "white" }} />
                {profileItems.map((item) => (
                  <MenuItem key={item.label} onClick={navigateTo(item.url, undefined, item.label === "Abmelden" ? "post" : "get")} sx={{ "&:hover": { bgcolor: "rgba(255, 255, 255, 0.2)" } }}>
                    <ListItemIcon>{item.icon}</ListItemIcon>
                    <Typography sx={{ color: "white" }}>{item.label}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </>
          ) : (
            <>
              {!location.href.includes("/login") && !location.href.includes("/forgot-password") && userLicence && (
                <Button
                  variant="contained"
                  onClick={navigateRegister}
                  sx={{
                    bgcolor: "#C94141",
                    color: "white",
                    textTransform: "none",
                    height: "40px",
                    "&:hover": { bgcolor: "#C94141" },
                  }}
                >
                  Registrierung
                </Button>
              )}
              <Button
                variant="contained"
                sx={{
                  bgcolor: "#C94141",
                  color: "white",
                  textTransform: "none",
                  height: "40px",
                  "&:hover": { bgcolor: "#C94141" },
                  marginLeft: "20px",
                }}
                onClick={navigateTo(route("login"))}
              >
                Login
              </Button>
            </>
          )}
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
