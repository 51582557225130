import React from 'react'
import { Dialog, DialogTitle, DialogActions, DialogContent, Button } from '@mui/material'

const ConfirmDialog = ({ title, children, open, setOpen, onConfirm }) => {
    return (
        <Dialog open={open} onClose={() => setOpen(false)}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>{children}</DialogContent>
            <DialogActions>
                <Button 
                    variant="contained" 
                    sx={{ bgcolor: 'black', color: 'white', textTransform: 'none', '&:hover': { bgcolor: 'black' } }}
                    onClick={() => setOpen(false)}
                >
                    Nein
                </Button>
                <Button
                    variant="contained"
                    sx={{ bgcolor: '#C94141', color: 'white', textTransform: 'none', '&:hover': { bgcolor: '#C94141' } }}
                    onClick={() => {
                        onConfirm()
                        setOpen(false)
                    }}
                >
                    Ja
                </Button>
            </DialogActions>
        </Dialog>
    )
}
export default ConfirmDialog