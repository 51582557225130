import React, { useEffect, useState } from 'react';
import { Inertia } from '@inertiajs/inertia';
import MainLayout from '../../Layouts/MainLayout';
import { Box, Button, Typography } from '@mui/material';
import { useResetRecoilState, useRecoilState } from 'recoil';
import {
  portfolioResultState,
  risikoProfilingState,
  umsetzungState,
  vertragsDetailsState,
  tarifListState,
  risikoProfileTooltipState,
  kundenProfilListState,
  zahlweiseListState,
  whiteLabelState,
  wpState,
  userLicenceState,
  companyKeyState,
  newEsgProfilingState,
  minBeitragState,
  userDataState
} from '../../Recoil/Atoms/PortfolioAtoms';
import { usePage } from '@inertiajs/inertia-react';
import { getLicenceFromKey, getTarifList, trackEvent } from '../../Services/PortfolioService';
import Grid from '@mui/material/Unstable_Grid2';
import CircularProgress from '@mui/material/CircularProgress';
import { gridColumnsTotalWidthSelector } from '@mui/x-data-grid';

const Index = () => {
  const { user } = usePage().props.auth;
  const wordpress = usePage().props.wp;
  const resetVertragsDetails = useResetRecoilState(vertragsDetailsState);
  const resetRisikoProfiling = useResetRecoilState(risikoProfilingState);
  const resetNewEsgProfiling = useResetRecoilState(newEsgProfilingState);
  const resetUmsetzung = useResetRecoilState(umsetzungState);
  const resetPortfolioResult = useResetRecoilState(portfolioResultState);
  const resetTooltip = useResetRecoilState(risikoProfileTooltipState);
  const resetKundenProfilList = useResetRecoilState(kundenProfilListState);
  const [tarifList, setTarifList] = useRecoilState(tarifListState);
  const [tooltipText, setTooltipText] = useRecoilState(risikoProfileTooltipState);
  const [kundenProfilList, setKundenProfilList] = useRecoilState(kundenProfilListState);
  const [zahlweiseList, setZahlweiseList] = useRecoilState(zahlweiseListState);
  const [userLicence, setUserLicence] = useRecoilState(userLicenceState);
  const [companyKey, setCompanyKey] = useRecoilState(companyKeyState);
  const [whiteLabel, setWhiteLabel] = useRecoilState(whiteLabelState);
  const [minBeitrag, setMinBeitrag] = useRecoilState(minBeitragState);
  const [disableStart, setDisableStart] = useState(false);
  const [wp, setWp] = useRecoilState(wpState);
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (params.company === '2290edd3-dfe1-478a-8134-8d350e0346bd' && new Date() < new Date(2024, 2, 20)) {
      setDisableStart(true);
      setInitialized(true);
    } else {
      const assignLicenceByKey = async (key) => {
        const licence = await getLicenceFromKey(key);
        if (licence) {
          trackEvent('Start application', licence.id);
          setUserLicence(licence);
        }
      };

      if (user?.licence) {
        setUserLicence(user.licence);
      } else if (params.company) {
        setCompanyKey(params.company);
        assignLicenceByKey(params.company);
      } else {
        setNoStart();
      }
    }
    setWp(wordpress);
  }, []);

  useEffect(() => {
    const assignTarifList = async () => {
      const insuranceCompanyIds = userLicence.insurance_companies.map((x) => x.id);
      const { insuranceCompanies, tarifList } = await getTarifList(insuranceCompanyIds);
      initConfig(insuranceCompanies, tarifList);
      setTarifList(tarifList);

      if (companyKey === '2290edd3-dfe1-478a-8134-8d350e0346bd' && !user) setDisableStart(true);
      setInitialized(true);
    };
    if (userLicence) {
      assignTarifList();
    }
  }, [userLicence]);

  const setNoStart = () => {
    setTarifList([]);
    setDisableStart(true);
    Inertia.get(route('login'));
  };

  const initConfig = (insuranceCompanies, tarifList) => {
    setTarifList(tarifList);
    let handled = false;
    setZahlweiseList([
      { value: 'laufend', label: 'laufend' },
      { value: 'einmalig', label: 'einmalig' }
    ]);
    if (insuranceCompanies.length === 1) {
      if (insuranceCompanies[0].name === 'Helvetia') {
        setWhiteLabel({ canSelectNurAktiv: true, canSelectETF: true, company: '', artikelFastLane: 'den', labelFastLane: 'Schnell-Einstieg', showPreview: false });
        setDisableStart(false);
      }
      if (insuranceCompanies[0].name === 'Ergo') {
        setWhiteLabel({ canSelectNurAktiv: false, canSelectETF: false, company: 'ergo', artikelFastLane: 'die', labelFastLane: 'Fast Lane', showPreview: true });
        setTooltipText(
          'Bei dieser Risikotoleranz ist eine reine Fondsanlage nicht zu empfehlen. Geeignet sind z.B.: ERGO Rente Index mit 100% Indexbeteiligung MSCI World oder mit 50% klassischer Verzinsung und 50% Indexbeteiligung'
        );
        setKundenProfilList([
          {
            key: 'Defensiv',
            items: ['ERGO Rente Balance, Vermögenspolice Balance, Basisrente Balance: 30% Fondsanlage und 70% klassische Anlage mit Index']
          },
          {
            key: 'Ausgewogen',
            items: [
              'ERGO Rente Balance, Vermögenspolice Balance, Basisrente Balance: 50% Fondsanlage und 50% klassische Anlage mit Index',
              'ERGO Rente Balance, Vermögenspolice Balance, Basisrente Balance: 100% Fondsanlage, die dem Kundenprofil „Ausgewogen“ zugeordnet ist',
              'ERGO Rente Chance, Vermögenspolice Chance: 100% Fondsanlage, die dem Kundenprofil „Ausgewogen“ zugeordnet ist'
            ]
          },
          {
            key: 'Wachstum',
            items: [
              'ERGO Rente Balance, Vermögenspolice Balance, Basisrente Balance: 70% Fondsanlage und 30% klassische Anlage mit Index',
              'ERGO Rente Balance, Vermögenspolice Balance, Basisrente Balance: 100% Fondsanlage, die dem Kundenprofil „Dynamisch“ zugeordnet ist',
              'ERGO Rente Chance, Vermögenspolice Chance: 100% Fondsanlage, die dem Kundenprofil „Dynamisch“ zugeordnet ist'
            ]
          },
          {
            key: 'max. Rendite',
            items: [
              'ERGO Rente Balance, Vermögenspolice Balance, Basisrente Balance: 100% Fondsanlage, die dem Kundenprofil „Risikoorientiert“ zugeordnet ist',
              'ERGO Rente Chance, Vermögenspolice Chance: 100% Fondsanlage, die dem Kundenprofil „Risikoorientiert“ zugeordnet ist'
            ]
          }
        ]);
        setDisableStart(false);
        handled = true;
      }
      if (insuranceCompanies[0].name === 'ERGO Life') {
        /*setZahlweiseList([{ value: "einmalig", label: "einmalig" }]);
        setMinBeitrag({
          einmalig: {
            value: 50000,
            label: "mind. 50000,00 EUR",
          },
        });*/
        setWhiteLabel({ canSelectNurAktiv: false, canSelectETF: false, company: 'ergo', artikelFastLane: 'die', labelFastLane: 'Fast Lane', showPreview: true });
        setTooltipText('Bei dieser Risikotoleranz ist eine reine Fondsanlage nicht zu empfehlen.');
        setKundenProfilList([
          {
            key: 'Defensiv',
            items: []
          },
          {
            key: 'Ausgewogen',
            items: []
          },
          {
            key: 'Wachstum',
            items: []
          },
          {
            key: 'max. Rendite',
            items: []
          }
        ]);
        setDisableStart(false);
        handled = true;
      }
    }
    if (!handled && insuranceCompanies.length > 0) {
      resetTooltip();
      resetKundenProfilList();
      setDisableStart(false);
      handled = true;
    }
    if (!handled) {
      setNoStart();
    }
  };

  const toPortfolio = () => {
    if (!disableStart) {
      resetVertragsDetails();
      resetRisikoProfiling();
      resetNewEsgProfiling();
      resetUmsetzung();
      resetPortfolioResult();
      Inertia.get(route('portfolio.risikoProfiling'));
    }
  };

  const toFastLane = () => {
    if (!disableStart) {
      resetVertragsDetails();
      resetRisikoProfiling();
      resetNewEsgProfiling();
      resetUmsetzung();
      resetPortfolioResult();
      Inertia.get(route('portfolio.fastLane'));
    }
  };

  const toOptimizeExisting = () => {
    if (!disableStart) {
      resetVertragsDetails();
      resetRisikoProfiling();
      resetNewEsgProfiling();
      resetUmsetzung();
      resetPortfolioResult();
      Inertia.get(route('portfolio.optimizeExisting'));
    }
  };
  /*

            sx={{
              position: "relative",
              display: "flex",
              flexDirection: "column",
              p: "30px",
              maxWidth: "420px",
              height: "550px",
              border: "3px solid #B2B2B2",
              borderRadius: "6px",
              mx: "20px",
              bgcolor: "#B2B2B2",
              color: "white",
            }}
*/

  const getClickableCardSX = (disable = false) => {
    let sx = {
      display: 'flex',
      flexDirection: 'column',
      p: '30px',
      maxWidth: '420px',
      height: '550px',
      border: '3px solid #B2B2B2',
      borderRadius: '6px',
      mx: '20px',
      bgcolor: '#B2B2B2',
      color: 'white'
    };
    if (!disable) sx = { ...sx, color: 'black', bgcolor: '#FFFFFF', '&:hover': { borderColor: '#C94141', cursor: 'pointer' } };

    return sx;
  };

  const Tile = (props) => {
    return (
      <Grid
        xs={6}
        sm={6}
        md={3}
        lg={3}
        xl={2}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}>
        <Box sx={getClickableCardSX(props.disable)} onClick={!props.disable ? props.action : undefined}>
          {props.highlight_title && (
            <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#C94141' }}>
              {props.highlight_title}
            </Typography>
          )}
          <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
            {props.title}
          </Typography>
          <Typography sx={{ fontWeight: 'bold' }}>{props.subtile}</Typography>
          <Box
            sx={{
              height: '60%',
              display: 'flex',
              flexDirection: 'column',
              marginTop: 'auto'
            }}>
            <Typography sx={{ mb: 'auto' }}>{props.description}</Typography>
            <Button
              variant="contained"
              sx={{
                '&:disabled': { bgcolor: '#EBEBEB' },
                '&:hover': { bgcolor: '#C94141' },
                bgcolor: '#C94141',
                color: 'white',
                textTransform: 'none',
                height: '45px'
              }}
              disabled={props.disable}>
              Start
            </Button>
          </Box>
        </Box>
      </Grid>
    );
  };

  return (
    <MainLayout>
      {initialized ? (
        <>
          <Typography variant="h3" sx={{ fontWeight: 'bold', textAlign: 'center', padding: '16px' }}>
            Willkommen
          </Typography>
          <Grid container="true" spacing={2} columns={6} justifyContent="center">
            <Tile
              disable={disableStart}
              action={toPortfolio}
              title="Neues Portfolio erstellen"
              description="Hier können Sie ganz einfach ein neues individuelles Portfolio für ihren Kunden erstellen bzw. berechnen. Erstellen Sie für oder mit Ihrem Kunden ein Risiko- und Nachhaltigkeitsprofil
              und auf dieser Basis wird das passende Portfolio erstellt."
            />
            <Tile
              disable={disableStart}
              action={toFastLane}
              highlight_title={'„' + whiteLabel.labelFastLane + '“'}
              title="für die Erstellung eines Portfolios"
              description={
                'Wenn Sie das Risiko- und ESG-Profil Ihres Kunden bereits kennen, dann nutzen Sie ' +
                whiteLabel.artikelFastLane +
                ' „' +
                whiteLabel.labelFastLane +
                '“. Hier überspringen Sie die individuellen Profilings und erhalten schneller die passenden Portfolios.'
              }
            />

            {(userLicence?.can_premium === 1 || user?.licence_type === 'Premium') && (
              <Tile
                disable={disableStart || user?.licence_type !== 'Premium'}
                action={toOptimizeExisting}
                title="Bestehende Portfolios"
                subtile="analysieren, optimieren und vergleichen"
                description="Werten Sie Portfolios in bestehenden Depots oder Tarifen einfach aus. Auf Basis der Risiko- und ESG Präferenzen erstellen Sie ganz einfach ein optimiertes Portfolio im gewünschten Zieltarif und vergleichen Sie die Investments miteinander."
              />
            )}

            <Grid
              xs={6}
              sm={6}
              md={6}
              lg={6}
              xl={6}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                marginLeft: '50px',
                marginRight: '50px'
              }}>
              <Typography
                sx={{
                  textAlign: 'center',
                  px: '20px',
                  fontSize: '12px'
                }}>
                Smart Asset Management Service GmbH haftet nicht für die in dieser als Ergebnis gemachten Angaben. Durch die Inanspruchnahme der Software und/oder die Aushändigung der von der Software
                erzeugten Analysen kommt kein Beratungsvertrag oder sonstige rechtliche Beziehung zwischen dem Empfänger der Analyse und der Smart Asset Management Service GmbH zustande.
              </Typography>
            </Grid>
          </Grid>
        </>
      ) : (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh'
          }}>
          <CircularProgress size={120} sx={{ color: '#C94141' }} />
          <Typography component="h1">Initialisierung...</Typography>
        </Box>
      )}
    </MainLayout>
  );
};

export default Index;
