import React, { useState } from 'react'
import MainLayout from '../../Layouts/MainLayout'
import LoginLayout from '../../Components/Shared/LoginLayout'
import { Box, Typography, Button } from '@mui/material'
import InputComponent from '../../Components/Controls/InputComponent'
import { Inertia } from '@inertiajs/inertia'
import { usePage } from '@inertiajs/inertia-react'

const ForgotPassword = () => {
    const { errors } = usePage().props
    const [email, setEmail] = useState('')

    const handleOnChange = (e) => {
        setEmail(e.target.value)
    }

    const onPasswordForgotten = () => {
        Inertia.post(route('password.email'), { email })
    }

    return (
        <MainLayout>
            <LoginLayout justify="start">
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography sx={{ color: 'black', fontWeight: 'bold', fontSize: '28px' }}>Passwort vergessen</Typography>
                        <Box component="span" sx={{ color: 'black', fontWeight: 'bold', fontSize: '28px' }}>s<Box component="span" sx={{ color: '#C94141' }}>a</Box>mperform</Box>
                    </Box>
                    <img src="/images/SAM_Logo.png" alt="" width="64px" height="64px" />
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', mt: '40px' }}>
                    <InputComponent
                        label="E-Mail Adresse"
                        name="email"
                        value={email}
                        onChange={handleOnChange}
                        styles={{ marginBottom: '10px' }}
                        errorMsg={errors.email}
                    />
                    <Button
                        variant="contained"
                        onClick={onPasswordForgotten}
                        sx={{
                            bgcolor: '#C94141',
                            color: 'white',
                            textTransform: 'none',
                            height: '40px',
                            '&:hover': { bgcolor: '#C94141' }
                        }}
                    >
                        Link zum Zurücksetzen des Passworts per E-Mail
                    </Button>
                </Box>
            </LoginLayout>
        </MainLayout>
    )
}

export default ForgotPassword