import React from 'react'
import { Box, FormControlLabel, Radio, Typography } from '@mui/material'

const YesNoQuestionComponent = ({ id, question, isCheckedYes, isCheckedNo, onChange }) => {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography>{`${id}. ${question}`}</Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <FormControlLabel
                    label="Ja"
                    labelPlacement="bottom"
                    control={
                        <Radio
                            checked={isCheckedYes}
                            onChange={() => onChange(3)}
                            sx={{ color: '#C94141', '&.Mui-checked': { color: '#C94141' } }}
                        />
                    }
                />
                <FormControlLabel
                    label="Nein"
                    labelPlacement="bottom"
                    control={
                        <Radio
                            checked={isCheckedNo}
                            onChange={() => onChange(0)}
                            sx={{ color: '#C94141', '&.Mui-checked': { color: '#C94141' } }}
                        />
                    }
                />
            </Box>
        </Box>
    )
}

export default YesNoQuestionComponent