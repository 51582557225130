import { atom } from 'recoil';

export const isFastLaneState = atom({
  key: 'isFasLaneState',
  default: false
});

export const vertragsDetailsState = atom({
  key: 'vertragsDetailsState',
  default: {
    canSwitchShift: false,
    versichererTarifAlt: undefined,
    versichererTarif: undefined,
    jahren: undefined,
    zahlweise: { value: 'laufend', label: 'laufend' },
    beitragshohe: '',
    vorgangsnummer: '',
    vertragsnummer: '',
    name: '',
    vorname: '',
    nutze_sicherungsvermoegen: true
  }
});

export const whiteLabelState = atom({
  key: 'whiteLabelState',
  default: {
    canSelectETF: true,
    canSelectNurAktiv: false,
    company: '',
    artikelFastLane: 'die',
    labelFastLane: 'Fast Lane',
    showPreview: true
  }
});

export const risikoProfilingState = atom({
  key: 'risikoProfilingState',
  default: {
    question1: [{ value: 20, label: '> 20 Jahre' }],
    question2: [{ value: 3, label: 'unwahrscheinlich' }],
    question3: { answer: undefined },
    question4: { answer: undefined },
    question5: [{ value: 1, label: 'Noch keine' }],
    question6: [{ value: 2, label: 'ausgewogen', label2: 'MDD bis 17%', label3: 'Rendite ca. 5,25% p.a.' }]
  }
});

export const existingPolicyState = atom({
  key: 'existingPolicyState',
  default: {
    versicherer: '',
    tarif: '',
    fonds: [],
    selected_years: 5,
    calcResult5: {
      beginn: null,
      entwicklung: [],
      mdd: null,
      perf_kumm: null,
      perf_pro_jahr: null
    },
    calcResult3: {
      beginn: null,
      entwicklung: [],
      mdd: null,
      perf_kumm: null,
      perf_pro_jahr: null
    }
  }
});

export const minBeitragState = atom({
  key: 'minBeitragState',
  default: {
    laufend: {
      value: 50,
      label: 'mind. 50,00 EUR'
    },
    einmalig: {
      value: 5000,
      label: 'mind. 5000,00 EUR'
    }
  }
});

export const newEsgProfilingFastLaneState = atom({
  key: 'newEsgProfilingFastLaneState',
  default: undefined
});

export const newEsgProfilingState = atom({
  key: 'newEsgProfilingState',
  default: {
    question1: { answer: 'Ja' },
    question2: { answer: undefined },
    question3: { answer: 'neutral_nachhaltig' },
    question4: [{ value: 0, label: '0%' }],
    question5: { answer: undefined },
    question6: { answer: undefined },
    question7: { answer: 'branche' },
    question8: { answer: 'Nein' },
    rahmen_question1: { answer: undefined },
    rahmen_question2: { answer: undefined },
    rahmen_question3: { answer: undefined },
    rahmen_question4: { answer: undefined },
    rahmen_question5: { answer: undefined },
    rahmen_question6: { answer: undefined },
    rahmen_question7: { answer: undefined },
    rahmen_question8: { answer: undefined },
    rahmen_question9: { answer: undefined },
    rahmen_question10: { answer: undefined },
    rahmen_question11: { answer: undefined },
    rahmen_question12: { answer: undefined },
    rahmen_question13: { answer: undefined },
    rahmen_question14: { answer: undefined },
    rahmen_question15: { answer: undefined },
    rahmen_question16: { answer: undefined },
    rahmen_question17: { answer: undefined },
    umwelt_question1: { answer: undefined },
    umwelt_question2: { answer: undefined },
    umwelt_question3: { answer: undefined },
    umwelt_question4: { answer: undefined },
    umwelt_question5: { answer: undefined },
    umwelt_question6: { answer: undefined },
    umwelt_question7: { answer: undefined },
    soziale_question1: { answer: undefined },
    soziale_question2: { answer: undefined },
    soziale_question3: { answer: undefined },
    soziale_question4: { answer: undefined }
  }
});

export const umsetzungState = atom({
  key: 'umsetzungState',
  default: undefined
});

export const portfolioResultState = atom({
  key: 'portfolioResultState',
  default: undefined
});

export const portfolioResultParentState = atom({
  key: 'portfolioResultParentState',
  default: undefined
});

export const comparisonResultState = atom({
  key: 'comparisonResultState',
  default: undefined
});

export const tarifListState = atom({
  key: 'tarifListState',
  default: []
});

export const chartsBase64State = atom({
  key: 'chartsBase64State',
  default: {
    chart1: undefined,
    chart2: undefined,
    circularChart: undefined
  }
});

export const kundenProfilDisplayState = atom({
  key: 'kundenProfilDisplayState',
  default: undefined
});

export const risikoProfileTooltipState = atom({
  key: 'risikoProfileTooltipState',
  default:
    'Mit dieser Risikotoleranz ist eine Fondspolice nicht zu empfehlen. Bitte verändern Sie Ihren Risikotoleranz oder entscheiden Sie sich für ein passendes Produkt, wie z.B. klassisch oder Index'
});

export const kundenProfilListState = atom({
  key: 'kundenProfilListState',
  default: undefined
});

export const zahlweiseListState = atom({
  key: 'zahlweiseListState',
  default: undefined
});

export const userDataState = atom({
  key: 'userDataState',
  default: {
    unternehmen: '',
    vermittlerregisternummer: '',
    anrede: undefined,
    name: '',
    vorname: '',
    strasse: '',
    plz: '',
    ort: '',
    telefon: '',
    email: '',
    password: '',
    password_confirmation: '',
    confirm_insurance: false,
    confirm_contract: false,
    confirm_transmission: false,
    licence_type: 'Basic'
  }
});

export const wpState = atom({
  key: 'wpState',
  default: undefined
});

export const saveLastRouteState = atom({
  key: 'saveLastRouteState',
  default: false
});

export const userLicenceState = atom({
  key: 'userLicenceState',
  default: undefined
});

export const companyKeyState = atom({
  key: 'companyKeyState',
  default: undefined
});

/*
  tarif:
  zahlweise:
  beitragshoehe:
  beitragszahldauer:
  callback:

*/
export const excursionState = atom({
  key: 'excursionState',
  default: undefined
});
