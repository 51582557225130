import React from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import { Inertia } from '@inertiajs/inertia';
import AdminLayout from '../../../Layouts/AdminLayout';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { format, parseISO } from 'date-fns';

const View = ({ calculation }) => {
  const onBack = () => Inertia.get(route('admin.calculations'));

  return (
    <AdminLayout subHeaderTitle="Portfolio anzeigen">
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Button
          variant="contained"
          sx={{
            bgcolor: 'black',
            color: 'white',
            textTransform: 'none',
            maxWidth: '150px',
            height: '40px',
            mb: '20px',
            '&:hover': { bgcolor: 'black' }
          }}
          startIcon={<KeyboardArrowLeftIcon />}
          onClick={onBack}>
          Zurück
        </Button>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography fontWeight="bold">ID:</Typography>
            <Typography>{calculation.id}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography fontWeight="bold">Versicherung:</Typography>
            <Typography>{calculation.insurance_company.name}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography fontWeight="bold">Tarif:</Typography>
            <Typography>{calculation.tarif}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography fontWeight="bold">Sortierung:</Typography>
            <Typography>{calculation.sort}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography fontWeight="bold">minimaler Beitrag (laufend):</Typography>
            <Typography>{calculation.mb_laufend}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography fontWeight="bold">minimaler Beitrag (einmalig):</Typography>
            <Typography>{calculation.mb_einmal}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography fontWeight="bold">Aktualisiert:</Typography>
            <Typography>{format(parseISO(calculation.updated_at), 'dd.MM.yyyy')}</Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography fontWeight="bold">FPR Classic Rating:</Typography>
            <Typography>{calculation.fpr_classic_rating}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography fontWeight="bold">FPR Nachh. Rating:</Typography>
            <Typography>{calculation.fpr_nachh_rating}</Typography>
          </Grid>
          {calculation.calc_result && (
            <Grid item xs={12}>
              <Typography fontWeight="bold">Portfolio:</Typography>
              <Box sx={{ bgcolor: 'white' }}>
                <pre style={{ maxHeight: 700 }}>{JSON.stringify(JSON.parse(calculation.calc_result), null, 2)}</pre>
              </Box>
            </Grid>
          )}
        </Grid>
      </Box>
    </AdminLayout>
  );
};

export default View;
