import React, { useState } from "react";
import { Inertia } from "@inertiajs/inertia";
import { AppBar, Box, Button, CssBaseline, Divider, IconButton, ListItemIcon, Menu, MenuItem, Toolbar, Typography, Avatar, Link } from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import { usePage } from "@inertiajs/inertia-react";
import { useResetRecoilState } from "recoil";
import { userDataState } from "../../Recoil/Atoms/PortfolioAtoms";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

const pages = [
  {
    navLabel: "Benutzer",
    url: route("admin.users"),
  },
  {
    navLabel: "Rollen",
    url: route("admin.roles"),
  },
  {
    navLabel: "Versicherungen",
    url: route("admin.insurance_companies"),
  },
  {
    navLabel: "Lizenzen",
    url: route("admin.licences"),
  },
  {
    navLabel: "Keys",
    url: route("admin.keys"),
  },
  {
    navLabel: "Fonds",
    url: route("admin.fonds"),
  },
  {
    navLabel: "Portfolios",
    url: route("admin.calculations"),
  },
  {
    navLabel: "Reporte",
    url: route("admin.tracking.export"),
  },
];

const profileItems = [{ label: "Abmelden", url: route("logout"), icon: <LogoutIcon sx={{ color: "rgba(255, 255, 255, 0.54)" }} /> }];

const AdminHeader = () => {
  const { user } = usePage().props.auth;
  const resetUser = useResetRecoilState(userDataState);
  const [anchorElUser, setAnchorElUser] = useState(null);

  const handleOpenUserMenu = (event) => setAnchorElUser(event.currentTarget);
  const handleCloseUserMenu = () => setAnchorElUser(null);

  const navigateTo =
    (url, method = "get") =>
    () => {
      setAnchorElUser(null);
      if (url.includes("logout")) {
        resetUser();
      }
      method === "get" ? Inertia.get(url) : Inertia.post(url);
    };

  return (
    <AppBar position="fixed" sx={{ bgcolor: "#FFFFFF", boxShadow: "none", border: "1px solid #EBEBEB" }}>
      <CssBaseline />
      <Toolbar sx={{ display: "flex" }}>
        <Box sx={{ display: "flex", alignItems: "center", cursor: "pointer" }} onClick={navigateTo(route("admin.users"))}>
          <img src="/images/SAM_Logo.png" alt="" width="34px" />
          <Box component="span" sx={{ pl: "30px", color: "black", fontWeight: "bold", fontSize: "24px" }}>
            s
            <Box component="span" sx={{ color: "#C94141" }}>
              a
            </Box>
            mperform
          </Box>
        </Box>
        <Box sx={{ display: "flex", marginLeft: "auto" }}>
          {pages.map((page) => {
            if (page.navLabel === "Reporte") {
              return (
                <Button key={page.navLabel} component={Link} href={page.url} sx={{ ml: "10px", color: "black", display: "block", textTransform: "none", display: "flex", flexDirection: "row" }}>
                  {page.navLabel}
                </Button>
              );
            }

            return (
              <Button key={page.navLabel} onClick={navigateTo(page.url)} sx={{ ml: "10px", color: "black", display: "block", textTransform: "none", display: "flex", flexDirection: "row" }}>
                {page.navLabel}
              </Button>
            );
          })}
        </Box>

        <Box sx={{ display: "flex", ml: "20px" }}>
          <IconButton onClick={handleOpenUserMenu} sx={{ p: 0, ml: "10px" }}>
            {user.photo_thumb ? (
              <Avatar src={user.photo_thumb} sx={{ width: 42, height: 42 }} />
            ) : (
              <Avatar sx={{ width: 42, height: 42 }}>
                <AccountCircleIcon sx={{ width: 42, height: 42 }} />
              </Avatar>
            )}
          </IconButton>
          <Menu
            sx={{ mt: "45px" }}
            anchorEl={anchorElUser}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
            PaperProps={{ sx: { bgcolor: "black" } }}
          >
            <MenuItem sx={{ cursor: "default" }}>
              {user.photo_thumb ? (
                <Avatar src={user.photo_thumb} sx={{ width: 42, height: 42 }} />
              ) : (
                <Avatar sx={{ width: 42, height: 42 }}>
                  <AccountCircleIcon sx={{ color: "white", width: 42, height: 42 }} />
                </Avatar>
              )}
              <Typography sx={{ ml: "10px", color: "white" }}>
                {user.name}, {user.vorname}
              </Typography>
            </MenuItem>
            <Divider sx={{ color: "white" }} />
            {profileItems.map((item) => (
              <MenuItem key={item.label} onClick={navigateTo(item.url, "post")} sx={{ "&:hover": { bgcolor: "rgba(255, 255, 255, 0.2)" } }}>
                <ListItemIcon>{item.icon}</ListItemIcon>
                <Typography sx={{ color: "white" }}>{item.label}</Typography>
              </MenuItem>
            ))}
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default AdminHeader;
