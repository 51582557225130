import React, { useEffect } from "react";
import { Inertia } from "@inertiajs/inertia";
import MainLayout from "../../Layouts/MainLayout";
import { Box, FormControlLabel, Radio, Typography } from "@mui/material";
import { BREADCRUMBS_PORTFOLIO, INFO_TEXTS } from "../../Utils/Constants";
import { useRecoilState, useRecoilValue } from "recoil";
import { newEsgProfilingState, risikoProfilingState } from "../../Recoil/Atoms/PortfolioAtoms";

const ESGProfiling = ({ visited }) => {
  const [newEsgProfiling, setNewEsgProfiling] = useRecoilState(newEsgProfilingState);
  const risikoProfiling = useRecoilValue(risikoProfilingState);
  const rightBtnDisabled = newEsgProfiling.question1.answer === undefined || newEsgProfiling.question2.answer === undefined;

  useEffect(() => {
    if (risikoProfiling.question3.answer === undefined || risikoProfiling.question4.answer === undefined) {
      Inertia.get("/");
    }
  }, []);

  const onRadioBtnChange = (event, stateName) => setNewEsgProfiling({ ...newEsgProfiling, [stateName]: { answer: event.target.value } });

  const onNext = () => Inertia.get(route(newEsgProfiling.question2.answer === "Ja" ? "portfolio.esgProfilingScreenTwo" : "portfolio.ergebnisESG"));

  const onBack = () => Inertia.get(route("portfolio.ergebnisRisiko"));

  return (
    <MainLayout
      breadcrumbs={BREADCRUMBS_PORTFOLIO}
      visitedPages={visited}
      subHeaderTitle="ESG-Profiling"
      infoText={INFO_TEXTS.ESG_PROFILING}
      rightBtnDisabled={rightBtnDisabled}
      onRightBtnClick={onNext}
      onLeftBtnClick={onBack}
    >
      <Box sx={{ pb: "30px", marginLeft: "30px", marginRight: "30px", width: "90%" }}>
        <Typography variant="h4" sx={{ color: "#a9a9a9", pb: "20px" }}>
          I. Allgemein
        </Typography>
        <Box sx={{ pb: "20px" }}>
          <Typography sx={{ pb: "10px" }}>1. Mit dem Thema Nachhaltigkeit in der Geldanlage habe ich mich bereits beschäftigt und verfüge über Informationen darüber:</Typography>
          <FormControlLabel
            label="Ja"
            labelPlacement="bottom"
            control={
              <Radio
                sx={{ color: "#69B977", "&.Mui-checked": { color: "#69B977" } }}
                checked={newEsgProfiling["question1"].answer === "Ja"}
                value="Ja"
                onChange={(e) => onRadioBtnChange(e, "question1")}
              />
            }
          />
          <FormControlLabel
            label="Nein"
            labelPlacement="bottom"
            control={
              <Radio
                sx={{ color: "#69B977", "&.Mui-checked": { color: "#69B977" } }}
                checked={newEsgProfiling["question1"].answer === "Nein"}
                value="Nein"
                disabled={true}
                onChange={(e) => onRadioBtnChange(e, "question1")}
              />
            }
          />
        </Box>
        <Box sx={{ pb: "20px" }}>
          <Typography sx={{ pb: "10px" }}>2. Nachhaltigkeit möchte ich in meiner Geldanlage berücksichtigen:</Typography>
          <FormControlLabel
            label="Ja"
            labelPlacement="bottom"
            control={
              <Radio
                sx={{ color: "#69B977", "&.Mui-checked": { color: "#69B977" } }}
                checked={newEsgProfiling["question2"].answer === "Ja"}
                value="Ja"
                onChange={(e) => onRadioBtnChange(e, "question2")}
              />
            }
          />
          <FormControlLabel
            label="Nein"
            labelPlacement="bottom"
            control={
              <Radio
                sx={{ color: "#69B977", "&.Mui-checked": { color: "#69B977" } }}
                checked={newEsgProfiling["question2"].answer === "Nein"}
                value="Nein"
                onChange={(e) => onRadioBtnChange(e, "question2")}
              />
            }
          />
        </Box>
      </Box>
    </MainLayout>
  );
};

export default ESGProfiling;
