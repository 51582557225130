import React, { useEffect } from "react";
import { Inertia } from "@inertiajs/inertia";
import MainLayout from "../../Layouts/MainLayout";
import { Box, Checkbox, Typography } from "@mui/material";
import { BREADCRUMBS_PORTFOLIO, ERGEBNIS_ESG_PROFILINGS, ERGEBNIS_RISIKO_PROFILINGS, ERGEBNIS_RISIKO_PROFILINGS_ERGO } from "../../Utils/Constants";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  kundenProfilDisplayState,
  risikoProfilingState,
  umsetzungState,
  whiteLabelState,
  isFastLaneState,
  newEsgProfilingFastLaneState,
  vertragsDetailsState,
  tarifListState,
} from "../../Recoil/Atoms/PortfolioAtoms";
import { umsetzungPassivState } from "../../Recoil/Selectors/PortfolioSelectors";
import Grid from "@mui/material/Unstable_Grid2";
import AktivPassivSVG from "../../Components/AktivPassivSVG";

const fastLane = ({ visited }) => {
  const [umsetzung, setUmsetzung] = useRecoilState(umsetzungState);
  const [kundenProfilDisplay, setKundenProfilDisplay] = useRecoilState(kundenProfilDisplayState);
  const [isFastLane, setIsFastLane] = useRecoilState(isFastLaneState);
  const [newEsgProfilingFastLane, setNewEsgProfilingFastLane] = useRecoilState(newEsgProfilingFastLaneState);
  const [vertragsDetails, setVertragsDetails] = useRecoilState(vertragsDetailsState);
  const tarifList = useRecoilValue(tarifListState);
  const passivDisabled = useRecoilValue(umsetzungPassivState);
  const risikoProfiling = useRecoilValue(risikoProfilingState);
  const whiteLabel = useRecoilValue(whiteLabelState);
  const rightBtnDisabled = !(umsetzung !== undefined && kundenProfilDisplay !== undefined && newEsgProfilingFastLane !== undefined);

  useEffect(() => {
    setIsFastLane(true);

    setVertragsDetails({
      ...vertragsDetails,
      versichererTarif: tarifList[0],
      jahren: {
        value: "20 Jahre",
        label: "20 Jahre",
        searchParam: 20,
      },
    });
  }, []);

  useEffect(() => {
    if (umsetzung === "Passiv" && passivDisabled) {
      setUmsetzung(undefined);
    }
  }, [umsetzung, passivDisabled]);

  const onNext = () => Inertia.get(route("portfolio.vertragsdetails"));

  const handleOnChange = (umsetzung) => () => setUmsetzung(umsetzung);

  const handleRisikoProfilingOnChange = (risikoProfilingCheckbox) => () => setKundenProfilDisplay(risikoProfilingCheckbox);
  const handleESGProfilingOnChange = (esgProfilingCheckbox) => () => setNewEsgProfilingFastLane(esgProfilingCheckbox);

  const ergebnisRisikoProfiling = whiteLabel.company === "ergo" ? ERGEBNIS_RISIKO_PROFILINGS_ERGO : ERGEBNIS_RISIKO_PROFILINGS;

  const Tile = (props) => {
    const disabled = props.disabled || false;
    return (
      <Grid
        xs={4}
        sm={2}
        md={2}
        lg={2}
        xl={1}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box
          onClick={disabled ? undefined : props.handle(props.subject)}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            cursor: disabled ? "default" : "pointer",
            pt: "20px",
            pb: "10px",
            px: "40px",
            mx: "20px",
            width: "268px",
            height: "100%",
            bgcolor: `${props.compare === props.subject ? "white" : "#EBEBEB"}`,
            border: `3px solid ${props.compare === props.subject ? "#C94141" : "transparent"}`,
            borderRadius: "12px",
            opacity: disabled ? 0.6 : 1,
            "&:hover": { border: disabled ? "3px solid transparent" : "3px solid #C94141" },
          }}
        >
          {props.image ? props.image : <img src={`/images/${props.subject.image}.png`} alt="" width="80px" style={{ marginBottom: 25 }} />}
          <Typography style={{ fontWeight: "bold", fontSize: "23px", alignSelf: "flex-start" }}>{props.subject.imageTitle}</Typography>
          {props.subject.imageSubtitle && <Typography style={{ alignSelf: "flex-start" }}>{props.subject.imageSubtitle}</Typography>}
          {props.subject.treesImage && <img src={`/images/${props.subject.treesImage}.png`} alt="" width="150px" style={{ marginTop: 5 }} />}
          {props.subject.legend && <Typography style={{ alignSelf: "flex-start", fontSize: 14, width: "max-content" }}>{props.legend}</Typography>}
          <Box style={{ marginTop: "auto" }}>
            <Checkbox checked={props.compare === props.subject} sx={{ mt: "10px", "& .MuiSvgIcon-root": { fontSize: 40 }, "&.Mui-checked": { color: "#C94141" } }} />
          </Box>
        </Box>
      </Grid>
    );
  };

  const Section = (props) => {
    return (
      <>
        <Typography style={{ fontSize: 30, fontWeight: "bold", marginLeft: 20, marginTop: 40 }}>{props.title}</Typography>
        <Typography style={{ fontSize: 23, marginLeft: 20, marginBottom: 20 }}>{props.description}</Typography>
      </>
    );
  };

  return (
    <MainLayout breadcrumbs={BREADCRUMBS_PORTFOLIO} visitedPages={visited} subHeaderTitle={"Neuallokation | " + whiteLabel.labelFastLane} onRightBtnClick={onNext} rightBtnDisabled={rightBtnDisabled}>
      <Section
        title="1. Kundenprofil"
        description="Bestimmen Sie das bereits ermittelte Rendite/Risiko Profil der Kundin bzw. des Kunden. Informationen zu den Kundenprofilen finden Sie im Menü „Investment“."
      />

      <Grid container="true" spacing={2} columns={4} justifyContent="center">
        <Tile handle={handleRisikoProfilingOnChange} subject={ergebnisRisikoProfiling.DEFENSIV} compare={kundenProfilDisplay} legend="(MDD = Maximum Drawdown)" />
        <Tile handle={handleRisikoProfilingOnChange} subject={ergebnisRisikoProfiling.AUSGEWOGEN} compare={kundenProfilDisplay} legend="(MDD = Maximum Drawdown)" />
        <Tile handle={handleRisikoProfilingOnChange} subject={ergebnisRisikoProfiling.WACHSTUM} compare={kundenProfilDisplay} legend="(MDD = Maximum Drawdown)" />
        <Tile handle={handleRisikoProfilingOnChange} subject={ergebnisRisikoProfiling.MAX_RENDITE} compare={kundenProfilDisplay} legend="(MDD = Maximum Drawdown)" />
      </Grid>

      <Section
        title="2. ESG-Profil"
        description="Bestimmen Sie das bereits ermittelte ESG-Profil der Kundin bzw. des Kunden. Informationen zum ESG-Profiling und zum IVA ESG-Konsensrating finden Sie im Menü „Investment“."
      />

      <Grid container="true" spacing={2} columns={4} justifyContent="center">
        <Tile handle={handleESGProfilingOnChange} subject={ERGEBNIS_ESG_PROFILINGS.NICHT_WICHTIG} compare={newEsgProfilingFastLane} />
        <Tile handle={handleESGProfilingOnChange} subject={ERGEBNIS_ESG_PROFILINGS.WICHTIG_1} compare={newEsgProfilingFastLane} />
        <Tile handle={handleESGProfilingOnChange} subject={ERGEBNIS_ESG_PROFILINGS.WICHTIG_2} compare={newEsgProfilingFastLane} />
        <Tile handle={handleESGProfilingOnChange} subject={ERGEBNIS_ESG_PROFILINGS.SEHR_WICHTIG_1} compare={newEsgProfilingFastLane} />
      </Grid>

      <Typography style={{ fontSize: 30, fontWeight: "bold", marginLeft: 20, marginTop: 50 }}>3. Aktiv/Passiv</Typography>
      <Typography component="div" style={{ fontSize: 23, marginLeft: 20, marginBottom: 20 }}>
        Entscheiden Sie, ob das Portfolio nur aus aktiven Fonds, aus einer Mischung von aktiven- und passiven Fonds bzw. ETFs oder ausschließlich aus passiven Fonds bzw. ETFs zusammengesetzt werden
        soll.
        {whiteLabel.canSelectNurAktiv && (
          <Box>
            <b>Beraterhinweis</b>
            <br />
            Die Auswahl passiver Fonds, wie z. B. ETF, ist ab folgenden Bedingungswerken der Tarife seit 10/2017 möglich:
            <ul>
              <li>CleVesto Select ab Bedingungswerk L-FXS-8</li>
              <li>CleVesto Favorites I ab Bedingungswerk L-FSI-33</li>
            </ul>
          </Box>
        )}
      </Typography>

      <Grid container="true" spacing={2} columns={4} justifyContent="center">
        {whiteLabel.canSelectNurAktiv ? (
          <Tile handle={handleOnChange} subject="nur Aktiv" compare={umsetzung} image=<AktivPassivSVG width="120" height="141" icon="nur Aktiv" umsetzung={umsetzung} /> />
        ) : (
          <></>
        )}
        <Tile handle={handleOnChange} subject="Aktiv" compare={umsetzung} image=<AktivPassivSVG width="120" height="141" icon="Aktiv" umsetzung={umsetzung} /> />
        {whiteLabel.canSelectETF ? (
          <Tile disabled={passivDisabled} handle={handleOnChange} subject="Passiv" compare={umsetzung} image=<AktivPassivSVG width="120" height="141" icon="Passiv" umsetzung={umsetzung} /> />
        ) : (
          <></>
        )}
      </Grid>
    </MainLayout>
  );
};

export default fastLane;
