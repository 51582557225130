import React, { useEffect } from "react";
import { usePage } from "@inertiajs/inertia-react";
import { useRecoilState } from "recoil";
import LoadingSpinnerComponent from "../Components/Shared/LoadingSpinnerComponent";
import SubHeader from "../Components/Shared/SubHeader";
import { wpState, userLicenceState } from "../Recoil/Atoms/PortfolioAtoms";
import AdminHeader from "../Components/Admin/AdminHeader";
import { Container, Box } from "@mui/material";

const AdminLayout = ({ subHeaderTitle, infoText, isLoading, children }) => {
  const { user } = usePage().props.auth;
  const wordpress = usePage().props.wp;
  const [wp, setWp] = useRecoilState(wpState);
  const [userLicence, setUserLicence] = useRecoilState(userLicenceState);

  useEffect(() => {
    if (user) {
      setWp(wordpress);
      setUserLicence(user.licence);
    }
  }, []);

  return (
    <div style={{ display: "flex", flexDirection: "column", backgroundColor: "#F7F7F7", minHeight: "100vh" }}>
      <LoadingSpinnerComponent visible={isLoading} />
      <AdminHeader />
      <main style={{ backgroundColor: "#F7F7F7", paddingLeft: "90px", paddingRight: "90px", marginBottom: "20px", marginTop: "64px" }}>
        <Container component="main" maxWidth="1330px">
          <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
            <Box
              sx={{
                width: {
                  xl: "1330px",
                  lg: "1330px",
                  md: "1330px",
                  sm: "1330px",
                  xs: "1330px",
                },
              }}
            >
              {subHeaderTitle && <SubHeader title={subHeaderTitle} infoText={infoText} />}
              {children}{" "}
            </Box>
          </Box>
        </Container>
      </main>
    </div>
  );
};

export default AdminLayout;
