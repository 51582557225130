import { Inertia } from '@inertiajs/inertia';
import { Box, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import ChartDashboard from '../../Components/ChartDashboard';
import MainLayout from '../../Layouts/MainLayout';
import { portfolioResultState, userLicenceState, portfolioResultParentState } from '../../Recoil/Atoms/PortfolioAtoms';
import { trackEvent } from '../../Services/PortfolioService';

const AllocationUpdate = ({}) => {
  const userLicence = useRecoilValue(userLicenceState);
  const [portfolioResult, setPortfolioResult] = useRecoilState(portfolioResultState);
  const [portfolioResultParent, setPortfolioResultParent] = useRecoilState(portfolioResultParentState);

  useEffect(() => {
    if (!userLicence) {
      Inertia.get('/');
    }
    trackEvent('Warning opened', userLicence.id);
  }, []);

  const onNext = () => Inertia.get(route('portfolio.zusammenfassung'));
  const onBack = () => Inertia.get(route('archive.index'));

  return (
    <MainLayout subHeaderTitle="aktualisierter Portfoliovorschlag" onRightBtnClick={onNext} onLeftBtnClick={onBack} rightBtnName="Portfolio übernehmen">
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Typography variant="h5">ursprünglicher Portfoliovorschlag</Typography>
        <ChartDashboard portfolioResult={portfolioResultParent} showKundenprofil={true} />
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Typography variant="h5">aktualisierter Portfoliovorschlag</Typography>
        <ChartDashboard portfolioResult={portfolioResult} showKundenprofil={true} />
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'row', bgcolor: '#EBEBEB', borderRadius: '6px', mt: '20px', p: '15px', width: '100%', marginTop: 'auto' }}>
        <Box sx={{ flex: '0 0 auto', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <img src={`/images/Bestehender_Vertrag.png`} alt="" height="101px" />
        </Box>
        <Box sx={{ flex: 1, marginLeft: '15px' }}>
          <Typography variant="h5">Hinweis:</Typography>
          <Typography>
            Unterschiede bzw. Veränderungen in den durchschnittlich erwartbaren Renditen und dem erwartbaren Verlustrisiko ergeben sich durch die jeweils aktualisierten Daten. Bitte überprüfen Sie, ob
            die jeweiligen Kennzahlen noch zu den Erwartungen bzw. Präferenzen des Kunden passen.
          </Typography>
        </Box>
      </Box>
    </MainLayout>
  );
};

export default AllocationUpdate;
