import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, IconButton, Tooltip, Typography } from '@mui/material';
import { ArcElement, Chart as ChartJS, Legend, Tooltip as TooltipChart } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import { useRecoilValue } from 'recoil';
import { useWindowSize } from '../Hooks/CustomHooks';
import { whiteLabelState } from '../Recoil/Atoms/PortfolioAtoms';
import { ERGEBNIS_RISIKO_PROFILINGS, ERGEBNIS_RISIKO_PROFILINGS_ERGO, FIRST_CHART_COLOR_PALETTE, SECOND_CHART_COLOR_PALETTE } from '../Utils/Constants';
import { percentageToInt, removeSVFromFondslist } from '../Utils/PortfolioHelpers';

ChartJS.register(ArcElement, TooltipChart, ChartDataLabels, Legend);

const chartOptions = {
  devicePixelRatio: 2,
  layout: {
    padding: 20
  },
  plugins: {
    datalabels: {
      display: false
    },
    legend: {
      display: true,
      position: 'bottom'
    },
    tooltip: {
      callbacks: {
        label: function (context) {
          let label = context.label || '';

          if (label) {
            label += ': ';
          }
          if (context.parsed !== null) {
            label += context.parsed + '%';
          }
          return label;
        }
      }
    }
  }
};

const generateChart1Data = (chartData) => {
  return {
    labels: chartData?.map((item) => item[0]),
    datasets: [
      {
        data: chartData?.map((item) => item[1]),
        cutout: '70%',
        backgroundColor: chartData?.map((item, index) => FIRST_CHART_COLOR_PALETTE[index]),
        datalabels: {
          anchor: 'end',
          align: 'end',
          offset: 6
        }
      }
    ]
  };
};

const generateChart2Data = (chartData) => {
  return {
    labels: chartData?.map((item) => item.Fondsname),
    datasets: [
      {
        data: chartData?.map((item) => item.Gewicht),
        cutout: '70%',
        backgroundColor: chartData?.map((item, index) => SECOND_CHART_COLOR_PALETTE[index]),
        datalabels: {
          anchor: 'end',
          align: 'end',
          offset: 6
        }
      }
    ]
  };
};

const infoTextRendite =
  'Die hier angegebene durchschnittlich erwartbare Rendite p.a. für die nächsten 7-10 Jahre, basiert auf Prognosen langfristiger volkswirtschaftlicher Entwicklungen sowie Renditeschätzungen von institutionellen Asset-Managern. Assetklassen werden entsprechende Renditeerwartungen zugeordnet.';
const infoTextRisiko = 'Als Risikokennzahl für das erwartete Verlustrisiko dient der Maximum Drawdown (MDD, Var10% 1 Jahr).';

const ChartDashboard = ({ portfolioResult, showKundenprofil }) => {
  const { width } = useWindowSize();
  const whiteLabel = useRecoilValue(whiteLabelState);
  let chart1Data =
    portfolioResult && portfolioResult.Aufteilung_nach_Assetklassen.filter((item) => percentageToInt(item[1]) > 0).map((assetklasse) => [assetklasse[0], percentageToInt(assetklasse[1])]);
  if (portfolioResult && portfolioResult.Aufteilung_nach_Assetklassen[0].Assetklasse)
    chart1Data = portfolioResult.Aufteilung_nach_Assetklassen.filter((item) => percentageToInt(item.Gewicht) > 0).map((assetklasse) => [assetklasse.Assetklasse, percentageToInt(assetklasse.Gewicht)]);

  const formattedChart1Data = generateChart1Data(chart1Data);
  const formattedChart2Data = generateChart2Data(portfolioResult && removeSVFromFondslist(portfolioResult.Aufteilung_nach_Fonds));
  const risikoProfilings = 'ergo' === whiteLabel.company ? ERGEBNIS_RISIKO_PROFILINGS_ERGO : ERGEBNIS_RISIKO_PROFILINGS;

  const getClickableCardSX = (item) => {
    let sx = {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      width: '150px',
      bgcolor: item.kundenprofil === portfolioResult?.Kundenprofil ? '#FFFFFF' : '#EBEBEB',
      p: '20px',
      borderRadius: '20px',
      mx: '15px',
      filter: 'brightness(75%)',
      border: item.kundenprofil === portfolioResult?.Kundenprofil ? '2px solid #C94141' : '2px solid transparent',
      cursor: 'default'
    };

    return sx;
  };

  if (!portfolioResult) return <></>;
  else
    return (
      <>
        <Box
          sx={{
            display: 'flex',
            flexDirection: width > 1300 ? 'row' : 'column',
            justifyContent: width > 1300 ? 'space-between' : 'start',
            alignItems: width > 1300 ? 'initial' : 'center',
            mb: '20px',
            width: '100%'
          }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', bgcolor: '#FFFFFF', borderRadius: '6px', width: width > 1300 ? '35%' : '70%' }}>
            <Typography sx={{ pt: '20px', pl: '20px' }}>Aufteilung nach Assetklassen</Typography>
            <Doughnut id="chart1" data={formattedChart1Data} options={chartOptions} />
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'space-between',
              mx: '20px',
              my: width > 1300 ? '0px' : '20px',
              width: width > 1300 ? '35%' : '70%'
            }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', bgcolor: '#FFFFFF', borderRadius: '6px', p: '20px', mb: width > 1300 ? '0px' : '15px', width: '100%' }}>
              <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <Typography>Aufteilung nach Risikoklassen</Typography>
                <Tooltip
                  title="Die Aufteilung nach Risikoklassen variiert je nach Risikoprofil und Restlaufzeit. Je länger z.B. die Restlaufzeit, um so höher der Anteil an renditeorientierten Risikoklassen."
                  arrow>
                  <IconButton sx={{ ml: '10px', p: '0px' }}>
                    <InfoOutlinedIcon />
                  </IconButton>
                </Tooltip>
              </Box>

              <Typography variant="h5" sx={{ fontWeight: 'bold', mb: '10px' }}>
                {`${Math.floor(portfolioResult?.Renditeorientiert * 100)}% Renditeorientiert`}
              </Typography>
              <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                <Box sx={{ maxWidth: '260px', maxHeight: '130px' }}>
                  <CircularProgressbarWithChildren
                    id="circularChart"
                    circleRatio={0.5}
                    value={Math.floor(portfolioResult?.Sicherheitsorientiert * 100)}
                    maxValue={100}
                    strokeWidth={8}
                    styles={buildStyles({
                      rotation: 1 / 2 + 1 / 4,
                      strokeLinecap: 'butt',
                      trailColor: '#C94141',
                      pathColor: '#F1CFCF'
                    })}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: '-80px' }}>
                      <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                        {`${Math.round(portfolioResult?.Sicherheitsorientiert * 100)}%`}
                      </Typography>
                      <Typography>Sicherheitsorientiert</Typography>
                    </Box>
                  </CircularProgressbarWithChildren>
                </Box>
              </Box>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
              <Box sx={{ display: 'flex', flexDirection: 'column', bgcolor: '#EBEBEB', borderRadius: '6px', p: '15px', mr: '20px', width: '100%' }}>
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Typography>Erwartete Rendite</Typography>
                  <Tooltip title={infoTextRendite} arrow>
                    <IconButton sx={{ p: '0px' }}>
                      <InfoOutlinedIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
                <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
                  {`${(portfolioResult?.Erwartete_Rendite * 100).toFixed(2).replace('.', ',')}% `}
                  <Typography component="span" sx={{ color: '#B2B2B2' }}>
                    p.a.
                  </Typography>
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column', bgcolor: '#EBEBEB', borderRadius: '6px', p: '15px', width: '100%' }}>
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Typography>Erwartetes Risiko</Typography>
                  <Tooltip title={infoTextRisiko} arrow>
                    <IconButton sx={{ p: '0px' }}>
                      <InfoOutlinedIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
                <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
                  {`${(portfolioResult?.Erwartetes_Risiko * 100).toFixed(2).replace('.', ',')}% `}
                  <Typography component="span" sx={{ color: '#B2B2B2' }}>
                    MDD
                  </Typography>
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', bgcolor: '#FFFFFF', borderRadius: '6px', width: width > 1300 ? '35%' : '70%' }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: '20px', pl: '20px' }}>
              <Typography>Aufteilung nach Fonds</Typography>
              <Tooltip title="Die Darstellung zeigt die Aufteilung nach Einzelfonds bzw. die Aufteilung nach Einzelfonds und Sicherungsvermögen" arrow>
                <IconButton sx={{ ml: '10px', p: '0px' }}>
                  <InfoOutlinedIcon />
                </IconButton>
              </Tooltip>
            </Box>
            <Doughnut id="chart2" data={formattedChart2Data} options={chartOptions} />
          </Box>
        </Box>
        {showKundenprofil && (
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mb: '20px' }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center'
              }}>
              {Object.values(risikoProfilings).map((item) => (
                <Box key={item.kundenprofil} sx={getClickableCardSX(item)}>
                  <img src={`/images/${item.image}.png`} alt="" width="80px" />
                  <Typography sx={{ mt: '5px', fontWeight: 'bold' }}>{item.imageTitle}</Typography>
                  <Typography>{item.imageSubtitle}</Typography>
                </Box>
              ))}
            </Box>
          </Box>
        )}
      </>
    );
};

export default ChartDashboard;
