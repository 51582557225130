const appPalette = {
  spcacing: 4,
  primary: {
    main: '#C94141',
    contrastText: '#FFFFFF'
  }
};

const appTheme = {};

export { appPalette, appTheme };
