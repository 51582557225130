import { Inertia } from '@inertiajs/inertia';
import { ErrorOutlineOutlined, Inventory2Outlined, RemoveModeratorOutlined, ShieldOutlined, TipsAndUpdatesOutlined } from '@mui/icons-material';
import { Circle, Delete, EditNote, Print, Visibility } from '@mui/icons-material';
import { Box, Button, ButtonGroup, IconButton } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { DataGrid } from '@mui/x-data-grid';
import { compareAsc, parse } from 'date-fns';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import ConfirmDialog from '../../Components/Shared/ConfirmDialog';
import MainLayout from '../../Layouts/MainLayout';
import {
  existingPolicyState,
  kundenProfilDisplayState,
  newEsgProfilingState,
  portfolioResultParentState,
  portfolioResultState,
  risikoProfilingState,
  umsetzungState,
  userLicenceState,
  vertragsDetailsState,
  whiteLabelState,
  userDataState
} from '../../Recoil/Atoms/PortfolioAtoms';
import { createApplication } from '../../Services/ApplicationService';
import { sendToggle } from '../../Services/ArchiveService';
import { getTarifList } from '../../Services/PortfolioService';
import { samperformPrint } from '../../Services/PrintService';
import { ERGEBNIS_RISIKO_PROFILINGS, ERGEBNIS_RISIKO_PROFILINGS_ERGO, RED_BUTTON_SX, RISIKO_PROFILING_QUESTIONS, RISIKO_PROFILING_QUESTIONS_ERGO } from '../../Utils/Constants';
import { getArchiveColor } from '../../Utils/PortfolioHelpers';
import { usePage } from '@inertiajs/inertia-react';

const Index = ({ env, archives }) => {
  const { enqueueSnackbar } = useSnackbar();
  const whiteLabel = useRecoilValue(whiteLabelState);
  const [isLoading, setIsLoading] = useState(false);
  const [idToDelete, setIdToDelete] = useState(undefined);
  const [deleteDialogOpened, setDeleteDialogOpened] = useState(false);
  const [userLicence, setUserLicence] = useRecoilState(userLicenceState);
  const [userData, setUserData] = useRecoilState(userDataState);
  const [risikoProfiling, setRisikoProfiling] = useRecoilState(risikoProfilingState);
  const [newEsgProfiling, setNewEsgProfiling] = useRecoilState(newEsgProfilingState);
  const [umsetzung, setUmsetzung] = useRecoilState(umsetzungState);
  const [vertragsDetails, setVertragsDetails] = useRecoilState(vertragsDetailsState);
  const [existingPolicy, setExistingPolicy] = useRecoilState(existingPolicyState);
  const [portfolioResult, setPortfolioResult] = useRecoilState(portfolioResultState);
  const [portfolioResultParent, setPortfolioResultParent] = useRecoilState(portfolioResultParentState);
  const [archiveItems, setArchiveItems] = useState([]);
  const [kundenProfilDisplay, setKundenProfilDisplay] = useRecoilState(kundenProfilDisplayState);
  const risikoProfilings = 'ergo' === whiteLabel.company ? ERGEBNIS_RISIKO_PROFILINGS_ERGO : ERGEBNIS_RISIKO_PROFILINGS;
  const { user } = usePage().props.auth;

  const { url } = usePage();
  const searchParams = new URLSearchParams(url.split('?')[1]);
  const [filter, setFilter] = useState(searchParams.get('view') || 'alle');

  useEffect(() => {
    switch (filter) {
      case 'abweichung':
        setArchiveItems(archives.filter((item) => item.has_warning === 1));
        break;
      case 'aktualisiert':
        setArchiveItems(archives.filter((item) => item.has_allocation_update === 1));
        break;
      case 'archiviert':
        setArchiveItems(archives.filter((item) => item.is_outdated === 1));
        break;
      case 'alle':
      default:
        setArchiveItems(archives.map((x) => x) || []);
    }
  }, [archives, filter]);

  const toggleIsVertrag = async (id) => {
    const res = await sendToggle(id);
    if (res.status === 'OK') {
      setArchiveItems(
        archiveItems.map((item) => {
          if (item.id === id)
            return {
              ...item,
              is_vertrag: res.is_vertrag
            };
          else return item;
        })
      );
    }
  };

  const showDeleteDialog = (itemId) => {
    setDeleteDialogOpened(true);
    setIdToDelete(itemId);
  };

  const getArchive = (archive, action) => {
    setIsLoading(true);
    axios
      .get(route('archive.get', { id: archive.id }))
      .then(async (res) => {
        if (res.status === 200) {
          const archive = JSON.parse(res.data.archive);
          switch (action) {
            case 'print':
              samperformPrint(archive);
              break;
            case 'view':
              loadArchive(res.data, res.data.canSwitchShift);
              break;
            case 'createApplication':
              const res1 = await createApplication(archive);
              if (res1.success)
                enqueueSnackbar(res1.message, {
                  variant: 'success'
                });
              else
                enqueueSnackbar(res1.message, {
                  variant: 'error'
                });
          }
        }
        setIsLoading(false);
      })
      .catch((e) => {
        console.log('error', e);
        setIsLoading(false);
      });
  };

  const deleteArchive = async () => {
    Inertia.delete(route('archive.destroy', idToDelete), {
      onStart: () => setIsLoading(true),
      onSuccess: () => setIdToDelete(undefined),
      onFinish: () => setIsLoading(false)
    });
  };

  const generateEsgData = (itemEsgProfiling) => {
    const newEsgData = {
      question1: { answer: itemEsgProfiling.q1 },
      question2: { answer: itemEsgProfiling.q2 },
      question3: { answer: 'neutral_nachhaltig' },
      question4: [{ value: 0, label: '0%' }],
      question5: { answer: undefined },
      question6: { answer: undefined },
      question7: { answer: 'branche' },
      question8: { answer: 'Nein' },
      rahmen_question1: { answer: undefined },
      rahmen_question2: { answer: undefined },
      rahmen_question3: { answer: undefined },
      rahmen_question4: { answer: undefined },
      rahmen_question5: { answer: undefined },
      rahmen_question6: { answer: undefined },
      rahmen_question7: { answer: undefined },
      rahmen_question8: { answer: undefined },
      rahmen_question9: { answer: undefined },
      rahmen_question10: { answer: undefined },
      rahmen_question11: { answer: undefined },
      rahmen_question12: { answer: undefined },
      rahmen_question13: { answer: undefined },
      rahmen_question14: { answer: undefined },
      rahmen_question15: { answer: undefined },
      rahmen_question16: { answer: undefined },
      rahmen_question17: { answer: undefined },
      umwelt_question1: { answer: undefined },
      umwelt_question2: { answer: undefined },
      umwelt_question3: { answer: undefined },
      umwelt_question4: { answer: undefined },
      umwelt_question5: { answer: undefined },
      umwelt_question6: { answer: undefined },
      umwelt_question7: { answer: undefined },
      soziale_question1: { answer: undefined },
      soziale_question2: { answer: undefined },
      soziale_question3: { answer: undefined },
      soziale_question4: { answer: undefined }
    };

    if (newEsgData.question2.answer === 'Ja') {
      let q3Value, q5Value, q6Value, q7Value;
      if (itemEsgProfiling.q3.startsWith('neutral')) {
        q3Value = 'neutral_nachhaltig';
      } else if (itemEsgProfiling.q3.startsWith('nachhaltig')) {
        q3Value = 'nachhaltig';
      } else {
        q3Value = 'stark_nachhaltig';
      }

      const q4Value = parseInt(itemEsgProfiling.q4.substring(0, itemEsgProfiling.q4.length - 1), 10);
      newEsgData.question3 = { answer: q3Value };
      newEsgData.question4 = [{ value: q4Value, label: itemEsgProfiling.q4 }];

      if (newEsgData.question3.answer !== 'neutral_nachhaltig') {
        if (itemEsgProfiling.q5.startsWith('Ich')) {
          q5Value = itemEsgProfiling.q5.substring(itemEsgProfiling.q5.indexOf('Umwelt:') + 8, itemEsgProfiling.q5.indexOf('\n', itemEsgProfiling.q5.indexOf('Umwelt:')) - 1);
          q6Value = itemEsgProfiling.q5.substring(itemEsgProfiling.q5.indexOf('Soziales:') + 10);
          q7Value = undefined;
        } else {
          q7Value = 'branche';
        }

        newEsgData.question5 = { answer: q5Value };
        newEsgData.question6 = { answer: q6Value };
        newEsgData.question7 = { answer: q7Value };

        if (newEsgData.question7.answer !== 'branche') {
          newEsgData.question8 = { answer: itemEsgProfiling.q8 };
          newEsgData.rahmen_question1 = { answer: itemEsgProfiling.rq1 };
          newEsgData.rahmen_question2 = { answer: itemEsgProfiling.rq2 };
          newEsgData.rahmen_question3 = { answer: itemEsgProfiling.rq3 };
          newEsgData.rahmen_question4 = { answer: itemEsgProfiling.rq4 };
          newEsgData.rahmen_question5 = { answer: itemEsgProfiling.rq5 };
          newEsgData.rahmen_question6 = { answer: itemEsgProfiling.rq6 };
          newEsgData.rahmen_question7 = { answer: itemEsgProfiling.rq7 };
          newEsgData.rahmen_question8 = { answer: itemEsgProfiling.rq8 };
          newEsgData.rahmen_question9 = { answer: itemEsgProfiling.rq9 };
          newEsgData.rahmen_question10 = { answer: itemEsgProfiling.rq10 };
          newEsgData.rahmen_question11 = { answer: itemEsgProfiling.rq11 };
          newEsgData.rahmen_question12 = { answer: itemEsgProfiling.rq12 };
          newEsgData.rahmen_question13 = { answer: itemEsgProfiling.rq13 };
          newEsgData.rahmen_question14 = { answer: itemEsgProfiling.rq14 };
          newEsgData.rahmen_question15 = { answer: itemEsgProfiling.rq15 };
          newEsgData.rahmen_question16 = { answer: itemEsgProfiling.rq16 };
          newEsgData.rahmen_question17 = { answer: itemEsgProfiling.rq17 };
          newEsgData.umwelt_question1 = { answer: itemEsgProfiling.uq1 };
          newEsgData.umwelt_question2 = { answer: itemEsgProfiling.uq2 };
          newEsgData.umwelt_question3 = { answer: itemEsgProfiling.uq3 };
          newEsgData.umwelt_question4 = { answer: itemEsgProfiling.uq4 };
          newEsgData.umwelt_question5 = { answer: itemEsgProfiling.uq5 };
          newEsgData.umwelt_question6 = { answer: itemEsgProfiling.uq6 };
          newEsgData.umwelt_question7 = { answer: itemEsgProfiling.uq7 };
          newEsgData.soziale_question1 = { answer: itemEsgProfiling.sq1 };
          newEsgData.soziale_question2 = { answer: itemEsgProfiling.sq2 };
          newEsgData.soziale_question3 = { answer: itemEsgProfiling.sq3 };
          newEsgData.soziale_question4 = { answer: itemEsgProfiling.sq4 };
        }
      }
    }

    return newEsgData;
  };

  const loadArchive = async (data, canSwitchShift) => {
    const archive = JSON.parse(data.archive);
    const risikoQuestions = 'ergo' === whiteLabel.company ? RISIKO_PROFILING_QUESTIONS_ERGO : RISIKO_PROFILING_QUESTIONS;
    const itemRiskProfiling = archive.calculation.input.risk_profiling;
    const itemEsgProfiling = archive.calculation.input.esg_profiling;
    const application = archive.calculation.input.application;

    const insuranceCompanyIds = userLicence.insurance_companies.map((x) => x.id);
    const { insuranceCompanies, tarifList } = await getTarifList(insuranceCompanyIds);
    const versichererTarif = tarifList.find((item) => item.label === application.tarif);

    setRisikoProfiling({
      question1: [risikoQuestions[0].steps.find((x) => x.value === itemRiskProfiling.q1)],
      question2: [risikoQuestions[1].steps.find((x) => x.label.toLowerCase() === itemRiskProfiling.q2.toLowerCase())],
      question3: { answer: itemRiskProfiling.q3 === 'Ja' ? 3 : 0 },
      question4: { answer: itemRiskProfiling.q4 === 'Ja' ? 3 : 0 },
      question5: [risikoQuestions[4].steps.find((x) => x.label.toLowerCase() === itemRiskProfiling.q5.toLowerCase())],
      question6: [risikoQuestions[5].steps.find((x) => x.label.toLowerCase() === itemRiskProfiling.q6.toLowerCase())]
    });
    setNewEsgProfiling(generateEsgData(itemEsgProfiling));
    setUmsetzung(archive.calculation.result.etf.label);

    setExistingPolicy(archive.existingPolicy);
    setVertragsDetails({
      canSwitchShift: canSwitchShift,
      versichererTarifAlt: versichererTarif,
      nutze_sicherungsvermoegen: application.nutze_sicherungsvermoegen,
      name: archive.customer.lastname || '',
      vorname: archive.customer.firstname || '',
      vorgangsnummer: application.vorgangsnummer || '',
      vertragsnummer: application.vertragsnummer || '',
      beitragshohe: parseFloat(application.beitragshoehe.replace('.', '').replace(',', '.')),
      versichererTarif: versichererTarif,
      zahlweise: { value: application.beitragszahlweise, label: application.beitragszahlweise },
      jahren: {
        value: application.beitragszahldauer,
        label: application.beitragszahldauer,
        searchParam: parseInt(application.beitragszahldauer)
      }
    });

    if (data.has_warning === 1) {
      setPortfolioResult(archive.portfolioResult);
      Inertia.get(route('portfolio.warning'));
    } else if (data.has_allocation_update === 1) {
      const oldArchive = JSON.parse(data.parent);
      setPortfolioResultParent(oldArchive.portfolioResult);
      setPortfolioResult(archive.portfolioResult);

      setKundenProfile(archive.portfolioResult.Kundenprofil);
      Inertia.get(route('portfolio.allocationUpdate'));
    } else {
      Inertia.get(route('portfolio.risikoProfiling'));
    }
  };

  const setKundenProfile = (kundenProfilKey) => {
    setKundenProfilDisplay(getKundenProfileFromKey(kundenProfilKey));
  };

  const getKundenProfileFromKey = (key) => {
    switch (key) {
      case risikoProfilings.DEFENSIV.kundenprofil:
        return risikoProfilings.DEFENSIV;
      case risikoProfilings.AUSGEWOGEN.kundenprofil:
        return risikoProfilings.AUSGEWOGEN;
      case risikoProfilings.WACHSTUM.kundenprofil:
        return risikoProfilings.WACHSTUM;
      case risikoProfilings.MAX_RENDITE.kundenprofil:
        return risikoProfilings.MAX_RENDITE;
      default:
        return risikoProfilings.DEFENSIV;
    }
  };

  const buttons = [
    { key: 'alle', label: 'alle', visible: true },
    { key: 'abweichung', label: 'Abweichungen', visible: whiteLabel.company === 'ergo' && user.licence_type === 'Premium' },
    { key: 'aktualisiert', label: 'Aktualisierungen', visible: user.licence_type === 'Premium' },
    { key: 'archiviert', label: 'Archivierte', visible: true }
  ];

  return (
    <MainLayout subHeaderTitle="Archiv" isLoading={isLoading}>
      <Box sx={{ height: 400 }}>
        <ButtonGroup sx={{ mb: '20px' }}>
          {buttons &&
            buttons.map(
              (item) =>
                item.visible && (
                  <Button
                    key={item.key}
                    onClick={(e) => setFilter(item.key)}
                    sx={{
                      textTransform: 'none'
                    }}
                    disabled={filter === item.key}>
                    {item.label}
                  </Button>
                )
            )}
        </ButtonGroup>
        {archiveItems.length > 0 && (
          <DataGrid
            rows={archiveItems
              .sort((a, b) => {
                return b.id - a.id;
              })
              .map((item) => {
                return {
                  ...item,
                  id: item.id + 9400,
                  optimierungs: item.status,
                  status: {
                    label: item.status,
                    color: getArchiveColor(item.status)
                  },
                  name: (item.name || '- - -') + ', ' + (item.vorname || '- - -'),
                  vorgangsnummer: item.vorgangsnummer || '- - -',
                  vertragsnummer: item.vertragsnummer || '- - -'
                };
              })}
            columns={[
              {
                field: 'info',
                headerName: '',
                resizable: false,
                flex: 0,
                sortable: false,
                renderCell: (cellObject) => {
                  return (
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%'
                      }}>
                      {cellObject.row.is_outdated === 1 && (
                        <IconButton sx={{ p: '3px' }}>
                          <Tooltip title="archivierter Vertrag">
                            <Inventory2Outlined />
                          </Tooltip>
                        </IconButton>
                      )}
                      {cellObject.row.has_allocation_update === 1 && (
                        <IconButton sx={{ p: '3px' }}>
                          <Tooltip title="aktualisiertes Portfolio">
                            <TipsAndUpdatesOutlined />
                          </Tooltip>
                        </IconButton>
                      )}
                      {cellObject.row.has_warning === 1 && (
                        <IconButton sx={{ p: '3px' }}>
                          <Tooltip title="Abweichung im Risiko">
                            <ErrorOutlineOutlined />
                          </Tooltip>
                        </IconButton>
                      )}
                    </Box>
                  );
                }
              },
              { field: 'id', headerName: 'ID', resizable: false, flex: 0 },
              {
                field: 'datum',
                headerName: 'Datum',
                resizable: false,
                flex: 0,
                sortComparator: (v1, v2) => {
                  const date1 = parse(v1, 'dd.MM.yyyy', new Date());
                  const date2 = parse(v2, 'dd.MM.yyyy', new Date());
                  return compareAsc(date1, date2);
                }
              },
              { field: 'name', headerName: 'Name', resizable: false, flex: 1 },
              { field: 'tarif', headerName: 'Tarif', resizable: false, flex: 1 },
              { field: 'kundenprofil', headerName: 'Kundenprofil', resizable: false, flex: 1 },
              //{ field: 'vorgangsnummer', headerName: 'Vorgangsnummer', resizable: false, flex: 1 },
              { field: 'vertragsnummer', headerName: 'Vertragsnummer', resizable: false, flex: 1 },
              {
                field: 'optimierungs',
                headerName: 'Optimierungs-Status',
                resizable: false,
                flex: 1,
                sortable: false,
                renderCell: (cellObject) => {
                  return (
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                      <Circle sx={{ color: getArchiveColor(cellObject.value), fontSize: 20, mr: '6px' }} />
                      <span>{cellObject.value}</span>
                    </Box>
                  );
                }
              },
              {
                field: 'aktion',
                headerName: 'Aktion',
                resizable: false,
                flex: 1,
                sortable: false,
                renderCell: (cellObject) => {
                  const archiveItem = archiveItems.find((x) => x.id + 9400 === cellObject.row.id);
                  return (
                    <Box>
                      {env.ca_ergo && (
                        <IconButton
                          disabled={!archiveItem.tarif.startsWith('Ergo') || archiveItem.sicherungsvermoegen !== null}
                          onClick={() => getArchive(archiveItem, 'createApplication')}
                          sx={{ p: '3px' }}>
                          <Tooltip title="Antrag erstellen">
                            <EditNote />
                          </Tooltip>
                        </IconButton>
                      )}
                      <IconButton disabled={archiveItem.has_allocation_update === 1} onClick={() => getArchive(archiveItem, 'print')} sx={{ p: '3px' }}>
                        <Tooltip title="Berechnung drucken">
                          <Print />
                        </Tooltip>
                      </IconButton>
                      <IconButton onClick={() => getArchive(archiveItem, 'view')} sx={{ p: '3px' }}>
                        <Tooltip title="Berechnung öffnen">
                          <Visibility />
                        </Tooltip>
                      </IconButton>
                      <IconButton disabled={archiveItem.has_allocation_update === 1} onClick={() => toggleIsVertrag(archiveItem.id)} sx={{ p: '3px' }}>
                        <Tooltip title={archiveItem.is_vertrag ? 'Vertragskennzeichen entfernen' : 'Vertragskennzeichen setzen'}>
                          {archiveItem.is_vertrag ? <ShieldOutlined sx={{ color: '#FF0000' }} /> : <RemoveModeratorOutlined />}
                        </Tooltip>
                      </IconButton>
                      <IconButton onClick={() => showDeleteDialog(archiveItem.id)} sx={{ p: '3px' }}>
                        <Tooltip title="Berechnung löschen">
                          <Delete />
                        </Tooltip>
                      </IconButton>
                    </Box>
                  );
                }
              }
            ]}
            disableColumnMenu={true}
            disableSelectionOnClick={true}
            rowsPerPageOptions={[5]}
            pageSize={5}
          />
        )}
      </Box>

      <ConfirmDialog title="Löschen" open={deleteDialogOpened} setOpen={setDeleteDialogOpened} onConfirm={deleteArchive}>
        Soll dieses Archiv wirklich gelöscht werden?
      </ConfirmDialog>
    </MainLayout>
  );
};

export default Index;
