export const BREADCRUMBS_PORTFOLIO = Object.freeze([
  { id: 1, label: 'Risikoprofiling', url: route('portfolio.risikoProfiling') },
  { id: 2, label: 'Ergebnis des Risikoprofilings', url: route('portfolio.ergebnisRisiko') },
  { id: 3, label: 'ESG-Profiling', url: route('portfolio.esgProfiling') },
  { id: 4, label: 'Ergebnis des ESG-Profilings', url: route('portfolio.ergebnisESG') },
  { id: 5, label: 'Aktiv / Passiv', url: route('portfolio.etf') },
  { id: 6, label: 'Vertragsdetails', url: route('portfolio.vertragsdetails') },
  { id: 7, label: 'Portfoliovorschlag', url: route('portfolio.portfoliovorschlag') }
]);

export const BREADCRUMBS_OPTIMIZATION = Object.freeze([
  { id: 1, label: 'Bestandsoptimierung', url: route('portfolio.optimizeExisting') },
  { id: 2, label: 'Auswertung Bestandsvertrag', url: route('portfolio.ergebnisOptimizeExisting') }
]);

export const INFO_TEXTS = Object.freeze({
  OPTIMIZE_EXISTING: 'Beschreibung zu Auswertung eines bestehenden Vertrages',
  RISIKO_PROFILING: 'Ermittlung eines kundenspezifischen Anlageprofils auf Basis von Anlagedauer, Risikoneigung und Erfahrung des Kunden',
  ESG_PROFILING: 'Ermittlung eines kundenspezifischen ESG-Profils auf Basis von Nachhaltigkeitspräferenzen.',
  VERTRAGS_DETAILS: 'Diese Informationen werden benötigt, um weitere wichtige Parameter für die Portfolioerstellung zu berücksichtigen',
  PORTFOLIOVORSCHLAG:
    'Bei dem Portfoliovorschlag handelt es sich um ein Ergebnis auf Basis der zuvor gemachten Angaben. Dieses Portfolio maximiert die Rendite und minimiert das Risiko nach aktuellem Informationsstand bzw. auf Basis der vorliegenden Daten.',
  COMPARISON: 'Der Tarifvergleich ermöglicht Ihnen eine Sortierung der Ergebnisse nach Rendite oder Risiko.'
});

export const ERGEBNIS_RISIKO_PROFILINGS = Object.freeze({
  DEFENSIV: {
    kundenprofil: 'Defensiv',
    image: 'Profil_Defensiv',
    imageTitle: 'Defensiv',
    imageSubtitle: 'MDD 12%',
    mdd: 12,
    titleColor: '#C94141',
    pageTitle: 'Das Risiko-Profiling hat ergeben, dass Sie dem Kundenprofil „Defensiv“ zuzuordnen sind.',
    pageDescription: 'Sie sind sich der Chancen und Risiken eines Investments in Aktien bewusst und bereit, ein moderates Risikobudget festzulegen.'
  },
  AUSGEWOGEN: {
    kundenprofil: 'Ausgewogen',
    image: 'Profil_Ausgewogen',
    imageTitle: 'Ausgewogen',
    imageSubtitle: 'MDD 17%',
    mdd: 17,
    titleColor: '#C94141',
    pageTitle: 'Das Risikoprofiling hat ergeben, dass Sie dem Kundenprofil „Ausgewogen“ zuzuordnen sind.',
    pageDescription: 'Sie sind sich der Chancen und Risiken eines Investments in Aktien bewusst und bereit, ein angemessenes Risikobudget festzulegen.'
  },
  WACHSTUM: {
    kundenprofil: 'Wachstum',
    image: 'Profil_Wachstum',
    imageTitle: 'Wachstum',
    imageSubtitle: 'MDD 22%',
    mdd: 22,
    titleColor: '#C94141',
    pageTitle: 'Das Risiko-Profiling hat ergeben, dass Sie dem Kundenprofil „Wachstum“ zuzuordnen sind.',
    pageDescription: 'Sie sind sich der Chancen und Risiken eines Investments in Aktien bewusst und bereit, ein höheres Risikobudget festzulegen.'
  },
  MAX_RENDITE: {
    kundenprofil: 'max. Rendite',
    image: 'Profil_Max',
    imageTitle: 'max. Rendite',
    imageSubtitle: 'MDD 30%',
    mdd: 30,
    titleColor: '#C94141',
    pageTitle: 'Das Risiko-Profiling hat ergeben, dass Sie dem Kundenprofil „maximale Rendite“ zuzuordnen sind.',
    pageDescription: 'Sie sind sich der Chancen und Risiken eines Investments in Aktien bewusst und bereit, ein hohes Risikobudget festzulegen.'
  }
});

export const ERGEBNIS_RISIKO_PROFILINGS_ERGO = Object.freeze({
  DEFENSIV: {
    kundenprofil: 'Defensiv',
    image: 'Profil_Defensiv',
    imageTitle: 'Defensiv',
    imageSubtitle: 'MDD 12%',
    mdd: 12,
    titleColor: '#C94141',
    pageTitle: 'Das Risiko-Profiling hat ergeben, dass Sie dem Kundenprofil „Defensiv“ zuzuordnen sind.',
    pageDescription: 'Sie sind sich der Chancen und Risiken eines Investments in Aktien bewusst und bereit, ein moderates Risikobudget festzulegen.'
  },
  AUSGEWOGEN: {
    kundenprofil: 'Ausgewogen',
    image: 'Profil_Ausgewogen',
    imageTitle: 'Ausgewogen',
    imageSubtitle: 'MDD 17%',
    mdd: 17,
    titleColor: '#C94141',
    pageTitle: 'Das Risikoprofiling hat ergeben, dass Sie dem Kundenprofil „Ausgewogen“ zuzuordnen sind.',
    pageDescription: 'Sie sind sich der Chancen und Risiken eines Investments in Aktien bewusst und bereit, ein angemessenes Risikobudget festzulegen.'
  },
  WACHSTUM: {
    kundenprofil: 'Wachstum',
    image: 'Profil_Wachstum',
    imageTitle: 'Dynamisch',
    imageSubtitle: 'MDD 22%',
    mdd: 22,
    titleColor: '#C94141',
    pageTitle: 'Das Risiko-Profiling hat ergeben, dass Sie dem Kundenprofil „Dynamisch“ zuzuordnen sind.',
    pageDescription: 'Sie sind sich der Chancen und Risiken eines Investments in Aktien bewusst und bereit, ein höheres Risikobudget festzulegen.'
  },
  MAX_RENDITE: {
    kundenprofil: 'max. Rendite',
    image: 'Profil_Max',
    imageTitle: 'Risikoorientiert',
    imageSubtitle: 'MDD 30%',
    mdd: 30,
    titleColor: '#C94141',
    pageTitle: 'Das Risiko-Profiling hat ergeben, dass Sie dem Kundenprofil „Risikoorientiert“ zuzuordnen sind.',
    pageDescription: 'Sie sind sich der Chancen und Risiken eines Investments in Aktien bewusst und bereit, ein hohes Risikobudget festzulegen.'
  }
});

export const ERGEBNIS_ESG_PROFILINGS = Object.freeze({
  NICHT_WICHTIG: {
    esg: 'nicht wichtig',
    displayEsg: 'nicht wichtig',
    image: 'ESG_Nicht_Wichtig',
    imageTitle: 'nicht wichtig',
    titleColor: '#000000'
  },
  WICHTIG_1: {
    esg: 'nicht wichtig',
    displayEsg: 'neutral nachhaltig',
    image: 'ESG_Wichtig',
    imageTitle: 'neutral nachhaltig',
    titleColor: '#264029',
    pageTitle: 'Das ESG-Profiling hat ergeben, dass Nachhaltigkeit für Sie in der Anlage eine geringe Rolle spielt.',
    pageDescription: 'In den von Ihnen ausgewählten Investments werden nur die wichtigsten Risiken aus Nachhaltigkeitsfaktoren berücksichtigt.',
    treesImage: 'three_trees'
  },
  WICHTIG_2: {
    esg: 'wichtig',
    displayEsg: 'nachhaltig',
    image: 'ESG_Wichtig',
    imageTitle: 'nachhaltig',
    titleColor: '#264029',
    pageTitle: 'Das ESG-Profiling hat ergeben, dass Nachhaltigkeit für Sie in der Anlage eine Rolle spielt.',
    pageDescription: 'Mit den von Ihnen ausgewählten Investments soll ein Beitrag zur Nachhaltigkeit geleistet werden.',
    treesImage: 'four_trees'
  },
  WICHTIG_3: {
    esg: 'wichtig',
    displayEsg: 'nachhaltig',
    image: 'ESG_Wichtig',
    imageTitle: 'nachhaltig',
    titleColor: '#264029',
    pageTitle: 'Das ESG-Profiling hat ergeben, dass Nachhaltigkeit für Sie in der Anlage eine Rolle spielt.',
    pageDescription: 'Mit den von Ihnen ausgewählten Investments soll ein Beitrag zur Nachhaltigkeit geleistet werden.',
    treesImage: 'four_trees',
    additionalInfo1:
      'Da das Angebot nachhaltiger Fonds innerhalb des Einzelfondsangebotes von Versicherungsanlageprodukten begrenzt ist, können Ihre Nachhaltigkeitspräferenzen nicht alle gleichzeitig erfüllt werden.',
    additionalInfo2: 'Daher wird bei der Portfolioerstellung mit folgender Priorisierung vorgegangen:',
    additionalInfoItems: ['1. Gewünschtes Risikoprofil', '2. gewünschter Grad der Nachhaltigkeit', '3. gewünschte Mindestanteile an nachhaltigen Anlagen', '4. Besondere Schwerpunkte']
  },
  SEHR_WICHTIG_1: {
    esg: 'sehr wichtig',
    displayEsg: 'stark nachhaltig',
    image: 'ESG_Sehr_Wichtig',
    imageTitle: 'stark nachhaltig (ESG plus)',
    titleColor: '#20C933',
    pageTitle: 'Das ESG-Profiling hat ergeben, dass Nachhaltigkeit für Sie in der Anlage eine sehr wichtige Rolle spielt.',
    pageDescription: 'Mit den von Ihnen ausgewählten Investments soll ein wesentlicher Beitrag zur Nachhaltigkeit geleistet werden.',
    treesImage: 'five_trees'
  },
  SEHR_WICHTIG_2: {
    esg: 'sehr wichtig',
    displayEsg: 'stark nachhaltig',
    image: 'ESG_Sehr_Wichtig',
    imageTitle: 'stark nachhaltig (ESG plus)',
    titleColor: '#20C933',
    pageTitle: 'Das ESG-Profiling hat ergeben, dass Nachhaltigkeit für Sie in der Anlage eine sehr wichtige Rolle spielt.',
    pageDescription: 'Mit den von Ihnen ausgewählten Investments soll ein wesentlicher Beitrag zur Nachhaltigkeit geleistet werden.',
    treesImage: 'five_trees',
    additionalInfo1:
      'Da das Angebot nachhaltiger Fonds innerhalb des Einzelfondsangebotes von Versicherungsanlageprodukten begrenzt ist, können Ihre Nachhaltigkeitspräferenzen nicht alle gleichzeitig erfüllt werden.',
    additionalInfo2: 'Daher wird bei der Portfolioerstellung mit folgender Priorisierung vorgegangen:',
    additionalInfoItems: ['1. Gewünschtes Risikoprofil', '2. gewünschter Grad der Nachhaltigkeit', '3. gewünschte Mindestanteile an nachhaltigen Anlagen', '4. Besondere Schwerpunkte']
  }
});

export const RISIKO_PROFILING_QUESTIONS = Object.freeze([
  {
    id: 1,
    stateName: 'question1',
    question: 'Die Laufzeit bzw. Restlaufzeit meines Vertrages bis zum Rentenbeginn beträgt voraussichtlich',
    questionType: 'Range',
    step: 1,
    min: 0,
    max: 40,
    steps: [
      { value: 0, label: '0 Jahre' },
      { value: 1, onlyMark: true },
      { value: 2, onlyMark: true },
      { value: 3, onlyMark: true },
      { value: 4, onlyMark: true },
      { value: 5, onlyMark: true },
      { value: 6, onlyMark: true },
      { value: 7, onlyMark: true },
      { value: 8, onlyMark: true },
      { value: 9, onlyMark: true },
      { value: 10, label: '10 Jahre' },
      { value: 11, onlyMark: true },
      { value: 12, onlyMark: true },
      { value: 13, onlyMark: true },
      { value: 14, onlyMark: true },
      { value: 15, onlyMark: true },
      { value: 16, onlyMark: true },
      { value: 17, onlyMark: true },
      { value: 18, onlyMark: true },
      { value: 19, onlyMark: true },
      { value: 20, label: '20 Jahre' },
      { value: 21, onlyMark: true },
      { value: 22, onlyMark: true },
      { value: 23, onlyMark: true },
      { value: 24, onlyMark: true },
      { value: 25, onlyMark: true },
      { value: 26, onlyMark: true },
      { value: 27, onlyMark: true },
      { value: 28, onlyMark: true },
      { value: 29, onlyMark: true },
      { value: 30, label: '30 Jahre' },
      { value: 31, onlyMark: true },
      { value: 32, onlyMark: true },
      { value: 33, onlyMark: true },
      { value: 34, onlyMark: true },
      { value: 35, onlyMark: true },
      { value: 36, onlyMark: true },
      { value: 37, onlyMark: true },
      { value: 38, onlyMark: true },
      { value: 39, onlyMark: true },
      { value: 40, label: '>= 40 Jahre' }
    ]
  },
  {
    id: 2,
    stateName: 'question2',
    question: 'Der Zweck meiner Anlage ist der langfristige Vermögensaufbau bzw. die Altersvorsorge. Ich werde vermutlich dennoch vorzeitig über meine Ersparnisse in der Anlage verfügen wollen:',
    questionType: 'Range',
    step: 1,
    min: 1,
    max: 5,
    direction: 'left',
    steps: [
      { value: 5, label: 'nein' },
      { value: 4, label: 'höchst unwahrscheinlich' },
      { value: 3, label: 'unwahrscheinlich' },
      { value: 2, label: 'möglich' },
      { value: 1, label: 'wahrscheinlich' }
    ]
  },
  {
    id: 3,
    stateName: 'question3',
    question:
      'Mir ist bewusst, dass Wertentwicklungen während der Anlagedauer schwanken. Das kann z.B. bedeuten, dass Aktien innerhalb eines Jahres um bis zu 30% Verlust machen können, aber diese in der Regel nach einiger Zeit wieder aufholen und dennoch Gewinne machen:',
    questionType: 'YesNo'
  },
  {
    id: 4,
    stateName: 'question4',
    question:
      'Mir ist bewusst, dass ich mit sichereren Anlageklassen, wie z.B. Anleihen, nicht die Inflation ausgleichen kann. Mein Geld wird also langfristig nicht weniger, aber ich kann mir weniger dafür kaufen:',
    questionType: 'YesNo'
  },
  {
    id: 5,
    stateName: 'question5',
    question: 'Ich habe bereits Kenntnisse und Erfahrungen im Bereich Einzelaktien, Investmentfonds oder Fondssparpläne',
    questionType: 'Range',
    step: 1,
    min: 1,
    max: 4,
    steps: [
      { value: 1, label: 'noch keine' },
      { value: 2, label: 'seit mind. 5 Jahren' },
      { value: 3, label: 'seit mind. 10 Jahren' },
      { value: 4, label: 'seit mehr als 10 Jahren' }
    ]
  },
  {
    id: 6,
    stateName: 'question6',
    question: 'Mir ist bekannt, dass ich für eine höhere Rendite auch ein höheres Risiko eingehen muss. Meine Risikotoleranz beschreibt am ehesten:',
    questionType: 'Range',
    step: 1,
    min: 0,
    max: 4,
    steps: [
      { value: 0, label: 'sehr sicherheitsorientiert', label2: 'MDD 2%', label3: 'Rendite kleiner als Inflation' },
      { value: 1, label: 'defensiv', label2: 'MDD bis 12%', label3: 'Rendite ca. 4% p.a.' },
      { value: 2, label: 'ausgewogen', label2: 'MDD bis 17%', label3: 'Rendite ca. 5,25% p.a.' },
      { value: 3, label: 'Wachstum', label2: 'MDD bis 22%', label3: 'Rendite ca. 6,5% p.a.' },
      { value: 4, label: 'maximale Rendite', label2: 'MDD bis 30%', label3: 'Rendite > 7% p.a.' }
    ]
  }
]);

export const RISIKO_PROFILING_QUESTIONS_ERGO = Object.freeze([
  {
    id: 1,
    stateName: 'question1',
    question: 'Die Laufzeit bzw. Restlaufzeit meines Vertrages bis zum Rentenbeginn beträgt voraussichtlich',
    questionType: 'Range',
    step: 1,
    min: 0,
    max: 40,
    steps: [
      { value: 0, label: '0 Jahre' },
      { value: 1, onlyMark: true },
      { value: 2, onlyMark: true },
      { value: 3, onlyMark: true },
      { value: 4, onlyMark: true },
      { value: 5, onlyMark: true },
      { value: 6, onlyMark: true },
      { value: 7, onlyMark: true },
      { value: 8, onlyMark: true },
      { value: 9, onlyMark: true },
      { value: 10, label: '10 Jahre' },
      { value: 11, onlyMark: true },
      { value: 12, onlyMark: true },
      { value: 13, onlyMark: true },
      { value: 14, onlyMark: true },
      { value: 15, onlyMark: true },
      { value: 16, onlyMark: true },
      { value: 17, onlyMark: true },
      { value: 18, onlyMark: true },
      { value: 19, onlyMark: true },
      { value: 20, label: '20 Jahre' },
      { value: 21, onlyMark: true },
      { value: 22, onlyMark: true },
      { value: 23, onlyMark: true },
      { value: 24, onlyMark: true },
      { value: 25, onlyMark: true },
      { value: 26, onlyMark: true },
      { value: 27, onlyMark: true },
      { value: 28, onlyMark: true },
      { value: 29, onlyMark: true },
      { value: 30, label: '30 Jahre' },
      { value: 31, onlyMark: true },
      { value: 32, onlyMark: true },
      { value: 33, onlyMark: true },
      { value: 34, onlyMark: true },
      { value: 35, onlyMark: true },
      { value: 36, onlyMark: true },
      { value: 37, onlyMark: true },
      { value: 38, onlyMark: true },
      { value: 39, onlyMark: true },
      { value: 40, label: '>= 40 Jahre' }
    ]
  },
  {
    id: 2,
    stateName: 'question2',
    question: 'Der Zweck meiner Anlage ist der langfristige Vermögensaufbau bzw. die Altersvorsorge. Ich werde vermutlich dennoch vorzeitig über meine Ersparnisse in der Anlage verfügen wollen:',
    questionType: 'Range',
    step: 1,
    min: 1,
    max: 5,
    direction: 'left',
    steps: [
      { value: 5, label: 'nein' },
      { value: 4, label: 'höchst unwahrscheinlich' },
      { value: 3, label: 'unwahrscheinlich' },
      { value: 2, label: 'möglich' },
      { value: 1, label: 'wahrscheinlich' }
    ]
  },
  {
    id: 3,
    stateName: 'question3',
    question:
      'Mir ist bewusst, dass Wertentwicklungen während der Anlagedauer schwanken. Das kann z.B. bedeuten, dass Aktien innerhalb eines Jahres um bis zu 30% Verlust machen können, aber diese in der Regel nach einiger Zeit wieder aufholen und dennoch Gewinne machen:',
    questionType: 'YesNo'
  },
  {
    id: 4,
    stateName: 'question4',
    question:
      'Mir ist bewusst, dass ich mit sichereren Anlageklassen, wie z.B. Anleihen, nicht die Inflation ausgleichen kann. Mein Geld wird also langfristig nicht weniger, aber ich kann mir weniger dafür kaufen:',
    questionType: 'YesNo'
  },
  {
    id: 5,
    stateName: 'question5',
    question: 'Ich habe bereits Kenntnisse und Erfahrungen im Bereich Einzelaktien, Investmentfonds oder Fondssparpläne',
    questionType: 'Range',
    step: 1,
    min: 1,
    max: 4,
    steps: [
      { value: 1, label: 'noch keine' },
      { value: 2, label: 'seit mind. 5 Jahren' },
      { value: 3, label: 'seit mind. 10 Jahren' },
      { value: 4, label: 'seit mehr als 10 Jahren' }
    ]
  },
  {
    id: 6,
    stateName: 'question6',
    question: 'Mir ist bekannt, dass ich für eine höhere Rendite auch ein höheres Risiko eingehen muss. Meine Risikotoleranz beschreibt am ehesten:',
    questionType: 'Range',
    step: 1,
    min: 0,
    max: 4,
    steps: [
      { value: 0, label: 'stabil', label2: 'MDD 2%', label3: 'Rendite kleiner als Inflation' },
      { value: 1, label: 'defensiv', label2: 'MDD bis 12%', label3: 'Rendite ca. 4% p.a.' },
      { value: 2, label: 'ausgewogen', label2: 'MDD bis 17%', label3: 'Rendite ca. 5,25% p.a.' },
      { value: 3, label: 'dynamisch', label2: 'MDD bis 22%', label3: 'Rendite ca. 6,5% p.a.' },
      { value: 4, label: 'risikoorientiert', label2: 'MDD bis 30%', label3: 'Rendite > 7% p.a.' }
    ]
  }
]);

export const ESG_PROFILING_RAHMEN_QUESTIONS = Object.freeze([
  {
    id: 1,
    stateName: 'rahmen_question1',
    question: 'Armut beenden - Armut in all ihren Formen und überall beenden'
  },
  {
    id: 2,
    stateName: 'rahmen_question2',
    question: 'Ernährung sichern - den Hunger beenden, Ernährungssicherheit und eine bessere Ernährung erreichen und eine nachhaltige Landwirtschaft fördern'
  },
  {
    id: 3,
    stateName: 'rahmen_question3',
    question: 'Gesundes Leben für alle - ein gesundes Leben für alle Menschen jeden Alters gewährleisten und ihr Wohlergehen fördern'
  },
  {
    id: 4,
    stateName: 'rahmen_question4',
    question: 'Bildung für alle - inklusive, gerechte und hochwertige Bildung gewährleisten und Möglichkeiten des lebenslangen Lernens für alle fördern'
  },
  {
    id: 5,
    stateName: 'rahmen_question5',
    question: 'Gleichstellung der Geschlechter - Geschlechtergleichstellung erreichen und alle Frauen und Mädchen zur Selbstbestimmung befähigen'
  },
  {
    id: 6,
    stateName: 'rahmen_question6',
    question: 'Wasser und Sanitärversorgung für alle - Verfügbarkeit und nachhaltige Bewirtschaftung von Wasser und Sanitärversorgung für alle gewährleisten'
  },
  {
    id: 7,
    stateName: 'rahmen_question7',
    question: 'Nachhaltige und moderne Energie für alle - Zugang zu bezahlbarer, verlässlicher, nachhaltiger und zeitgemäßer Energie für alle sichern'
  },
  {
    id: 8,
    stateName: 'rahmen_question8',
    question:
      'Nachhaltiges Wirtschaftswachstum und menschenwürdige Arbeit für alle - dauerhaftes, breitenwirksames und nachhaltiges Wirtschaftswachstum, produktive Vollbeschäftigung und menschenwürdige Arbeit für alle fördern'
  },
  {
    id: 9,
    stateName: 'rahmen_question9',
    question:
      'Widerstandsfähige Infrastruktur und nachhaltige Industrialisierung - eine widerstandsfähige Infrastruktur aufbauen, breitenwirksame und nachhaltige Industrialisierung fördern und Innovationen unterstützen'
  },
  {
    id: 10,
    stateName: 'rahmen_question10',
    question: 'Ungleichheit verringern - Ungleichheit in und zwischen Ländern verringern'
  },
  {
    id: 11,
    stateName: 'rahmen_question11',
    question: 'Nachhaltige Städte und Siedlungen - Städte und Siedlungen inklusiv, sicher, widerstandsfähig und nachhaltig gestalten'
  },
  {
    id: 12,
    stateName: 'rahmen_question12',
    question: 'Nachhaltige Konsum- und Produktionsweisen - nachhaltige Konsum- und Produktionsmuster sicherstellen'
  },
  {
    id: 13,
    stateName: 'rahmen_question13',
    question: 'Sofortmaßnahmen ergreifen, um den Klimawandel und seine Auswirkungen zu bekämpfen'
  },
  {
    id: 14,
    stateName: 'rahmen_question14',
    question: 'Bewahrung und nachhaltige Nutzung der Ozeane, Meere und Meeresressourcen'
  },
  {
    id: 15,
    stateName: 'rahmen_question15',
    question:
      'Landökosysteme schützen - Landökosysteme schützen, wiederherstellen und ihre nachhaltige Nutzung fördern, Wälder nachhaltig bewirtschaften, Wüstenbildung bekämpfen, Bodendegradation beenden und umkehren und dem Verlust der biologischen Vielfalt ein Ende setzen'
  },
  {
    id: 16,
    stateName: 'rahmen_question16',
    question:
      'Frieden, Gerechtigkeit und starke Institutionen. Friedliche und inklusive Gesellschaften für eine nachhaltige Entwicklung fördern, allen Menschen Zugang zum Recht ermöglichen und leistungsfähige, rechenschaftspflichtige und inklusive Institutionen auf allen Ebenen aufbauen'
  },
  {
    id: 17,
    stateName: 'rahmen_question17',
    question: 'Umsetzungsmittel und globale Partnerschaft stärken - Umsetzungsmittel stärken und die globale Partnerschaft für nachhaltige Entwicklung mit neuem Leben füllen'
  }
]);

export const ESG_PROFILING_EU_KRITERIEN_UMWELT_QUESTIONS = Object.freeze([
  {
    id: 1,
    stateName: 'umwelt_question1',
    question: 'Ressourceneffizienz bei der Nutzung von Energie'
  },
  {
    id: 2,
    stateName: 'umwelt_question2',
    question: 'erneuerbare Energien'
  },
  {
    id: 3,
    stateName: 'umwelt_question3',
    question: 'Rohstoffe'
  },
  {
    id: 4,
    stateName: 'umwelt_question4',
    question: 'Wasser und Boden'
  },
  {
    id: 5,
    stateName: 'umwelt_question5',
    question: 'Abfallerzeugung'
  },
  {
    id: 6,
    stateName: 'umwelt_question6',
    question: 'Treibhausgasemissionen'
  },
  {
    id: 7,
    stateName: 'umwelt_question7',
    question: 'Auswirkungen auf die biologische Vielfalt und die Kreislaufwirtschaft'
  }
]);

export const ESG_PROFILING_EU_KRITERIEN_SOZIALE_QUESTIONS = Object.freeze([
  {
    id: 1,
    stateName: 'soziale_question1',
    question: 'Bekämpfung von Ungleichheiten'
  },
  {
    id: 2,
    stateName: 'soziale_question2',
    question: 'Sozialer Zusammenhalt, soziale Integration'
  },
  {
    id: 3,
    stateName: 'soziale_question3',
    question: 'Arbeitsbeziehungen durch Investition in Humankapital'
  },
  {
    id: 4,
    stateName: 'soziale_question4',
    question: 'Integration wirtschaftlich- oder sozial benachteiligter Bevölkerungsgruppen'
  }
]);

export const FIRST_CHART_COLOR_PALETTE = [
  '#f1aea6',
  '#ed9389',
  '#e8786b',
  '#e66a5c',
  '#e45d4e',
  '#e14f3f',
  '#df4230',
  '#cd3120',
  '#bf2e1d',
  '#b02a1b',
  '#a12719',
  '#932317',
  '#842014',
  '#751c12',
  '#671910',
  '#58150e',
  '#49120b',
  '#3b0e09',
  '#2c0b07',
  '#1d0705'
];

export const SECOND_CHART_COLOR_PALETTE = [
  '#79eb86',
  '#4ce55e',
  '#20de36',
  '#1dc731',
  '#1ab12b',
  '#179b26',
  '#138420',
  '#106E1B',
  '#0f6719',
  '#0e5f17',
  '#0d5816',
  '#0c5114',
  '#0b4912',
  '#0a4210',
  '#093b0e',
  '#07330d',
  '#062c0b',
  '#052509',
  '#041d07',
  '#031605'
];
