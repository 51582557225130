import React, { useState } from 'react'
import { Box, Button, Grid } from '@mui/material'
import InputComponent from '../../../Components/Controls/InputComponent'
import { Inertia } from '@inertiajs/inertia'
import AdminLayout from '../../../Layouts/AdminLayout'
import SwitchComponent from '../../../Components/Controls/SwitchComponent'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'

const Create = () => {
    const [icState, setIcState] = useState({
        name: '',
        wp_category: '',
        mindestgewicht: '',
        schrittweite: '',
        max_anzahl_fonds: '',
        ksvb_mindestgewicht: '',
        ksvb_schrittweite: '',
        ksv_als_rentenfondsersatz: false,
        ksvb_mindestgewicht_laufzeitjahre: false,
    })

    const handleOnChange = e => {
        const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value
        setIcState({ ...icState, [e.target.name]: value })
    }
    
    const onCreate = () => Inertia.post(route('admin.insurance_company.store'), { insurance_company: { ...icState } })

    const onBack = () => Inertia.get(route('admin.insurance_companies'))

    return (
        <AdminLayout subHeaderTitle="Versicherung erstellen">
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Button
                    variant="contained"
                    sx={{
                        bgcolor: 'black',
                        color: 'white',
                        textTransform: 'none',
                        maxWidth: '150px',
                        height: '40px',
                        mb: '20px',
                        '&:hover': { bgcolor: 'black' }
                    }}
                    startIcon={<KeyboardArrowLeftIcon />}
                    onClick={onBack}
                >
                    Zurück
                </Button>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <InputComponent
                            label="Name"
                            name="name"
                            value={icState.name}
                            onChange={handleOnChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <InputComponent
                            label="WP Category"
                            name="wp_category"
                            value={icState.wp_category}
                            onChange={handleOnChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <InputComponent
                            label="Mindestgewicht"
                            name="mindestgewicht"
                            value={icState.mindestgewicht}
                            onChange={handleOnChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <InputComponent
                            label="Schrittweite"
                            name="schrittweite"
                            value={icState.schrittweite}
                            onChange={handleOnChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <InputComponent
                            label="max. Anzahl Fonds"
                            name="max_anzahl_fonds"
                            value={icState.max_anzahl_fonds}
                            onChange={handleOnChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <InputComponent
                            label="konventionelles SV beimischbar - Mindestgewicht"
                            name="ksvb_mindestgewicht"
                            value={icState.ksvb_mindestgewicht}
                            onChange={handleOnChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <InputComponent
                            label="konventionelles SV beimischbar - Schrittweite"
                            name="ksvb_schrittweite"
                            value={icState.ksvb_schrittweite}
                            onChange={handleOnChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <SwitchComponent
                            label="konventionelles SV als Rentenfondsersatz"
                            name="ksv_als_rentenfondsersatz"
                            checked={icState.ksv_als_rentenfondsersatz}
                            onChange={handleOnChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <SwitchComponent
                            label="konventionelles SV beimischbar - höheres Mindestgewicht spätere Laufzeitjahre"
                            name="ksvb_mindestgewicht_laufzeitjahre"
                            checked={icState.ksvb_mindestgewicht_laufzeitjahre}
                            onChange={handleOnChange}
                        />
                    </Grid>
                </Grid>
                <Button
                    variant="contained"
                    sx={{
                        bgcolor: '#C94141',
                        color: 'white',
                        textTransform: 'none',
                        minWidth: '120px',
                        height: '40px',
                        mt: '20px',
                        '&:hover': { bgcolor: '#C94141' }
                    }}
                    onClick={onCreate}
                >
                    Erstellen
                </Button>
            </Box>
        </AdminLayout>
    )
}

export default Create