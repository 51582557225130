import axios from 'axios';

export const sendToggle = async (id) => {
  axios.defaults.baseURL = '/';
  try {
    const res = await axios.get('/archive/toggle/' + id);
    return { ...res.data, status: 'OK' };
  } catch (error) {
    //console.log('error', error);
    return { status: 'NOK' };
  }
};
