import React, { useEffect } from 'react';
import MainLayout from '../../Layouts/MainLayout';
import { Box, Grid, IconButton, Typography, Avatar } from '@mui/material';
import SelectComponent from '../../Components/Controls/SelectComponent';
import InputComponent from '../../Components/Controls/InputComponent';
import { useRecoilState } from 'recoil';
import { userDataState } from '../../Recoil/Atoms/PortfolioAtoms';
import { Inertia } from '@inertiajs/inertia';
import { usePage } from '@inertiajs/inertia-react';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

const Profile = ({ user }) => {
  const { errors } = usePage().props;
  //const { user } = usePage().props.auth;
  const [userData, setUserData] = useRecoilState(userDataState);
  const { unternehmen, vermittlerregisternummer, anrede, name, vorname, strasse, plz, ort, telefon, email, photo, logo } = userData;

  useEffect(() => {
    setUserData({
      unternehmen: user.unternehmen,
      vermittlerregisternummer: user.vermittlerregisternummer,
      anrede: { value: user.anrede, label: user.anrede },
      name: user.name,
      vorname: user.vorname,
      strasse: user.strasse || '',
      plz: user.plz || '',
      ort: user.ort || '',
      telefon: user.telefon,
      email: user.email,
      photo: user.photo,
      logo: user.logo
    });
  }, []);

  const handleOnSelect = (selectElement) => (item) => setUserData({ ...userData, [selectElement]: item });

  const handleOnChange = (e) => setUserData({ ...userData, [e.target.name]: e.target.value });

  const handleUploadPress = (imageInputId) => document.getElementById(imageInputId).click();

  const onChangePhoto = (e, propName) => {
    const fileInput = e.target;
    if (!!fileInput.files && fileInput.files.length > 0) {
      const file = fileInput.files[0];
      const reader = new FileReader();
      reader.onload = function (e) {
        setUserData({ ...userData, [propName]: e.target.result });
      };
      reader.onerror = function (stuff) {
        console.log(stuff.getMessage());
      };
      reader.readAsDataURL(file);
    } else {
      setUserData({ ...userData, [propName]: null });
    }
  };

  const onSave = () => {
    Inertia.post(route('user.update'), { ...userData, anrede: anrede?.value });
  };

  return (
    <MainLayout rightBtnName="Speichern" onRightBtnClick={onSave} subHeaderTitle="Einstellungen">
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'start' }}>
          <IconButton onClick={() => handleUploadPress('userPhoto')} sx={{ p: 0 }}>
            <input id="userPhoto" hidden type="file" accept="image/*" multiple={false} onChange={(e) => onChangePhoto(e, 'photo')} />
            {photo ? (
              <Avatar src={photo} sx={{ width: 70, height: 70 }} />
            ) : (
              <Avatar sx={{ width: 70, height: 70 }}>
                <AccountCircleIcon sx={{ width: 70, height: 70 }} />
              </Avatar>
            )}
          </IconButton>
          <IconButton onClick={() => handleUploadPress('logoPhoto')} sx={{ p: 0, ml: '20px' }}>
            <input id="logoPhoto" hidden type="file" accept="image/*" multiple={false} onChange={(e) => onChangePhoto(e, 'logo')} />
            {logo ? (
              <Avatar src={logo} sx={{ width: 70, height: 70 }} />
            ) : (
              <Avatar sx={{ width: 70, height: 70, bgcolor: 'white' }}>
                <Typography sx={{ color: 'black' }}>Logo</Typography>
              </Avatar>
            )}
          </IconButton>
        </Box>
        <hr style={{ width: '100%' }} />
        <Grid container spacing={3}>
          <Grid item xs={7}>
            <InputComponent label="Unternehmen *" name="unternehmen" value={unternehmen} onChange={handleOnChange} errorMsg={errors.unternehmen} />
          </Grid>
          <Grid item xs={5}>
            <InputComponent
              label="IHK Vermittlerregisternummer *"
              name="vermittlerregisternummer"
              placeholder="mind. 15 Zeichen"
              value={vermittlerregisternummer}
              onChange={handleOnChange}
              errorMsg={errors.vermittlerregisternummer}
            />
          </Grid>
          <Grid item xs={2}>
            <SelectComponent
              label="Anrede *"
              name="anrede"
              value={anrede}
              onChange={handleOnSelect('anrede')}
              options={[
                { value: 'Herr', label: 'Herr' },
                { value: 'Frau', label: 'Frau' }
              ]}
              errorMsg={errors.anrede}
            />
          </Grid>
          <Grid item xs={5}>
            <InputComponent label="Name *" name="name" value={name} onChange={handleOnChange} errorMsg={errors.name} />
          </Grid>
          <Grid item xs={5}>
            <InputComponent label="Vorname *" name="vorname" value={vorname} onChange={handleOnChange} errorMsg={errors.vorname} />
          </Grid>
          <Grid item xs={7}>
            <InputComponent label="Strasse und Hausnummer" name="strasse" value={strasse} onChange={handleOnChange} />
          </Grid>
          <Grid item xs={2}>
            <InputComponent label="PLZ" name="plz" value={plz} onChange={handleOnChange} />
          </Grid>
          <Grid item xs={3}>
            <InputComponent label="Ort" name="ort" value={ort} onChange={handleOnChange} />
          </Grid>
          <Grid item xs={3}>
            <InputComponent label="Telefon *" name="telefon" value={telefon} onChange={handleOnChange} errorMsg={errors.telefon} />
          </Grid>
          <Grid item xs={3}>
            <InputComponent label="E-Mail *" name="email" value={email} onChange={handleOnChange} errorMsg={errors.email} />
          </Grid>
        </Grid>
        <br />* Pflichtfeld
      </Box>
    </MainLayout>
  );
};

export default Profile;
