import React, { useEffect } from 'react';
import { Inertia } from '@inertiajs/inertia';
import MainLayout from '../../Layouts/MainLayout';
import { Box, Button } from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useRecoilState } from 'recoil';
import { portfolioResultState, comparisonResultState, vertragsDetailsState } from '../../Recoil/Atoms/PortfolioAtoms';
import { BREADCRUMBS_PORTFOLIO, INFO_TEXTS } from '../../Utils/Constants';
import StarIcon from '@mui/icons-material/Star';
import { DataGrid, deDE } from '@mui/x-data-grid';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const Comparison = ({ visited }) => {
  const [portfolioResult, setPortfolioResult] = useRecoilState(portfolioResultState);
  const [comparisonResult, setComparisonResult] = useRecoilState(comparisonResultState);
  const [vertragsDetails, setVertragsDetails] = useRecoilState(vertragsDetailsState);

  const theme = createTheme(
    //deDE // x-data-grid translations
    {
      components: {
        MuiDataGrid: {
          styleOverrides: {
            root: {
              border: 'none'
            },
            columnHeaderTitle: {
              fontWeight: 800
            },
            iconSeparator: {
              display: 'none'
            },
            columnHeader: {
              '&.MuiDataGrid-columnHeader:focus': {
                outline: 'none'
              }
            }
          }
        }
      }
    }
  );

  const toPortfoliovorschlag = async (id) => {
    const portfolioResult = comparisonResult.find((item) => item.id === id);
    setVertragsDetails({
      ...vertragsDetails,
      ['versichererTarif']: { value: portfolioResult.tarif, label: portfolioResult.vu_tarif },
      ['nutze_sicherungsvermoegen']: portfolioResult.inkl_sv && portfolioResult.inkl_sv === 'true'
    });
    setPortfolioResult(portfolioResult);
    Inertia.get(route('portfolio.portfoliovorschlag'));
  };

  const onBack = () => {
    Inertia.get(route('portfolio.vertragsdetails'));
  };

  return (
    <MainLayout breadcrumbs={BREADCRUMBS_PORTFOLIO} visitedPages={visited} subHeaderTitle="Tarif-Vergleich" infoText={INFO_TEXTS.COMPARISON} onLeftBtnClick={onBack}>
      <ThemeProvider theme={theme}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Box sx={{ height: 650 }}>
            <DataGrid
              rows={comparisonResult}
              columns={[
                {
                  field: 'vu_tarif',
                  headerName: 'Versicherer/Tarif',
                  resizable: false,
                  flex: 1,
                  renderCell: (cellObject) => {
                    if (cellObject.row.inkl_sv && cellObject.row.inkl_sv === 'true') return cellObject.row.vu_tarif + ' (inkl. Sicherungsvermögen)';
                    else return cellObject.row.vu_tarif;
                  }
                },
                {
                  field: 'Erwartete_Rendite',
                  headerName: 'Erwartete Rendite',
                  resizable: false,
                  flex: 1,
                  renderCell: (cellObject) => {
                    return (cellObject.value * 100).toFixed(2).replace('.', ',') + '%';
                  }
                },
                {
                  field: 'Erwartetes_Risiko',
                  headerName: 'Erwartetes Risiko',
                  resizable: false,
                  flex: 1,
                  renderCell: (cellObject) => {
                    return (cellObject.value * 100).toFixed(2).replace('.', ',') + '%';
                  }
                },
                {
                  field: 'fpr_classic_rating',
                  headerName: 'FPR Classic Gesamt-Rating',
                  resizable: false,
                  flex: 1,
                  renderCell: (cellObject) => {
                    return [...Array(cellObject.value)].map((_, index) => <StarIcon key={index} sx={{ color: '#C94141' }} size="small" />);
                  }
                },
                {
                  field: 'fpr_nachh_rating',
                  headerName: 'FPR Nachh. Gesamt-Rating',
                  resizable: false,
                  flex: 1,
                  renderCell: (cellObject) => {
                    return [...Array(cellObject.value)].map((_, index) => <StarIcon key={index} sx={{ color: '#69B978' }} size="small" />);
                  }
                },
                {
                  field: 'aktion',
                  headerName: 'Aktion',
                  resizable: false,
                  flex: 1,
                  sortable: false,
                  renderCell: (cellObject) => {
                    return (
                      <Box>
                        <Button
                          variant="contained"
                          sx={{
                            bgcolor: '#C94141',
                            color: 'white',
                            textTransform: 'none',
                            height: '40px',
                            '&:hover': { bgcolor: '#C94141' }
                          }}
                          onClick={() => toPortfoliovorschlag(cellObject.row.id)}
                          endIcon={<KeyboardArrowRightIcon />}>
                          zum Portfoliovorschlag
                        </Button>
                      </Box>
                    );
                  }
                }
              ]}
              hideFooter={true}
              disableColumnMenu={true}
              disableSelectionOnClick={true}
              rowsPerPageOptions={[50]}
              pageSize={50}
            />
          </Box>
        </Box>
      </ThemeProvider>
    </MainLayout>
  );
};

export default Comparison;
