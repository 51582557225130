import React from "react";
import { Box, Button } from "@mui/material";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import SaveIcon from "@mui/icons-material/Save";
import EditNoteIcon from "@mui/icons-material/EditNote";
import CompareIcon from "@mui/icons-material/Compare";

const Footer = ({
  leftBtnName = "Zurück",
  rightBtnName = "Weiter",
  onLeftBtnClick,
  onRightBtnClick,
  hideArrow,
  rightBtnDisabled = false,
  printButtonHidden = true,
  saveButtonHidden = true,
  comparisonButtonHidden = true,
  onPrintBtnClick,
  onSaveBtnClick,
  onComparisonBtnClick,
  createApplicationButtonHidden = true,
  onCreateApplicationBtnClick,
  createApplicationButtonLabel = "Antrag"
}) => {
  let justifyContent = "space-between";
  if (!onLeftBtnClick && onRightBtnClick) justifyContent = "flex-end";

  if (!onRightBtnClick && !onLeftBtnClick) {
    return null;
  }

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        px: "90px",
        py: "10px",
        bgcolor: "#FFFFFF",
        mt: "auto",
        justifyContent
      }}>
      {onLeftBtnClick && (
        <Button
          variant="contained"
          sx={{
            bgcolor: "black",
            color: "white",
            textTransform: "none",
            minWidth: "210px",
            height: "40px",
            "&:hover": { bgcolor: "black" }
          }}
          onClick={onLeftBtnClick}
          startIcon={<KeyboardArrowLeftIcon />}>
          {leftBtnName}
        </Button>
      )}
      <Box sx={{ display: "flex" }}>
        {!saveButtonHidden && (
          <Button
            variant="contained"
            sx={{
              bgcolor: "black",
              color: "white",
              textTransform: "none",
              minWidth: "210px",
              height: "40px",
              mr: "5px",
              "&:hover": { bgcolor: "black" }
            }}
            onClick={onSaveBtnClick}
            endIcon={<SaveIcon />}>
            Speichern
          </Button>
        )}

        {!createApplicationButtonHidden && (
          <Button
            variant="contained"
            sx={{
              bgcolor: "black",
              color: "white",
              textTransform: "none",
              minWidth: "210px",
              height: "40px",
              mr: "5px",
              "&:hover": { bgcolor: "black" }
            }}
            onClick={onCreateApplicationBtnClick}
            endIcon={<EditNoteIcon />}>
            {createApplicationButtonLabel}
          </Button>
        )}
        {!printButtonHidden && (
          <Button
            variant="contained"
            sx={{
              bgcolor: "black",
              color: "white",
              textTransform: "none",
              minWidth: "210px",
              height: "40px",
              mr: "5px",
              "&:hover": { bgcolor: "black" }
            }}
            onClick={onPrintBtnClick}
            endIcon={<PictureAsPdfIcon />}>
            Drucken
          </Button>
        )}
        {!comparisonButtonHidden && (
          <Button
            variant="contained"
            sx={{
              bgcolor: "#C94141",
              color: "white",
              textTransform: "none",
              minWidth: "210px",
              height: "40px",
              mr: "5px",
              "&:hover": { bgcolor: "#C94141" }
            }}
            disabled={rightBtnDisabled}
            onClick={onComparisonBtnClick}
            endIcon={<CompareIcon />}>
            zum Vergleich
          </Button>
        )}

        {onRightBtnClick && (
          <Button
            variant="contained"
            sx={{
              bgcolor: "#C94141",
              color: "white",
              textTransform: "none",
              minWidth: "210px",
              height: "40px",
              "&:hover": { bgcolor: "#C94141" }
            }}
            disabled={rightBtnDisabled}
            onClick={onRightBtnClick}
            endIcon={hideArrow ? undefined : <KeyboardArrowRightIcon />}>
            {rightBtnName}
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default Footer;
