import axios from "axios";

export const createApplication = async (printData) => {
  axios.defaults.baseURL = "/";
  return await axios
    .post(
      route("application.create"),
      { printData },
      {
        headers: { "Content-Type": "application/json" },
      }
    )
    .then((res) => {
      console.log("res.data", res);
      if (res.status === 200) {
        window.open(res.data.redirect_url, "_blank");
        return { success: true, message: "Vervollständigen Sie den Antrag bitte in dem neu geöffneten Fenster!" };
      } else {
        return { success: false, message: res.data.message };
      }
    })
    .catch((e) => {
      console.error("error", e);
      return { success: false, message: "Antrag konnte nich erzeugt werden (Exception)." };
    });
};
