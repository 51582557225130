import { Inertia } from '@inertiajs/inertia';
import { Box, Button, ButtonGroup, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { ArcElement, CategoryScale, Chart as ChartJS, Legend, LineElement, LinearScale, PointElement, Tooltip as TooltipChart } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import React, { useEffect, useState } from 'react';
import { Doughnut, Line } from 'react-chartjs-2';
import { useRecoilState, useRecoilValue } from 'recoil';
import MainLayout from '../../Layouts/MainLayout';
import { existingPolicyState, userLicenceState } from '../../Recoil/Atoms/PortfolioAtoms';
import { calculateExisting } from '../../Services/OptimizeExistingService';
import { BREADCRUMBS_OPTIMIZATION, SECOND_CHART_COLOR_PALETTE } from '../../Utils/Constants';

ChartJS.register(ArcElement, TooltipChart, ChartDataLabels, Legend, CategoryScale, LinearScale, LineElement, PointElement);

const chartOptions = {
  responsive: true,
  maintainAspectRatio: true,
  devicePixelRatio: 2,
  layout: {
    padding: 20
  },
  plugins: {
    datalabels: {
      display: false
    },
    legend: {
      display: true,
      position: 'bottom'
    },
    tooltip: {
      callbacks: {
        label: function (context) {
          let label = context.label || '';

          if (label) {
            label += ': ';
          }
          if (context.parsed !== null) {
            label += context.parsed + '%';
          }
          return label;
        }
      }
    }
  }
};

const formatDate = (inputDateString) => {
  const inputDate = new Date(inputDateString);

  const day = inputDate.getDate().toString().padStart(2, '0');
  const month = (inputDate.getMonth() + 1).toString().padStart(2, '0'); // Monate beginnen bei 0
  const year = inputDate.getFullYear();

  return `${day}.${month}.${year}`;
};

const generateChart2Data = (chartData) => {
  return {
    labels: chartData?.map((item) => item.fondsname),
    datasets: [
      {
        data: chartData?.map((item) => item.gewichtung),
        cutout: '70%',
        backgroundColor: chartData?.map((item, index) => SECOND_CHART_COLOR_PALETTE[index]),
        datalabels: {
          anchor: 'end',
          align: 'end',
          offset: 6
        }
      }
    ]
  };
};

const generateChartDataEntwicklung = (chartData) => {
  return {
    labels: chartData?.map((item) => formatDate(item.Datum)),
    datasets: [
      {
        data: chartData?.map((item) => item.Wert),
        borderColor: 'rgb(255, 165, 0)',
        backgroundColor: 'rgba(255, 165, 0, 0.5)',
        datalabels: {
          anchor: 'end',
          align: 'end',
          offset: 30
        }
      }
    ]
  };
};

const ErgebnisOptimizeExisting = ({ visited }) => {
  const userLicence = useRecoilValue(userLicenceState);
  const [existingPolicy, setExistingPolicy] = useRecoilState(existingPolicyState);
  const [calcResult, setCalcResult] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const formattedChart2Data = generateChart2Data(existingPolicy && existingPolicy.fonds);
  const formattedChartDataEntwicklung = generateChartDataEntwicklung(calcResult && calcResult.entwicklung);

  const setSelectedYears = async (selected_years) => {
    switch (selected_years) {
      case 3:
        if (existingPolicy.calcResult3.beginn) {
          setExistingPolicy({
            ...existingPolicy,
            selected_years: selected_years
          });
        } else {
          const today = new Date();
          const beginn = today.setFullYear(today.getFullYear() - 3);
          setIsLoading(true);
          const calcResult = await calculateExisting(existingPolicy.fonds, beginn);
          setIsLoading(false);
          setExistingPolicy({
            ...existingPolicy,
            selected_years: selected_years,
            calcResult3: {
              beginn: beginn,
              entwicklung: calcResult.entwicklung,
              mdd: calcResult.mdd,
              perf_kumm: calcResult.performance.kummuliert,
              perf_pro_jahr: calcResult.performance.pro_jahr
            }
          });
        }
        break;
      case 5:
        setCalcResult(existingPolicy.calcResult5);
        setExistingPolicy({
          ...existingPolicy,
          selected_years: selected_years
        });
        break;
      default:
    }
  };

  useEffect(() => {
    if (existingPolicy) {
      if (existingPolicy.selected_years === 5) setCalcResult(existingPolicy.calcResult5);
      else setCalcResult(existingPolicy.calcResult3);
    }
  }, [existingPolicy]);

  useEffect(() => {
    if (!userLicence) {
      Inertia.get('/');
    }
  }, []);

  const onNext = async () => {
    Inertia.get(route('portfolio.risikoProfiling'));
  };

  const onBack = () => Inertia.get(route('portfolio.optimizeExisting'));

  return (
    <MainLayout
      breadcrumbs={BREADCRUMBS_OPTIMIZATION}
      visitedPages={visited}
      isLoading={isLoading}
      subHeaderTitle="Auswertung eines bestehenden Portfolios (Ergebnis)"
      rightBtnName="Vorschlag erstellen"
      onRightBtnClick={onNext}
      onLeftBtnClick={onBack}
      rightBtnDisabled={false}>
      <Box sx={{ pt: '3px', pb: '30px', ml: '30px', mr: '30px', width: '100%' }}>
        <Grid container="true" spacing={4} columns={10} sx={{ pt: '5px', pb: '5px' }}>
          <Grid sm={10} md={4} lg={4} xl={4} sx={{ p: '20px', width: '100%' }}>
            <Doughnut id="chartAufteilung" data={formattedChart2Data} options={chartOptions} />
            <Grid container columns={12} spacing={2} sx={{ width: '100%' }}>
              <Grid item xs={6}>
                <Box height={100} sx={{ bgcolor: '#EBEBEB', borderRadius: '6px', mt: '20px', p: '15px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                  Rendite p.a.
                  <Typography variant="h4">{calcResult?.perf_pro_jahr?.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}%</Typography>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box height={100} sx={{ bgcolor: '#EBEBEB', borderRadius: '6px', mt: '20px', p: '15px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                  Risiko (MDD)
                  <Typography variant="h4">{calcResult?.mdd?.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}%</Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            sm={10}
            md={4}
            lg={6}
            xl={6}
            sx={{
              p: '20px',
              width: '100%',
              flexDirection: 'column',
              display: 'flex'
            }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '10px', marginBottom: 'auto' }}>
              <Line
                id="chartEntwicklung"
                data={formattedChartDataEntwicklung}
                options={{
                  elements: {
                    point: {
                      radius: 0
                    }
                  },
                  responsive: true,
                  maintainAspectRatio: true,
                  plugins: {
                    title: {
                      display: true,
                      font: {
                        family: 'Roboto, Helvetica, Arial, sans-serif',
                        weight: '400',
                        size: 20
                      },
                      text: 'Entwicklung'
                    },
                    legend: {
                      display: false,
                      position: 'bottom'
                    },
                    tooltip: {
                      callbacks: {
                        label: function (context) {
                          return ' ' + context.formattedValue + '%';
                        }
                      }
                    },
                    datalabels: {
                      display: false
                    }
                  }
                }}
              />
              <Box sx={{ display: 'flex', gap: '10px' }}>
                <ButtonGroup sx={{ mb: '20px' }}>
                  <Button
                    disabled={existingPolicy?.selected_years === 3}
                    sx={{
                      textTransform: 'none'
                    }}
                    onClick={(e) => setSelectedYears(3)}>
                    3 Jahre
                  </Button>
                  <Button
                    disabled={existingPolicy?.selected_years === 5}
                    sx={{
                      textTransform: 'none'
                    }}
                    onClick={(e) => setSelectedYears(5)}>
                    5 Jahre
                  </Button>
                </ButtonGroup>
              </Box>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', bgcolor: '#EBEBEB', borderRadius: '6px', mt: '20px', p: '15px', width: '100%', marginTop: 'auto' }}>
              <Box sx={{ flex: '0 0 auto', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <img src={`/images/Bestehender_Vertrag.png`} alt="" height="101px" />
              </Box>
              <Box sx={{ flex: 1, marginLeft: '15px' }}>
                <Typography>
                  Die historischen Werte bieten einen Indikator, ob das bestehende Investment den Erwartungen nach Zielrendite und Risiko entspricht.
                  <br />
                  Erstellen Sie einen individuellen Vorschlag, in dem Sie die aktuellen Rendite/Risiko- und ESG-Präferenzen des Kunden ermitteln.
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Typography>
        Die angezeigten Werte beziehen sich maximal auf die vergangenen {existingPolicy.selected_years} Jahre. Sollte einer der Fonds eine kürzere Laufzeit haben, verkürzt sich die
        Vergangenheitsbetrachtung auf diesen Zeitraum. Vergangenheitswerte haben keine Aussage für die Entwicklung in der Zukunft. Sie können lediglich dazu dienen, angestrebte Ziele in der Rendite
        oder die Einhaltung des Risikobudgets zu überprüfen. Die Werte beinhalten keine Kosten auf Vertrags- bzw. Versicherungsebene. Sie entsprechen einer Direktanlage außerhalb eines
        Versicherungsprodukts.
        <br />
        Quelle: Edisoft
      </Typography>
    </MainLayout>
  );
};

export default ErgebnisOptimizeExisting;
