import React, { useState } from 'react'
import MainLayout from '../../Layouts/MainLayout'
import LoginLayout from '../../Components/Shared/LoginLayout'
import { Box, Typography, Button } from '@mui/material'
import InputComponent from '../../Components/Controls/InputComponent'
import { Inertia } from '@inertiajs/inertia'
import { usePage } from '@inertiajs/inertia-react'

const ResetPassword = ({ token, email }) => {
    const { errors } = usePage().props
    const [userData, setUserData] = useState({ token, email, password: '', password_confirmation: '' })

    const handleOnChange = (e) => {
        setUserData({ ...userData, [e.target.name]: e.target.value })
    }

    const onResetPassword = () => {
        Inertia.post(route('password.update'), { ...userData })
    }

    return (
        <MainLayout>
            <LoginLayout>
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography sx={{ color: 'black', fontWeight: 'bold', fontSize: '28px' }}>Passwort zurücksetzen</Typography>
                        <Box component="span" sx={{ color: 'black', fontWeight: 'bold', fontSize: '28px' }}>s<Box component="span" sx={{ color: '#C94141' }}>a</Box>mperform</Box>
                    </Box>
                    <img src="/images/SAM_Logo.png" alt="" width="64px" height="64px" />
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <InputComponent
                        label="E-Mail Adresse"
                        name="email"
                        value={userData.email}
                        onChange={handleOnChange}
                        styles={{ marginBottom: '10px' }}
                        errorMsg={errors.email}
                    />
                    <InputComponent
                        type="password"
                        label="Passwort"
                        name="password"
                        value={userData.password}
                        onChange={handleOnChange}
                        styles={{ marginBottom: '10px' }}
                        errorMsg={errors.password}
                    />
                    <InputComponent
                        type="password"
                        label="Passwort Bestätigung *"
                        name="password_confirmation"
                        value={userData.password_confirmation}
                        onChange={handleOnChange}
                        styles={{ marginBottom: '10px' }}
                        errorMsg={errors.password_confirmation}
                    />
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Button
                        variant="contained"
                        onClick={onResetPassword}
                        sx={{
                            bgcolor: '#C94141',
                            color: 'white',
                            textTransform: 'none',
                            height: '40px',
                            '&:hover': { bgcolor: '#C94141' }
                        }}
                    >
                        Passwort zurücksetzen
                    </Button>
                </Box>
            </LoginLayout>
        </MainLayout>
    )
}

export default ResetPassword