require('./bootstrap');
import { InertiaProgress } from '@inertiajs/progress';
import React from 'react';
import { render } from 'react-dom';
import { RecoilRoot } from 'recoil';
import Main from './Main';
InertiaProgress.init();

const el = document.getElementById('app');
render(
  <RecoilRoot>
    <Main />
  </RecoilRoot>,
  el
);
