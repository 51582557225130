import React, { useState } from 'react';
import { Box, Button, Grid } from '@mui/material';
import InputComponent from '../../../Components/Controls/InputComponent';
import { Inertia } from '@inertiajs/inertia';
import AdminLayout from '../../../Layouts/AdminLayout';
import SelectComponent from '../../../Components/Controls/SelectComponent';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import SwitchComponent from '../../../Components/Controls/SwitchComponent';

const Edit = ({ licence, insuranceCompanies }) => {
  const [companies, setCompanies] = useState(licence.insurance_companies.map((x) => ({ label: x.name, value: x.id })));
  const insuranceCompanyOptions = insuranceCompanies.map((x) => {
    return { label: x.name, value: x.id };
  });
  const [licenceState, setLicenceState] = useState({
    name: licence.name,
    can_premium: licence.can_premium
  });

  const handleOnChange = (e) => {
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    setLicenceState({ ...licenceState, [e.target.name]: value });
  };

  const handleOnSelect = (companies) => setCompanies(companies);

  const onUpdate = () =>
    Inertia.put(route('admin.licence.update', licence.id), { name: licenceState.name, can_premium: licenceState.can_premium, insurance_company_ids: companies.map((x) => x.value) });

  const onBack = () => Inertia.get(route('admin.licences'));

  return (
    <AdminLayout subHeaderTitle="Lizenz bearbeiten">
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Button
          variant="contained"
          sx={{
            bgcolor: 'black',
            color: 'white',
            textTransform: 'none',
            maxWidth: '150px',
            height: '40px',
            mb: '20px',
            '&:hover': { bgcolor: 'black' }
          }}
          startIcon={<KeyboardArrowLeftIcon />}
          onClick={onBack}>
          Zurück
        </Button>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <InputComponent label="Name" name="name" value={licenceState.name} onChange={handleOnChange} />
          </Grid>
          <Grid item xs={12}>
            <SwitchComponent label="Premium verfügbar" name="can_premium" checked={licenceState.can_premium} onChange={handleOnChange} />
          </Grid>
          <Grid item xs={12}>
            <SelectComponent label="Versicherung" name="insuranceCompany" value={companies} onChange={handleOnSelect} options={insuranceCompanyOptions} isMulti={true} />
          </Grid>
        </Grid>
        <Button
          variant="contained"
          sx={{
            bgcolor: '#C94141',
            color: 'white',
            textTransform: 'none',
            minWidth: '120px',
            height: '40px',
            mt: '20px',
            '&:hover': { bgcolor: '#C94141' }
          }}
          onClick={onUpdate}>
          Aktualisieren
        </Button>
      </Box>
    </AdminLayout>
  );
};

export default Edit;
