import React, { useState } from "react";
import { Box, Button, Grid } from "@mui/material";
import InputComponent from "../../../Components/Controls/InputComponent";
import { Inertia } from "@inertiajs/inertia";
import AdminLayout from "../../../Layouts/AdminLayout";
import SelectComponent from "../../../Components/Controls/SelectComponent";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";

const Edit = ({ keyItem, licences }) => {
	const [keyCode, setKeyCode] = useState(keyItem.key_code);
	const [licence, setLicence] = useState({ label: keyItem.licence.name, value: keyItem.licence.id });
	const licenceOptions = licences.map((x) => {
		return { label: x.name, value: x.id };
	});

	const handleOnChange = (e) => setKeyCode(e.target.value);
	const handleOnSelect = (licence) => setLicence(licence);

	const onUpdate = () => Inertia.put(route("admin.key.update", keyItem.id), { key_code: keyCode, licence_id: licence.value });

	const onBack = () => Inertia.get(route("admin.keys"));

	return (
		<AdminLayout subHeaderTitle="Key bearbeiten">
			<Box sx={{ display: "flex", flexDirection: "column" }}>
				<Button
					variant="contained"
					sx={{
						bgcolor: "black",
						color: "white",
						textTransform: "none",
						maxWidth: "150px",
						height: "40px",
						mb: "20px",
						"&:hover": { bgcolor: "black" },
					}}
					startIcon={<KeyboardArrowLeftIcon />}
					onClick={onBack}
				>
					Zurück
				</Button>
				<Grid container spacing={3}>
					<Grid item xs={12}>
						<InputComponent label="Key Code" name="keyCode" value={keyCode} onChange={handleOnChange} />
					</Grid>
					<Grid item xs={12}>
						<SelectComponent label="Lizenz" name="licence" value={licence} onChange={handleOnSelect} options={licenceOptions} />
					</Grid>
				</Grid>
				<Button
					variant="contained"
					sx={{
						bgcolor: "#C94141",
						color: "white",
						textTransform: "none",
						minWidth: "120px",
						height: "40px",
						mt: "20px",
						"&:hover": { bgcolor: "#C94141" },
					}}
					onClick={onUpdate}
				>
					Aktualisieren
				</Button>
			</Box>
		</AdminLayout>
	);
};

export default Edit;
