import React, { useState } from "react";
import { Inertia } from "@inertiajs/inertia";
import { Box, Button, IconButton } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { DataGrid, deDE } from "@mui/x-data-grid";
import AdminLayout from "../../../Layouts/AdminLayout";
import AddIcon from "@mui/icons-material/Add";
import ConfirmDialog from "../../../Components/Shared/ConfirmDialog";
import DeleteIcon from "@mui/icons-material/Delete";
import { compareAsc, format, parse, parseISO } from "date-fns";
import VisibilityIcon from "@mui/icons-material/Visibility";

const Index = ({ calculations }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [idToDelete, setIdToDelete] = useState(undefined);
  const [deleteDialogOpened, setDeleteDialogOpened] = useState(false);

  const addCalculation = () => Inertia.get(route("admin.calculation.create"));

  const viewCalculation = (id) => Inertia.get(route("admin.calculation.view", id));

  const showDeleteDialog = (itemId) => {
    setDeleteDialogOpened(true);
    setIdToDelete(itemId);
  };

  const deleteCalculation = () => {
    Inertia.delete(route("admin.calculation.destroy", idToDelete), {
      onStart: () => setIsLoading(true),
      onSuccess: () => setIdToDelete(undefined),
      onFinish: () => setIsLoading(false),
    });
  };

  const theme = createTheme(
    deDE // x-data-grid translations
  );

  return (
    <AdminLayout subHeaderTitle="Portfolios" isLoading={isLoading}>
      <Button
        variant="contained"
        sx={{
          bgcolor: "#C94141",
          color: "white",
          textTransform: "none",
          minWidth: "120px",
          height: "40px",
          mb: "20px",
          "&:hover": { bgcolor: "#C94141" },
        }}
        onClick={addCalculation}
        endIcon={<AddIcon />}
      >
        Portfolio
      </Button>
      {calculations.length > 0 && (
        <Box sx={{ height: 650 }}>
          <ThemeProvider theme={theme}>
            <DataGrid
              rows={calculations
                .sort((a, b) => {
                  return b.id - a.id;
                })
                .map((item) => {
                  return {
                    id: item.id,
                    tarif: item.tarif,
                    insuranceCompany: item.insurance_company.name,
                    updated_at: item.insurance_company.updated_at,
                  };
                })}
              columns={[
                { field: "id", headerName: "ID", resizable: false, flex: 1, maxWidth: 80 },
                { field: "tarif", headerName: "Tarif", resizable: false, flex: 1 },
                { field: "insuranceCompany", headerName: "Versicherung", resizable: false, flex: 1 },
                {
                  field: "updated_at",
                  headerName: "Aktualisiert",
                  resizable: false,
                  flex: 1,
                  sortComparator: (v1, v2) => {
                    const date1 = parse(v1, "dd.MM.yyyy", new Date());
                    const date2 = parse(v2, "dd.MM.yyyy", new Date());
                    return compareAsc(date1, date2);
                  },
                  renderCell: (cellObject) => <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>{format(parseISO(cellObject.value), "dd.MM.yyyy")}</Box>,
                },
                {
                  field: "aktion",
                  headerName: "Aktion",
                  resizable: false,
                  flex: 1,
                  sortable: false,
                  minWidth: 130,
                  renderCell: (cellObject) => (
                    <Box>
                      <IconButton onClick={() => viewCalculation(cellObject.row.id)}>
                        <VisibilityIcon />
                      </IconButton>
                      <IconButton onClick={() => showDeleteDialog(cellObject.row.id)}>
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  ),
                },
              ]}
              disableColumnMenu={true}
              disableSelectionOnClick={true}
              rowsPerPageOptions={[10]}
              pageSize={10}
            />
          </ThemeProvider>
        </Box>
      )}
      <ConfirmDialog title="Löschen" open={deleteDialogOpened} setOpen={setDeleteDialogOpened} onConfirm={deleteCalculation}>
        Soll dieses Portfolio wirklich gelöscht werden?
      </ConfirmDialog>
    </AdminLayout>
  );
};

export default Index;
