import React, { useEffect } from 'react';
import { Inertia } from '@inertiajs/inertia';
import MainLayout from '../../Layouts/MainLayout';
import { Box, Tooltip, IconButton } from '@mui/material';
import SelectComponent from '../../Components/Controls/SelectComponent';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import InputComponent from '../../Components/Controls/InputComponent';
import SwitchComponent from '../../Components/Controls/SwitchComponent';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  portfolioResultState,
  comparisonResultState,
  risikoProfilingState,
  umsetzungState,
  vertragsDetailsState,
  tarifListState,
  kundenProfilDisplayState,
  isFastLaneState,
  userLicenceState,
  zahlweiseListState,
  minBeitragState
} from '../../Recoil/Atoms/PortfolioAtoms';
import { BREADCRUMBS_PORTFOLIO, INFO_TEXTS } from '../../Utils/Constants';
import { canUseSicherungsvermoegen, canSeeSicherungsvermoegen } from '../../Utils/PortfolioHelpers';
import { ESGState, zahlweiseLabelState } from '../../Recoil/Selectors/PortfolioSelectors';
import { usePage } from '@inertiajs/inertia-react';
import { calculate, compare } from '../../Services/PortfolioService';
import { useSnackbar } from 'notistack';

const Vertragsdetails = ({ visited }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { user } = usePage().props.auth;
  const risikoProfiling = useRecoilValue(risikoProfilingState);
  const kundenProfilDisplay = useRecoilValue(kundenProfilDisplayState);
  const esg = useRecoilValue(ESGState);
  const umsetzung = useRecoilValue(umsetzungState);
  const zahlweiseLabel = useRecoilValue(zahlweiseLabelState);
  const [vertragsDetails, setVertragsDetails] = useRecoilState(vertragsDetailsState);
  const userLicence = useRecoilValue(userLicenceState);
  const [portfolioResult, setPortfolioResult] = useRecoilState(portfolioResultState);
  const [comparisonResult, setComparisonResult] = useRecoilState(comparisonResultState);
  const [zahlweiseList, setZahlweiseList] = useRecoilState(zahlweiseListState);
  const [isFastLane, setIsFastLane] = useRecoilState(isFastLaneState);
  const [minBeitrag, setMinBeitrag] = useRecoilState(minBeitragState);
  const { versichererTarif, beitragshohe, jahren, name, vorgangsnummer, vorname, zahlweise, nutze_sicherungsvermoegen, vertragsnummer } = vertragsDetails;
  const beitragszahldauerOptions = [];
  const tarifList = useRecoilValue(tarifListState);

  let beitrag = 0;
  if (zahlweise?.value === 'einmalig') {
    beitrag = vertragsDetails?.versichererTarif?.mb_einmal;
  }
  if (zahlweise?.value === 'laufend') {
    beitrag = vertragsDetails?.versichererTarif?.mb_laufend;
  }

  const minBeitragLabel = 'mind. ' + beitrag?.toLocaleString('de-DE') + ' EUR';
  const minBeitragValue = beitrag;

  const rightBtnDisabled = !versichererTarif || !beitragshohe || !jahren || !zahlweise || beitragshohe < minBeitragValue;

  for (let index = 2; index <= 40; index++) {
    beitragszahldauerOptions.push({
      value: `${index} Jahre`,
      label: `${index} Jahre`,
      searchParam: index
    });
  }
  beitragszahldauerOptions.push({
    value: '>40 Jahre',
    label: '>40 Jahre',
    searchParam: 41
  });

  function hasPermission(permission) {
    const { allPermissions } = usePage().props.auth;
    if (allPermissions) return allPermissions.filter((item) => item.name === permission).length > 0;
    else return false;
  }

  useEffect(() => {
    let zahlweiseItems = [];
    if (versichererTarif?.mb_laufend > 0) zahlweiseItems.push({ value: 'laufend', label: 'laufend' });
    if (versichererTarif?.mb_einmal > 0) zahlweiseItems.push({ value: 'einmalig', label: 'einmalig' });
    setZahlweiseList(zahlweiseItems);
    if (zahlweiseItems?.length === 1) {
      setVertragsDetails({ ...vertragsDetails, zahlweise: zahlweiseItems[0] });
    }
  }, [versichererTarif]);

  useEffect(() => {
    if (zahlweiseList?.length === 1) {
      setVertragsDetails({ ...vertragsDetails, zahlweise: zahlweiseList[0] });
    }
    if (!isFastLane && (risikoProfiling.question3.answer === undefined || risikoProfiling.question4.answer === undefined || umsetzung === undefined)) {
      Inertia.get('/');
    }
  }, []);

  const onNext = async () => {
    const position = versichererTarif.value.indexOf(' ');
    const insuranceCompanyId = versichererTarif.value.substring(0, position);
    const tarif = versichererTarif.value.substring(position + 1);
    const resultPortfolio = await calculate(
      insuranceCompanyId,
      tarif,
      jahren.searchParam,
      zahlweise.value,
      kundenProfilDisplay.kundenprofil,
      umsetzung,
      esg.esg,
      canSeeSicherungsvermoegen(userLicence) && canUseSicherungsvermoegen(kundenProfilDisplay, versichererTarif, esg) ? nutze_sicherungsvermoegen : false
    );

    setPortfolioResult(resultPortfolio);
    Inertia.get(route('portfolio.portfoliovorschlag'));
  };

  const onBack = () => {
    if (isFastLane) {
      Inertia.get(route('portfolio.fastLane'));
    } else {
      Inertia.get(route('portfolio.etf'));
    }
  };

  const onComparison = async () => {
    const resultComparison = await compare(jahren.searchParam, zahlweise.value, kundenProfilDisplay.kundenprofil, umsetzung, esg.esg);
    if (resultComparison) {
      setPortfolioResult(resultComparison);
      setComparisonResult(resultComparison);
      Inertia.get(route('portfolio.comparison'));
    } else {
      enqueueSnackbar('Vergleich nicht möglich!', {
        variant: 'error'
      });
    }
  };

  const handleOnSelect = (selectElement) => (item) => {
    setVertragsDetails({ ...vertragsDetails, [selectElement]: item });
  };

  const handleOnChange = (e) => {
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    setVertragsDetails({ ...vertragsDetails, [e.target.name]: value });
  };

  return (
    <MainLayout
      breadcrumbs={BREADCRUMBS_PORTFOLIO}
      visitedPages={visited}
      subHeaderTitle="Vertragsdetails"
      rightBtnName="zum Portfoliovorschlag"
      infoText={INFO_TEXTS.VERTRAGS_DETAILS}
      onRightBtnClick={onNext}
      onLeftBtnClick={onBack}
      onComparisonBtnClick={onComparison}
      rightBtnDisabled={rightBtnDisabled}
      comparisonButtonHidden={!hasPermission('comparison')}>
      <Box sx={{ pb: '30px', marginLeft: '30px', marginRight: '30px', width: '60%' }}>
        <SelectComponent label="Versicherer/Tarif *" name="versichererTarif" value={versichererTarif} onChange={handleOnSelect('versichererTarif')} options={tarifList} />
        <SelectComponent label="Beitragszahlweise *" name="zahlweise" value={zahlweise} width="250px" onChange={handleOnSelect('zahlweise')} options={zahlweiseList} />
        <SelectComponent
          label={`${zahlweiseLabel} *`}
          info={
            <Tooltip title={'Beachten Sie die Mindestlaufzeiten des Tarifs'} arrow>
              <IconButton sx={{ ml: '5px', p: '4px' }}>
                <InfoOutlinedIcon />
              </IconButton>
            </Tooltip>
          }
          name="jahren"
          value={jahren}
          width="250px"
          onChange={handleOnSelect('jahren')}
          options={beitragszahldauerOptions}
        />
        <InputComponent
          isNumberFormat={true}
          label="Beitragshöhe *"
          name="beitragshohe"
          placeholder={minBeitragLabel}
          value={beitragshohe}
          error={!beitragshohe || beitragshohe < minBeitragValue}
          onChange={handleOnChange}
          styles={{ width: '250px' }}
        />
        {canSeeSicherungsvermoegen(userLicence) && (
          <SwitchComponent
            label="Sicherungsvermögen"
            info={
              <Tooltip title={'Mit der Aktivierung der Funktion „Sicherungsvermögen“ werden insbesondere Rentenfonds teilweise durch das Sicherungsvermögen ersetzt.'} arrow>
                <IconButton sx={{ ml: '5px', p: '4px' }}>
                  <InfoOutlinedIcon />
                </IconButton>
              </Tooltip>
            }
            name="nutze_sicherungsvermoegen"
            checked={nutze_sicherungsvermoegen}
            disabled={!canUseSicherungsvermoegen(kundenProfilDisplay, versichererTarif, esg)}
            onChange={handleOnChange}
          />
        )}
        <InputComponent disabled={!user} label="Vorgangsnummer" name="vorgangsnummer" value={vorgangsnummer} onChange={handleOnChange} />
        <InputComponent disabled={!user} label="Vertragsnummer" name="vertragsnummer" value={vertragsnummer} onChange={handleOnChange} />
        <InputComponent disabled={!user} label="Name" name="name" value={name} onChange={handleOnChange} />
        <InputComponent disabled={!user} label="Vorname" name="vorname" value={vorname} onChange={handleOnChange} />
        <br />* Pflichtfeld
      </Box>
    </MainLayout>
  );
};

export default Vertragsdetails;
