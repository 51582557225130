import React from 'react';
import { CircularProgress, Box } from '@mui/material';

const LoadingSpinnerComponent = ({ visible, title }) => {
  if (!visible) {
    return null;
  }

  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        zIndex: 9999
      }}>
      <CircularProgress size={150} variant="indeterminate" title={title} sx={{ color: '#C94141' }} />
    </Box>
  );
};

export default LoadingSpinnerComponent;
