import { Box, Button } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import DnDFileUpload from '../../../Components/Controls/DnDFileUpload';
import LoadingSpinnerComponent from '../../../Components/Shared/LoadingSpinnerComponent';
import { checkISIN, ocrExtract } from '../../../Services/OptimizeExistingService';

const UploadOCRComponent = (props) => {
  const { setRows, setOpenCD } = props;
  const [upload, setUpload] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);

  const processUpload = async () => {
    setIsLoading(true);
    const result = await ocrExtract(upload[0].filedata, upload[0].filename);
    if (result.status === 'NOK') {
      setIsLoading(false);
      enqueueSnackbar('Fehler - versuchen Sie es bitte später noch einmal', { variant: 'error' });
    } else {
      const enrichedRows = (
        await Promise.all(
          result.rows.map(async (row) => {
            const isin = row.isin;
            const gewichtung = row.gewichtung;
            if (isin !== undefined && gewichtung !== undefined) {
              const checkResult = await checkISIN(isin);
              if (checkResult.status === 'OK') {
                const fondsname = checkResult.kurzname;
                return { id: uuidv4(), isin: isin, fondsname: fondsname, gewichtung: gewichtung };
              } else {
                enqueueSnackbar('ungültige ISIN: ' + isin, { variant: 'error' });
                return undefined;
              }
            } else {
              return undefined;
            }
          })
        )
      ).filter((result) => result !== undefined);
      setIsLoading(false);
      setRows(enrichedRows || []);
      setOpenCD(false);
    }
  };

  return (
    <Box sx={{ width: '800px' }}>
      <LoadingSpinnerComponent visible={isLoading} />
      <Grid container="true" spacing={2}>
        <Grid sm={12} md={12} lg={12} xl={12} sx={{ paddingTop: '8px', width: '100%' }}>
          <DnDFileUpload upload={upload} multiple={false} add_description={false} setUpload={setUpload} allowedExtensions={['PDF', 'JPG', 'JPEG', 'PNG', 'TIF', 'TIFF']} disabled={false} />
        </Grid>
        <Grid sm={12} md={12} lg={12} xl={12} sx={{ paddingTop: '8px', width: '100%' }}>
          <Button
            disabled={upload.length === 0}
            variant="contained"
            sx={{ bgcolor: '#C94141', color: 'white', textTransform: 'none', '&:hover': { bgcolor: '#C94141' } }}
            onClick={(e) => processUpload()}>
            automatische Erkennung starten
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default UploadOCRComponent;
