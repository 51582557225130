import axios from "axios";

export const samperformPrint = async (printData) => {
  axios.defaults.baseURL = "/";
  try {
    const res = await axios.post(
      "/api/samperform/print",
      { printData },
      {
        responseType: "blob",
        headers: { "Content-Type": "application/json" },
      }
    );
    const url = window.URL.createObjectURL(new Blob([res.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "Auswertung.pdf");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    console.log(error);
  }
};

export const samperformPrintSwitchShift = async (printData) => {
  axios.defaults.baseURL = "/";
  try {
    const res = await axios.post(
      "/api/samperform/printswitchshift",
      { printData },
      {
        responseType: "blob",
        headers: { "Content-Type": "application/json" },
      }
    );
    const url = window.URL.createObjectURL(new Blob([res.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "Wechselformular.pdf");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    console.log(error);
  }
};
