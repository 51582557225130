import React from 'react';
import { Box, Typography } from '@mui/material';
import NumberFormat from 'react-number-format';

const InputComponent = ({ label, type = 'text', name, value, placeholder, onChange = null, styles, errorMsg, error = false, info, disabled = false, isNumberFormat = false, suffix = ' EUR' }) => {
  const inputStyle = styles ? { fontSize: 14, ...styles } : { fontSize: 14 };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', pb: '8px' }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: errorMsg ? 'space-between' : 'start' }}>
        {!error && <Typography sx={{ pb: '2px' }}>{label}</Typography>}
        {error && <Typography sx={{ pb: '2px', color: 'red' }}>{label}</Typography>}
        {info}
        {errorMsg && <Typography sx={{ pb: '2px', color: 'red' }}>{errorMsg.charAt(0).toUpperCase() + errorMsg.slice(1)}</Typography>}
      </Box>
      {isNumberFormat ? (
        <NumberFormat
          disabled={disabled}
          className="input-control"
          style={inputStyle}
          name={name}
          type={type}
          value={value}
          placeholder={placeholder}
          onValueChange={(values) => {
            onChange &&
              onChange({
                target: {
                  name: name,
                  value: values.floatValue
                }
              });
          }}
          thousandSeparator={'.'}
          decimalSeparator={','}
          fixedDecimalScale={false}
          decimalScale={0}
          allowNegative={false}
          suffix={suffix}
        />
      ) : (
        <input disabled={disabled} className="input-control" style={inputStyle} name={name} type={type} value={value} placeholder={placeholder} onChange={onChange} />
      )}
    </Box>
  );
};

export default InputComponent;
