import React, { useState } from 'react';
import { Box, Grid, IconButton, Typography, Avatar, Button } from '@mui/material';
import SelectComponent from '../../../Components/Controls/SelectComponent';
import InputComponent from '../../../Components/Controls/InputComponent';
import { Inertia } from '@inertiajs/inertia';
import { usePage } from '@inertiajs/inertia-react';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AdminLayout from '../../../Layouts/AdminLayout';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

const Create = ({ licences, roles }) => {
  const { errors } = usePage().props;
  const [userRoles, setUserRoles] = useState([]);
  const roleOptions = roles.map((x) => {
    return { label: x.name, value: x.id };
  });
  const [licence, setLicence] = useState({ label: '', value: '' });
  const licenceOptions = licences.map((x) => {
    return { label: x.name, value: x.id };
  });
  const [userData, setUserData] = useState({
    unternehmen: '',
    vermittlerregisternummer: '',
    anrede: undefined,
    name: '',
    vorname: '',
    strasse: '',
    plz: '',
    ort: '',
    telefon: '',
    email: '',
    password: '',
    password_confirmation: '',
    confirm_insurance: true,
    confirm_contract: true,
    confirm_transmission: true,
    licence_type: undefined
  });
  const { unternehmen, vermittlerregisternummer, anrede, name, password, vorname, strasse, plz, ort, telefon, email, photo, logo, password_confirmation, licence_type } = userData;

  const handleOnSelect = (selectElement) => (item) => setUserData({ ...userData, [selectElement]: item });
  const handleOnSelectLicence = (licence) => setLicence(licence);
  const handleOnSelectRole = (userRoles) => setUserRoles(userRoles);

  const handleOnChange = (e) => setUserData({ ...userData, [e.target.name]: e.target.value });

  const handleUploadPress = (imageInputId) => document.getElementById(imageInputId).click();

  const onChangePhoto = (e, propName) => {
    const fileInput = e.target;
    if (!!fileInput.files && fileInput.files.length > 0) {
      const file = fileInput.files[0];
      const reader = new FileReader();
      reader.onload = function (e) {
        setUserData({ ...userData, [propName]: e.target.result });
      };
      reader.onerror = function (stuff) {
        console.log(stuff.getMessage());
      };
      reader.readAsDataURL(file);
    } else {
      setUserData({ ...userData, [propName]: null });
    }
  };

  const onCreate = () =>
    Inertia.post(route('admin.user.store'), { ...userData, anrede: anrede?.value, licence_type: licence_type?.value, licence_id: licence.value, role_ids: userRoles.map((x) => x.value) });

  const onBack = () => Inertia.get(route('admin.users'));

  return (
    <AdminLayout subHeaderTitle="Benutzer erstellen">
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
        <Button
          variant="contained"
          sx={{
            bgcolor: 'black',
            color: 'white',
            textTransform: 'none',
            minWidth: '150px',
            height: '40px',
            mb: '20px',
            '&:hover': { bgcolor: 'black' }
          }}
          startIcon={<KeyboardArrowLeftIcon />}
          onClick={onBack}>
          Zurück
        </Button>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'start' }}>
          <IconButton onClick={() => handleUploadPress('userPhoto')} sx={{ p: 0 }}>
            <input id="userPhoto" hidden type="file" accept="image/*" multiple={false} onChange={(e) => onChangePhoto(e, 'photo')} />
            {photo ? (
              <Avatar src={photo} sx={{ width: 70, height: 70 }} />
            ) : (
              <Avatar sx={{ width: 70, height: 70 }}>
                <AccountCircleIcon sx={{ width: 70, height: 70 }} />
              </Avatar>
            )}
          </IconButton>
          <IconButton onClick={() => handleUploadPress('logoPhoto')} sx={{ p: 0, ml: '20px' }}>
            <input id="logoPhoto" hidden type="file" accept="image/*" multiple={false} onChange={(e) => onChangePhoto(e, 'logo')} />
            {logo ? (
              <Avatar src={logo} sx={{ width: 70, height: 70 }} />
            ) : (
              <Avatar sx={{ width: 70, height: 70, bgcolor: 'white' }}>
                <Typography sx={{ color: 'black' }}>Logo</Typography>
              </Avatar>
            )}
          </IconButton>
        </Box>
        <hr style={{ width: '100%' }} />
        <Grid container spacing={3}>
          <Grid item xs={7}>
            <InputComponent label="Unternehmen *" name="unternehmen" value={unternehmen} onChange={handleOnChange} errorMsg={errors.unternehmen} />
          </Grid>
          <Grid item xs={5}>
            <InputComponent
              label="IHK Vermittlerregisternummer *"
              name="vermittlerregisternummer"
              placeholder="mind. 15 Zeichen"
              value={vermittlerregisternummer}
              onChange={handleOnChange}
              errorMsg={errors.vermittlerregisternummer}
            />
          </Grid>
          <Grid item xs={2}>
            <SelectComponent
              label="Anrede *"
              name="anrede"
              value={anrede}
              onChange={handleOnSelect('anrede')}
              options={[
                { value: 'Herr', label: 'Herr' },
                { value: 'Frau', label: 'Frau' }
              ]}
              errorMsg={errors.anrede}
            />
          </Grid>
          <Grid item xs={5}>
            <InputComponent label="Name *" name="name" value={name} onChange={handleOnChange} errorMsg={errors.name} />
          </Grid>
          <Grid item xs={5}>
            <InputComponent label="Vorname *" name="vorname" value={vorname} onChange={handleOnChange} errorMsg={errors.vorname} />
          </Grid>
          <Grid item xs={7}>
            <InputComponent label="Strasse und Hausnummer" name="strasse" value={strasse} onChange={handleOnChange} />
          </Grid>
          <Grid item xs={2}>
            <InputComponent label="PLZ" name="plz" value={plz} onChange={handleOnChange} />
          </Grid>
          <Grid item xs={3}>
            <InputComponent label="Ort" name="ort" value={ort} onChange={handleOnChange} />
          </Grid>
          <Grid item xs={3}>
            <InputComponent label="Telefon *" name="telefon" value={telefon} onChange={handleOnChange} errorMsg={errors.telefon} />
          </Grid>
          <Grid item xs={3}>
            <InputComponent label="E-Mail *" name="email" value={email} onChange={handleOnChange} errorMsg={errors.email} />
          </Grid>
          <Grid item xs={3}>
            <InputComponent type="password" label="Passwort *" name="password" value={password} onChange={handleOnChange} errorMsg={errors.password} />
          </Grid>
          <Grid item xs={3}>
            <InputComponent
              type="password"
              label="Passwort Bestätigung *"
              name="password_confirmation"
              value={password_confirmation}
              onChange={handleOnChange}
              errorMsg={errors.password_confirmation}
            />
          </Grid>
          <Grid item xs={3}>
            <SelectComponent label="Lizenz *" name="licence" value={licence} onChange={handleOnSelectLicence} options={licenceOptions} errorMsg={errors.licence_id} />
          </Grid>
          <Grid item xs={3}>
            <SelectComponent
              label="Lizenz-Typ *"
              name="licence_type"
              value={licence_type}
              onChange={handleOnSelect('licence_type')}
              options={[
                { value: 'Basic', label: 'Basic' },
                { value: 'Premium', label: 'Premium' }
              ]}
              errorMsg={errors.licence_type}
            />
          </Grid>
          <Grid item xs={4}>
            <SelectComponent label="Rollen *" name="userRoles" value={userRoles} onChange={handleOnSelectRole} options={roleOptions} isMulti={true} errorMsg={errors.role_ids} />
          </Grid>
        </Grid>
        <br />
        * Pflichtfeld
        <br />
        <Button
          variant="contained"
          sx={{
            bgcolor: '#C94141',
            color: 'white',
            textTransform: 'none',
            minWidth: '150px',
            height: '40px',
            mt: '20px',
            '&:hover': { bgcolor: '#C94141' }
          }}
          onClick={onCreate}>
          Create
        </Button>
      </Box>
    </AdminLayout>
  );
};

export default Create;
