import { ERGEBNIS_ESG_PROFILINGS, ERGEBNIS_RISIKO_PROFILINGS, ERGEBNIS_RISIKO_PROFILINGS_ERGO } from './Constants';

export const calculateKundenProfileSum = (risikoProfiling) => {
  const question2Value = risikoProfiling.question2[0].value * 10;
  const question3Value = risikoProfiling.question3.answer * 10;
  const question4Value = risikoProfiling.question4.answer * 10;
  const question5Value = risikoProfiling.question5[0].value * 20;
  const question6Value = risikoProfiling.question6[0].value * 50;
  return question2Value + question3Value + question4Value + question5Value + question6Value;
};

export const getKundenProfile = (kundenProfilSum, company) => {
  if (company === 'ergo') {
    if (kundenProfilSum >= 330) return ERGEBNIS_RISIKO_PROFILINGS_ERGO.MAX_RENDITE;
    if (kundenProfilSum >= 250) return ERGEBNIS_RISIKO_PROFILINGS_ERGO.WACHSTUM;
    if (kundenProfilSum > 160) return ERGEBNIS_RISIKO_PROFILINGS_ERGO.AUSGEWOGEN;
    return ERGEBNIS_RISIKO_PROFILINGS_ERGO.DEFENSIV;
  } else {
    if (kundenProfilSum >= 330) return ERGEBNIS_RISIKO_PROFILINGS.MAX_RENDITE;
    if (kundenProfilSum >= 250) return ERGEBNIS_RISIKO_PROFILINGS.WACHSTUM;
    if (kundenProfilSum > 160) return ERGEBNIS_RISIKO_PROFILINGS.AUSGEWOGEN;
    return ERGEBNIS_RISIKO_PROFILINGS.DEFENSIV;
  }
};

export const getESGProfile = (esgProfiling) => {
  if (esgProfiling.question2.answer === 'Nein') return ERGEBNIS_ESG_PROFILINGS.NICHT_WICHTIG;
  if (esgProfiling.question3.answer === 'neutral_nachhaltig') return ERGEBNIS_ESG_PROFILINGS.WICHTIG_1;
  if (esgProfiling.question7.answer === 'branche') {
    return esgProfiling.question3.answer === 'nachhaltig' ? ERGEBNIS_ESG_PROFILINGS.WICHTIG_2 : ERGEBNIS_ESG_PROFILINGS.SEHR_WICHTIG_1;
  }

  const esgKeys = Object.keys(esgProfiling).filter((x) => x.startsWith('umwelt') || x.startsWith('soziale'));
  const umweltOrSozialeChecked = !!esgKeys.find((x) => esgProfiling[x].answer !== undefined);

  if (esgProfiling.question3.answer === 'nachhaltig') {
    return umweltOrSozialeChecked ? ERGEBNIS_ESG_PROFILINGS.WICHTIG_3 : ERGEBNIS_ESG_PROFILINGS.WICHTIG_2;
  }

  if (esgProfiling.question3.answer === 'stark_nachhaltig') {
    return umweltOrSozialeChecked ? ERGEBNIS_ESG_PROFILINGS.SEHR_WICHTIG_2 : ERGEBNIS_ESG_PROFILINGS.SEHR_WICHTIG_1;
  }

  return ERGEBNIS_ESG_PROFILINGS.SEHR_WICHTIG_1;
};

export const percentageToInt = (percentageValue) => {
  let newValue = percentageValue;
  if (typeof percentageValue === 'string' || percentageValue instanceof String) newValue = parseInt(newValue.slice(0, -1).replace(',', '.'), 10);
  if (newValue > 1) return Math.round(newValue);
  return Math.round(newValue * 100);
};

export const formatIntPercentage = (percentageValue) => {
  if (typeof percentageValue === 'string' || percentageValue instanceof String) return `${parseInt(percentageValue.slice(0, -1).replace(',', '.'), 10)}%`;
  else return Math.round(percentageValue) + '%';
};

export const getArchiveColor = (status) => {
  switch (status) {
    case '>18 Monate':
      return '#C94141';
    case '13-18 Monate':
      return '#FFA800';
    default:
      return '#20C933';
  }
};

export const canSeeSicherungsvermoegen = (userLicence) => {
  return userLicence?.name === 'Ergo';
};

export const canUseSicherungsvermoegen = (kundenProfilDisplay, versichererTarif, esg) => {
  return (
    (kundenProfilDisplay?.kundenprofil === 'Defensiv' || kundenProfilDisplay?.kundenprofil === 'Ausgewogen') &&
    esg.displayEsg !== 'stark nachhaltig' &&
    (versichererTarif?.label === 'Ergo Rente Balance' || versichererTarif?.label === 'Ergo Basis-Rente Balance')
  );
};

export const removeSVFromFondslist = (fonds) => {
  //LU053423990
  console.log(
    '>0',
    fonds.filter((item) => item.Gewicht > 0)
  );
  /*
  defensiv, ohne SV, Einmalbeitrag < 20 Jahre
  summiere gewichte
  falls summe = 95% dann erhöhe fonds LU0534239909 um 5%
  */

  let summeCheck = 0;
  for (let i = 0; i < fonds.length; i++) {
    if (fonds[i].ISIN !== 'DE00ERGOSV00') summeCheck += Math.round(fonds[i].Gewicht * 100.0);
  }

  if (summeCheck === 10000) {
    const erg = fonds.filter((item) => item.Gewicht > 0);
    console.log('SummeCheck === 10000', erg);
    return erg;
  }

  if (summeCheck === 100) {
    const erg = fonds.filter((item) => item.Gewicht > 0).map((fond) => ({ ...fond, Gewicht: fond.Gewicht * 100 }));
    console.log('SummeCheck === 100', erg);
    return erg;
  }
  const hasSV = fonds.filter((item) => item.ISIN === 'DE00ERGOSV00').length > 0;
  const hasPumpUpFond = fonds.filter((item) => item.ISIN === 'LU0534239909').length > 0;
  if (!hasSV && hasPumpUpFond) {
    const erg = fonds.filter((item) => item.Gewicht > 0).map((fond) => ({ ...fond, Gewicht: Math.round((fond.ISIN === 'LU0534239909' ? fond.Gewicht + 0.05 : fond.Gewicht) * 100) }));
    console.log('hasPumpUpFond', erg);
    return erg;
  }

  let fondsOhneSV = Object.values(
    Object.assign(
      {},
      fonds?.filter((item) => item.ISIN !== 'DE00ERGOSV00' && item.Gewicht > 0)
    )
  );
  if (fondsOhneSV) {
    let summe = 0;
    for (let i = 0; i < fondsOhneSV.length; i++) {
      summe += Math.round(fondsOhneSV[i].Gewicht * 100.0);
    }
    fondsOhneSV = fondsOhneSV.map((fond) => ({ ...fond, Gewicht: Math.round((Math.round(fond.Gewicht * 100.0) / summe) * 100.0) }));
  }
  console.log('fondsOhneSV', fondsOhneSV);
  return fondsOhneSV;
};
