import React, { useEffect } from 'react';
import { Inertia } from '@inertiajs/inertia';
import MainLayout from '../../Layouts/MainLayout';
import { Box } from '@mui/material';
import SliderQuestionComponent from '../../Components/Shared/SliderQuestionComponent';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  userLicenceState,
  kundenProfilDisplayState,
  risikoProfilingState,
  tarifListState,
  vertragsDetailsState,
  risikoProfileTooltipState,
  whiteLabelState,
  isFastLaneState,
  existingPolicyState
} from '../../Recoil/Atoms/PortfolioAtoms';
import YesNoQuestionComponent from '../../Components/Shared/YesNoQuestionComponent';
import { BREADCRUMBS_PORTFOLIO, INFO_TEXTS, RISIKO_PROFILING_QUESTIONS, RISIKO_PROFILING_QUESTIONS_ERGO } from '../../Utils/Constants';
import { calculateKundenProfileSum, getKundenProfile } from '../../Utils/PortfolioHelpers';

const RisikoProfiling = ({ visited }) => {
  const whiteLabel = useRecoilValue(whiteLabelState);
  const tarifList = useRecoilValue(tarifListState);
  const userLicence = useRecoilValue(userLicenceState);
  const tooltipText = useRecoilValue(risikoProfileTooltipState);
  const [risikoProfiling, setRisikoProfiling] = useRecoilState(risikoProfilingState);
  const [vertragsDetails, setVertragsDetails] = useRecoilState(vertragsDetailsState);
  const [kundenProfilDisplay, setKundenProfilDisplay] = useRecoilState(kundenProfilDisplayState);
  const [existingPolicy, setExistingPolicy] = useRecoilState(existingPolicyState);
  const [isFastLane, setIsFastLane] = useRecoilState(isFastLaneState);
  const rightBtnDisabled = risikoProfiling.question3.answer === undefined || risikoProfiling.question4.answer === undefined || risikoProfiling.question6[0].value === 0;
  const risikoQuestions = 'ergo' === whiteLabel.company ? RISIKO_PROFILING_QUESTIONS_ERGO : RISIKO_PROFILING_QUESTIONS;

  useEffect(() => {
    setIsFastLane(false);

    if (!userLicence) {
      Inertia.get('/');
    }
  }, []);
  useEffect(() => {
    console.log('EP', existingPolicy);
  }, [existingPolicy]);

  const onNext = () => {
    setVertragsDetails({
      ...vertragsDetails,
      versichererTarif: vertragsDetails.versichererTarif ? vertragsDetails.versichererTarif : tarifList[0],
      jahren: {
        value: `${risikoProfiling.question1[0].value} Jahre`,
        label: `${risikoProfiling.question1[0].value} Jahre`,
        searchParam: risikoProfiling.question1[0].value
      }
    });
    const kundenProfilSum = calculateKundenProfileSum(risikoProfiling);
    setKundenProfilDisplay(getKundenProfile(kundenProfilSum, whiteLabel.company));
    Inertia.get(route('portfolio.ergebnisRisiko'));
  };

  const onBack = () => Inertia.get(route('portfolio.ergebnisOptimizeExisting'));

  const onSliderChange = (value, stateName) => setRisikoProfiling({ ...risikoProfiling, [stateName]: [value] });

  const onRadioBtnChange = (value, stateName) => setRisikoProfiling({ ...risikoProfiling, [stateName]: { answer: value } });

  return (
    <MainLayout
      breadcrumbs={BREADCRUMBS_PORTFOLIO}
      visitedPages={visited}
      subHeaderTitle="Risikoprofiling"
      infoText={INFO_TEXTS.RISIKO_PROFILING}
      rightBtnName="zum Ergebnis"
      onRightBtnClick={onNext}
      onLeftBtnClick={existingPolicy?.fonds?.length > 0 ? onBack : null}
      rightBtnDisabled={rightBtnDisabled}>
      {risikoQuestions.map((item) => (
        <Box key={`item_${item.id}`} sx={{ pb: '30px', marginLeft: '30px', marginRight: '30px', width: '90%' }}>
          {item.questionType === 'Range' ? (
            <SliderQuestionComponent
              id={item.id}
              direction={item.direction}
              question={item.question}
              values={risikoProfiling[item.stateName]}
              stepperItems={item.steps}
              step={item.step}
              min={item.min}
              max={item.max}
              onChange={(value) => onSliderChange(value, item.stateName)}
              tooltipText={tooltipText}
            />
          ) : (
            <YesNoQuestionComponent
              id={item.id}
              question={item.question}
              isCheckedYes={risikoProfiling[item.stateName].answer === 3}
              isCheckedNo={risikoProfiling[item.stateName].answer === 0}
              onChange={(value) => onRadioBtnChange(value, item.stateName)}
            />
          )}
        </Box>
      ))}
    </MainLayout>
  );
};

export default RisikoProfiling;
