import React, { useEffect } from 'react'
import { Inertia } from '@inertiajs/inertia'
import MainLayout from '../../Layouts/MainLayout'
import { BREADCRUMBS_PORTFOLIO } from '../../Utils/Constants'
import {useRecoilState, useRecoilValue} from 'recoil'
import ErgebnisComponent from '../../Components/Shared/ErgebnisComponent'
import { kundenProfilDisplayState, kundenProfilListState, risikoProfilingState, isFastLaneState } from '../../Recoil/Atoms/PortfolioAtoms'

const ErgebnisRisiko = ({ visited }) => {
    const kundenProfilDisplay = useRecoilValue(kundenProfilDisplayState)
    const risikoProfiling = useRecoilValue(risikoProfilingState)
    const kundenProfilList = useRecoilValue(kundenProfilListState)
    const [isFastLane, setIsFastLane] = useRecoilState(isFastLaneState);

    useEffect(() => {
        if (!isFastLane && (risikoProfiling.question3.answer === undefined || risikoProfiling.question4.answer === undefined)) {
            Inertia.get('/')
        }
    }, [])

    const onNext = () => Inertia.get(route('portfolio.esgProfiling'))

    const onBack = () => Inertia.get(route('portfolio.risikoProfiling'))

    return (
        <MainLayout
            breadcrumbs={BREADCRUMBS_PORTFOLIO}
            visitedPages={visited}
            subHeaderTitle="Ergebnis des Risikoprofilings"
            onRightBtnClick={onNext}
            onLeftBtnClick={onBack}
        >
            <ErgebnisComponent ergebnis={kundenProfilDisplay} kundenProfilList={kundenProfilList} description="(MDD = Maximum Drawdown)"/>
        </MainLayout>
    )
}

export default ErgebnisRisiko