import React, { useState } from "react";
import { Inertia } from "@inertiajs/inertia";
import { Box, Button, Link } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { DataGrid, deDE } from "@mui/x-data-grid";
import AdminLayout from "../../../Layouts/AdminLayout";
import AddIcon from "@mui/icons-material/Add";
import SelectComponent from "../../../Components/Controls/SelectComponent";
import ConfirmDialog from "../../../Components/Shared/ConfirmDialog";
import { usePage } from "@inertiajs/inertia-react";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";

const Index = ({ fonds }) => {
	const { errors } = usePage().props;
	const [tarif, setTarif] = useState(null);
	const [deleteDialogOpened, setDeleteDialogOpened] = useState(false);
	const allOptions = fonds.map((x) => {
		return { label: x.tarif, value: x.id };
	});
	const uniqueTarifs = [];
	const tarifOptions = allOptions.filter((item) => {
		if (!uniqueTarifs.includes(item.label)) {
			uniqueTarifs.push(item.label);
			return true;
		}
		return false;
	});

	const showDeleteDialog = () => {
		setDeleteDialogOpened(true);
	};

	const handleOnSelect = (tarif) => setTarif(tarif);

	const addFond = () => Inertia.get(route("admin.fond.create"));

	const deleteTarif = () => {
		Inertia.post(route("admin.fonds.delete"), { tarif: tarif?.label });
		setTarif(null);
	};

	const theme = createTheme(
		deDE // x-data-grid translations
	);

	return (
		<AdminLayout subHeaderTitle="Fonds">
			<Button
				variant="contained"
				sx={{
					bgcolor: "#C94141",
					color: "white",
					textTransform: "none",
					minWidth: "120px",
					height: "40px",
					mb: "20px",
					"&:hover": { bgcolor: "#C94141" },
				}}
				onClick={addFond}
				endIcon={<AddIcon />}
			>
				Fonds
			</Button>
			<Button
				component={Link}
				href={route("admin.fonds.export")}
				variant="contained"
				sx={{
					bgcolor: "#C94141",
					color: "white",
					textTransform: "none",
					height: "40px",
					ml: "20px",
					mb: "20px",
					"&:hover": { color: "white", bgcolor: "#C94141" },
				}}
			>
				Fonds exportieren
				<DownloadIcon sx={{ ml: "10px" }} />
			</Button>
			<Box sx={{ display: "flex", flexDirection: "row", alignItems: "end" }}>
				<SelectComponent label="Tarif" name="tarif" value={tarif} onChange={handleOnSelect} options={tarifOptions} width="300px" errorMsg={errors.tarif} />
				<Button
					variant="contained"
					onClick={() => showDeleteDialog()}
					sx={{
						bgcolor: "#C94141",
						color: "white",
						textTransform: "none",
						height: "38px",
						ml: "20px",
						mb: "8px",
						"&:hover": { bgcolor: "#C94141" },
					}}
				>
					Tarif und zugehörige Fonds löschen
					<DeleteIcon sx={{ ml: "10px" }} />
				</Button>
			</Box>
			{fonds.length > 0 && (
				<Box sx={{ height: 650 }}>
					<ThemeProvider theme={theme}>
						<DataGrid
							rows={fonds
								.sort((a, b) => {
									return b.id - a.id;
								})
								.map((item) => {
									return {
										id: item.id,
										name: item.name,
										tarif: item.tarif,
										insuranceCompany: item.insurance_company_name,
									};
								})}
							columns={[
								{ field: "id", headerName: "ID", resizable: false, flex: 1, maxWidth: 80 },
								{ field: "name", headerName: "Name", resizable: false, flex: 1 },
								{ field: "tarif", headerName: "Tarif", resizable: false, flex: 1 },
								{ field: "insuranceCompany", headerName: "Versicherung", resizable: false, flex: 1 },
							]}
							disableColumnMenu={true}
							disableSelectionOnClick={true}
							rowsPerPageOptions={[10]}
							pageSize={10}
						/>
					</ThemeProvider>
				</Box>
			)}
			<ConfirmDialog title="Löschen" open={deleteDialogOpened} setOpen={setDeleteDialogOpened} onConfirm={deleteTarif}>
				Soll dieser Tarif mit allen zugehörigen Fonds wirklich gelöscht werden?
			</ConfirmDialog>
		</AdminLayout>
	);
};

export default Index;
