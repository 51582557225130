import React from "react";
import { Box, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

const ErgebnisComponent = ({ ergebnis, kundenProfilList, description }) => {
  const profilList = kundenProfilList?.find((x) => x.key === ergebnis.kundenprofil);
  if (!ergebnis) {
    return null;
  }

  return (
    <Grid container="true" spacing={2} columns={2} justifyContent="center">
      <Grid
        xs={2}
        sm={2}
        md={2}
        lg={1}
        xl={1}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", bgcolor: "#EBEBEB", px: "30px", py: "20px", mr: "70px", width: "500px", borderRadius: "20px" }}>
          <img src={`/images/${ergebnis.image}.png`} alt="" width="198px" style={{ alignSelf: "center", margin: "80px 0" }} />
          <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            {ergebnis.treesImage && <img src={`/images/${ergebnis.treesImage}.png`} alt="" width="200px" style={{ marginRight: 16 }} />}
            <Typography variant="h4" sx={{ fontWeight: "bold" }}>
              {ergebnis.imageTitle}
            </Typography>
          </Box>
          <Typography variant="h6">{ergebnis.imageSubtitle}</Typography>
          <Typography variant="h7">{description}</Typography>
        </Box>
      </Grid>
      <Grid
        xs={2}
        sm={2}
        md={2}
        lg={1}
        xl={1}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", width: "100%", px: "50px" }}>
          {ergebnis.pageTitle && (
            <Typography variant="h4" sx={{ color: ergebnis.titleColor, mb: "30px", fontWeight: "bold" }}>
              {ergebnis.pageTitle}
            </Typography>
          )}
          {ergebnis.pageDescription && <Typography variant="h5">{ergebnis.pageDescription}</Typography>}
          {profilList && profilList.items.length > 0 && (
            <>
              <Typography sx={{ mt: "5px" }}>Für dieses Kundenprofil besonders geeignet:</Typography>
              <ul>
                {profilList.items.map((itemLabel, index) => (
                  <li key={`item_${index}`}>
                    <Typography>{itemLabel}</Typography>
                  </li>
                ))}
              </ul>
            </>
          )}
          {ergebnis.additionalInfo1 && ergebnis.additionalInfo2 && ergebnis.additionalInfoItems && ergebnis.additionalInfoItems.length > 0 && (
            <Box sx={{ pt: "20px" }}>
              <Typography>{ergebnis.additionalInfo1}</Typography>
              <Typography>{ergebnis.additionalInfo2}</Typography>
              {ergebnis.additionalInfoItems.map((additionalInfoItem, index) => (
                <Typography key={`additionalInfoItem_${index}`}>{additionalInfoItem}</Typography>
              ))}
            </Box>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default ErgebnisComponent;
