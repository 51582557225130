import { Inertia } from '@inertiajs/inertia';
import { Box, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import ChartDashboard from '../../Components/ChartDashboard';
import { useWindowSize } from '../../Hooks/CustomHooks';
import MainLayout from '../../Layouts/MainLayout';
import {
  chartsBase64State,
  isFastLaneState,
  kundenProfilDisplayState,
  portfolioResultState,
  risikoProfilingState,
  umsetzungState,
  userLicenceState,
  vertragsDetailsState,
  whiteLabelState
} from '../../Recoil/Atoms/PortfolioAtoms';
import { ESGState, kundenprofilState } from '../../Recoil/Selectors/PortfolioSelectors';
import { calculate, trackEvent } from '../../Services/PortfolioService';
import { BREADCRUMBS_PORTFOLIO, ERGEBNIS_RISIKO_PROFILINGS, ERGEBNIS_RISIKO_PROFILINGS_ERGO, INFO_TEXTS } from '../../Utils/Constants';
import { canSeeSicherungsvermoegen, canUseSicherungsvermoegen } from '../../Utils/PortfolioHelpers';

const Portfoliovorschlag = ({ visited }) => {
  const { width } = useWindowSize();
  const userLicence = useRecoilValue(userLicenceState);
  const whiteLabel = useRecoilValue(whiteLabelState);
  const [kundenProfilDisplay, setKundenProfilDisplay] = useRecoilState(kundenProfilDisplayState);
  const esg = useRecoilValue(ESGState);
  const risikoProfiling = useRecoilValue(risikoProfilingState);
  const umsetzung = useRecoilValue(umsetzungState);
  const calculatedKundenProfil = useRecoilValue(kundenprofilState);
  const [portfolioResult, setPortfolioResult] = useRecoilState(portfolioResultState);
  const [isFastLane, setIsFastLane] = useRecoilState(isFastLaneState);
  const vertragsDetails = useRecoilValue(vertragsDetailsState);
  const { versichererTarif, beitragshohe, jahren, zahlweise, nutze_sicherungsvermoegen } = vertragsDetails;

  const [chartsBase64, setChartsBase64] = useRecoilState(chartsBase64State);
  const risikoProfilings = 'ergo' === whiteLabel.company ? ERGEBNIS_RISIKO_PROFILINGS_ERGO : ERGEBNIS_RISIKO_PROFILINGS;

  useEffect(() => {
    if (
      !isFastLane &&
      (risikoProfiling.question3.answer === undefined || risikoProfiling.question4.answer === undefined || umsetzung === undefined || !versichererTarif || !beitragshohe || !jahren || !zahlweise)
    ) {
      Inertia.get('/');
    } else {
      trackEvent('Portfolio calculated', userLicence.id);
    }
  }, []);

  useEffect(() => {
    const reCalculate = async () => {
      let resultPortfolio;

      const position = versichererTarif?.value.indexOf(' ');
      const insuranceCompanyId = versichererTarif?.value.substring(0, position);
      const tarif = versichererTarif?.value.substring(position + 1);

      let jahre = jahren?.searchParam;
      if (jahre < 12) {
        jahre = 12;
      }
      if (insuranceCompanyId && tarif && jahre && zahlweise && kundenProfilDisplay && umsetzung && esg) {
        resultPortfolio = await calculate(
          insuranceCompanyId,
          tarif,
          jahre,
          zahlweise.value,
          kundenProfilDisplay.kundenprofil,
          umsetzung,
          esg.esg,
          canSeeSicherungsvermoegen(userLicence) && canUseSicherungsvermoegen(kundenProfilDisplay, versichererTarif, esg) ? nutze_sicherungsvermoegen : false
        );
      }
      setPortfolioResult(resultPortfolio);
    };

    reCalculate();
  }, [kundenProfilDisplay]);

  const setKundenProfile = (kundenProfilKey) => {
    setKundenProfilDisplay(getKundenProfileFromKey(kundenProfilKey));
  };

  const getKundenProfileFromKey = (key) => {
    switch (key) {
      case risikoProfilings.DEFENSIV.kundenprofil:
        return risikoProfilings.DEFENSIV;
      case risikoProfilings.AUSGEWOGEN.kundenprofil:
        return risikoProfilings.AUSGEWOGEN;
      case risikoProfilings.WACHSTUM.kundenprofil:
        return risikoProfilings.WACHSTUM;
      case risikoProfilings.MAX_RENDITE.kundenprofil:
        return risikoProfilings.MAX_RENDITE;
      default:
        return risikoProfilings.DEFENSIV;
    }
  };

  const onNext = () => {
    let chart1Base64;
    let chart2Base64;
    let circularChartBase64;
    const chart1Canvas = document.getElementById('chart1');
    if (chart1Canvas) {
      chart1Base64 = chart1Canvas.toDataURL('image/png');
    }
    const chart2Canvas = document.getElementById('chart2');
    if (chart1Canvas) {
      chart2Base64 = chart2Canvas.toDataURL('image/png');
    }

    const circularChartCanvasElement = document.querySelector('.CircularProgressbar');
    const svgData = new XMLSerializer().serializeToString(circularChartCanvasElement);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const img = document.createElement('img');
    img.setAttribute('src', 'data:image/svg+xml;base64,' + btoa(svgData));
    img.onload = function () {
      ctx.drawImage(img, 30, 40);
      ctx.font = '600 18px Helvetica';
      ctx.fillText(`${Math.floor(portfolioResult?.Renditeorientiert * 100)}% Renditeorientiert`, 0, 20);
      ctx.fillText(`${Math.round(portfolioResult?.Sicherheitsorientiert * 100)}%`, 93, 90);
      ctx.font = '12px Helvetica';
      ctx.fillText('Sicherheitsorientiert', 52, 110);
      circularChartBase64 = canvas.toDataURL('image/png');
      setChartsBase64({ chart1: chart1Base64, chart2: chart2Base64, circularChart: circularChartBase64 });
    };
    Inertia.get(route('portfolio.zusammenfassung'));
  };

  const onBack = () => Inertia.get(route('portfolio.vertragsdetails'));

  const isProfileSelectable = (profile) => {
    if (canSeeSicherungsvermoegen(userLicence) && canUseSicherungsvermoegen(kundenProfilDisplay, versichererTarif, esg) && nutze_sicherungsvermoegen) {
      return profile.kundenprofil === 'Ausgewogen' || profile.kundenprofil === 'Defensiv';
    }
    return true;
  };

  const getClickableCardSX = (item) => {
    let sx = {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      width: '150px',
      bgcolor: item.kundenprofil === kundenProfilDisplay?.kundenprofil ? '#FFFFFF' : '#EBEBEB',
      p: '20px',
      borderRadius: '20px',
      mx: '15px',
      filter: 'brightness(75%)',
      border: item.kundenprofil === kundenProfilDisplay?.kundenprofil ? '2px solid #C94141' : '2px solid transparent',
      cursor: 'default'
    };
    if (isProfileSelectable(item)) sx = { ...sx, filter: 'brightness(100%)', cursor: 'pointer', '&:hover': { borderColor: '#C94141', cursor: 'pointer' } };

    return sx;
  };

  return (
    <MainLayout
      breadcrumbs={BREADCRUMBS_PORTFOLIO}
      visitedPages={visited}
      subHeaderTitle="Portfoliovorschlag"
      infoText={INFO_TEXTS.PORTFOLIOVORSCHLAG}
      onRightBtnClick={onNext}
      onLeftBtnClick={onBack}
      rightBtnDisabled={!portfolioResult}>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        {portfolioResult ? (
          <ChartDashboard portfolioResult={portfolioResult} showKundenprofil={false} />
        ) : (
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mx: 'auto', height: 300, width: '50%' }}>
            <Typography align="center">
              Leider kann mit den von Ihnen gewählten Präferenzen kein Portfoliovorschlag für diesen Tarif erstellt werden. Bitte ändern Sie die Angaben, wie z.B. das Kundenprofil.
            </Typography>
          </Box>
        )}
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Typography sx={{ mb: '10px', fontWeight: 'bold' }}>Kundenprofil ändern:</Typography>
          {calculatedKundenProfil?.kundenprofil !== kundenProfilDisplay?.kundenprofil && (
            <Typography sx={{ mb: '10px' }}>Hinweis: Das Kunden/Risikoprofil wurde manuell geändert und entspricht nicht dem ermittelten Ergebnis des Risikoprofilings!</Typography>
          )}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center'
            }}>
            {Object.values(risikoProfilings).map((item) => (
              <Box
                key={item.kundenprofil}
                onClick={() => {
                  if (isProfileSelectable(item)) setKundenProfile(item.kundenprofil);
                }}
                sx={getClickableCardSX(item)}>
                <img src={`/images/${item.image}.png`} alt="" width="80px" />
                <Typography sx={{ mt: '5px', fontWeight: 'bold' }}>{item.imageTitle}</Typography>
                <Typography>{item.imageSubtitle}</Typography>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </MainLayout>
  );
};

export default Portfoliovorschlag;
