import React, { useState } from 'react';
import { Inertia } from '@inertiajs/inertia';
import { Box, Button, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { DataGrid, deDE } from '@mui/x-data-grid';
import AdminLayout from '../../../Layouts/AdminLayout';
import ConfirmDialog from '../../../Components/Shared/ConfirmDialog';
import AddIcon from '@mui/icons-material/Add';

const Index = ({ licences }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [idToDelete, setIdToDelete] = useState(undefined);
  const [deleteDialogOpened, setDeleteDialogOpened] = useState(false);

  const showDeleteDialog = (itemId) => {
    setDeleteDialogOpened(true);
    setIdToDelete(itemId);
  };

  const deleteLicence = async () => {
    Inertia.delete(route('admin.licence.destroy', idToDelete), {
      onStart: () => setIsLoading(true),
      onSuccess: () => setIdToDelete(undefined),
      onFinish: () => setIsLoading(false)
    });
  };

  const theme = createTheme(
    deDE // x-data-grid translations
  );
  const addLicence = () => Inertia.get(route('admin.licence.create'));

  const editLicence = (id) => Inertia.get(route('admin.licence.edit', id));

  return (
    <AdminLayout subHeaderTitle="Lizenzen" isLoading={isLoading}>
      <Button
        variant="contained"
        sx={{
          bgcolor: '#C94141',
          color: 'white',
          textTransform: 'none',
          minWidth: '120px',
          height: '40px',
          mb: '20px',
          '&:hover': { bgcolor: '#C94141' }
        }}
        onClick={addLicence}
        endIcon={<AddIcon />}>
        Lizenz
      </Button>
      {licences.length > 0 && (
        <Box sx={{ height: 650 }}>
          <ThemeProvider theme={theme}>
            <DataGrid
              rows={licences
                .sort((a, b) => {
                  return b.id - a.id;
                })
                .map((item) => {
                  return {
                    id: item.id,
                    name: item.name,
                    can_premium: item.can_premium ? 'ja' : 'nein',
                    insurance_companies: item.insurance_companies.map((x) => x.name).join(', ')
                  };
                })}
              columns={[
                { field: 'id', headerName: 'ID', resizable: false, flex: 1, maxWidth: 80 },
                { field: 'name', headerName: 'Name', resizable: false, flex: 1 },
                { field: 'can_premium', headerName: 'Premium verfügbar', resizable: false, flex: 1 },
                { field: 'insurance_companies', headerName: 'Versicherungen', resizable: false, flex: 1 },
                {
                  field: 'aktion',
                  headerName: 'Aktion',
                  resizable: false,
                  flex: 1,
                  sortable: false,
                  minWidth: 130,
                  renderCell: (cellObject) => (
                    <Box>
                      <IconButton onClick={() => editLicence(cellObject.row.id)}>
                        <EditIcon />
                      </IconButton>
                      <IconButton onClick={() => showDeleteDialog(cellObject.row.id)}>
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  )
                }
              ]}
              disableColumnMenu={true}
              disableSelectionOnClick={true}
              rowsPerPageOptions={[10]}
              pageSize={10}
            />
          </ThemeProvider>
        </Box>
      )}
      <ConfirmDialog title="Löschen" open={deleteDialogOpened} setOpen={setDeleteDialogOpened} onConfirm={deleteLicence}>
        Soll diese Lizenz wirklich gelöscht werden?
      </ConfirmDialog>
    </AdminLayout>
  );
};

export default Index;
