import React, { useEffect } from "react";
import { Inertia } from "@inertiajs/inertia";
import MainLayout from "../../Layouts/MainLayout";
import { Box, FormControlLabel, Radio, Typography } from "@mui/material";
import { BREADCRUMBS_PORTFOLIO, INFO_TEXTS } from "../../Utils/Constants";
import { useRecoilState, useRecoilValue } from "recoil";
import { newEsgProfilingState, risikoProfilingState } from "../../Recoil/Atoms/PortfolioAtoms";

const ESGProfilingScreenThree = ({ visited }) => {
  const [newEsgProfiling, setNewEsgProfiling] = useRecoilState(newEsgProfilingState);
  const risikoProfiling = useRecoilValue(risikoProfilingState);
  const rightBtnDisabled =
    (newEsgProfiling.question5.answer === undefined && newEsgProfiling.question6.answer !== undefined) ||
    (newEsgProfiling.question5.answer !== undefined && newEsgProfiling.question6.answer === undefined);

  useEffect(() => {
    if (risikoProfiling.question3.answer === undefined || risikoProfiling.question4.answer === undefined) {
      Inertia.get("/");
    }
  }, []);

  const onRadioBtnChange = (event, stateName) => setNewEsgProfiling({ ...newEsgProfiling, [stateName]: { answer: event.target.value }, question7: { answer: undefined } });

  const onBrancheChange = (event, stateName) =>
    setNewEsgProfiling({ ...newEsgProfiling, [stateName]: { answer: event.target.value }, question5: { answer: undefined }, question6: { answer: undefined } });

  const onNext = () => Inertia.get(route(newEsgProfiling.question7.answer === "branche" ? "portfolio.ergebnisESG" : "portfolio.esgProfilingScreenFour"));

  const onBack = () => Inertia.get(route("portfolio.esgProfilingScreenTwo"));

  return (
    <MainLayout
      breadcrumbs={BREADCRUMBS_PORTFOLIO}
      visitedPages={visited}
      subHeaderTitle="ESG-Profiling"
      infoText={INFO_TEXTS.ESG_PROFILING}
      rightBtnDisabled={rightBtnDisabled}
      onRightBtnClick={onNext}
      onLeftBtnClick={onBack}
    >
      <Box sx={{ pb: "30px", marginLeft: "30px", marginRight: "30px", width: "90%" }}>
        <Typography variant="h4" sx={{ color: "#a9a9a9", pb: "10px" }}>
          III. Präferenzen - Schwerpunkte
        </Typography>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography sx={{ fontWeight: "bold", pb: "20px" }}>5. Nachhaltigkeit ist vielschichtig und komplex. Haben Sie Präferenzen?</Typography>
          <Typography>Ich habe bestimmte Präferenzen in den Bereichen Umwelt (E, Environment) und Soziales (S, Social)</Typography>
          <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", py: "10px" }}>
            <Typography sx={{ width: 120 }}>Umwelt (E)</Typography>
            <FormControlLabel
              label="unwichtig"
              labelPlacement="bottom"
              control={
                <Radio
                  sx={{ color: "#69B977", "&.Mui-checked": { color: "#69B977" } }}
                  checked={newEsgProfiling["question5"].answer === "unwichtig"}
                  value="unwichtig"
                  onChange={(e) => onRadioBtnChange(e, "question5")}
                />
              }
            />
            <FormControlLabel
              label="wichtig"
              labelPlacement="bottom"
              control={
                <Radio
                  sx={{ color: "#69B977", "&.Mui-checked": { color: "#69B977" } }}
                  checked={newEsgProfiling["question5"].answer === "wichtig"}
                  value="wichtig"
                  onChange={(e) => onRadioBtnChange(e, "question5")}
                />
              }
            />
            <FormControlLabel
              label="sehr wichtig"
              labelPlacement="bottom"
              control={
                <Radio
                  sx={{ color: "#69B977", "&.Mui-checked": { color: "#69B977" } }}
                  checked={newEsgProfiling["question5"].answer === "sehr wichtig"}
                  value="sehr wichtig"
                  onChange={(e) => onRadioBtnChange(e, "question5")}
                />
              }
            />
          </Box>
          <Typography sx={{ color: "#a9a9a9", pb: "10px" }}>Bei Umwelt (E) handelt es sich z.B. um Klimawandel, ressourcenschonenden Umgang mit Wasser und Recycling</Typography>
          <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", py: "10px" }}>
            <Typography sx={{ width: 120 }}>Soziales (S)</Typography>
            <FormControlLabel
              label="unwichtig"
              labelPlacement="bottom"
              control={
                <Radio
                  sx={{ color: "#69B977", "&.Mui-checked": { color: "#69B977" } }}
                  checked={newEsgProfiling["question6"].answer === "unwichtig"}
                  value="unwichtig"
                  onChange={(e) => onRadioBtnChange(e, "question6")}
                />
              }
            />
            <FormControlLabel
              label="wichtig"
              labelPlacement="bottom"
              control={
                <Radio
                  sx={{ color: "#69B977", "&.Mui-checked": { color: "#69B977" } }}
                  checked={newEsgProfiling["question6"].answer === "wichtig"}
                  value="wichtig"
                  onChange={(e) => onRadioBtnChange(e, "question6")}
                />
              }
            />
            <FormControlLabel
              label="sehr wichtig"
              labelPlacement="bottom"
              control={
                <Radio
                  sx={{ color: "#69B977", "&.Mui-checked": { color: "#69B977" } }}
                  checked={newEsgProfiling["question6"].answer === "sehr wichtig"}
                  value="sehr wichtig"
                  onChange={(e) => onRadioBtnChange(e, "question6")}
                />
              }
            />
          </Box>
          <Typography sx={{ color: "#a9a9a9", pb: "20px" }}>Bei Soziales (S) handelt es sich z.B. um faire Behandlung von Mitarbeitern und Kunden</Typography>
          <FormControlLabel
            label={
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography>Meine Präferenzen sind abhängig von der jeweiligen Branche.</Typography>
                <Typography>
                  So ist mir bekannt, dass z.B. bei Energieerzeugern der Aspekt CO2 eine wichtige Rolle spielt oder bei Unternehmen der Chemiebranche z.B. Giftmüll. Bei Dienstleistungsunternehmen wie
                  z.B. Versicherungsunternehmen stehen dagegen faire Kundenbehandlung oder bei Internetunternehmen wie z.B. Facebook Datenschutz und CO2 im Vordergrund.
                </Typography>
              </Box>
            }
            control={
              <Radio
                sx={{ color: "#69B977", "&.Mui-checked": { color: "#69B977" } }}
                checked={newEsgProfiling["question7"].answer === "branche"}
                value="branche"
                onChange={(e) => onBrancheChange(e, "question7")}
              />
            }
          />
        </Box>
      </Box>
    </MainLayout>
  );
};

export default ESGProfilingScreenThree;
