import MainLayout from '../../Layouts/MainLayout'
import { wpState } from '../../Recoil/Atoms/PortfolioAtoms'
import { useRecoilValue } from 'recoil'
import React, { useEffect, useState } from 'react'
import SelectComponent from '../../Components/Controls/SelectComponent'
import { Typography, Box, Avatar } from '@mui/material'

const FondsInfo = ({ }) => {
  const wp = useRecoilValue(wpState)
  const [fondsgesellschaften, setFondsgesellschaften] = useState({});
  const [fondsgesellschaftName, setFondsgesellschaftName] = useState('');
  const [activeFondsgesellschaft, setActiveFondsgesellschaft] = useState('');
  const [options, setOptions] = useState({});

  useEffect(() => {
    if (wp) {
      const menu = wp.menus.find(menu => menu.navLabel == 'Info-Center');
      const fg = menu.items.find(menu => menu.type == 'special_fonds_informationen');
      setFondsgesellschaften(fg);
      let fgOptions = []
      fg.content.map(item => fgOptions.push({ value: item.name, label: item.name }));
      setOptions(fgOptions);
      setFondsgesellschaftName(fgOptions[0])
      setActiveFondsgesellschaft(fg.content.find(item => item.name == fgOptions[0].value))
    }
  }, [wp])

  const handleOnSelect = selectItem => {
    setFondsgesellschaftName(selectItem);
    setActiveFondsgesellschaft(fondsgesellschaften.content.find(item => item.name == selectItem.value));
  }

  return (
    <MainLayout subHeaderTitle="Fondsinformationen">
      <SelectComponent
        label="Investmentgesellschaft"
        name="fondsgesellschaftName"
        value={fondsgesellschaftName}
        onChange={handleOnSelect}
        width="250px"
        options={options}
      />
      <Typography variant="h5" sx={{ pt: '8px' }}>{activeFondsgesellschaft?.name}</Typography>
      <img src={activeFondsgesellschaft?.logo} style={{ paddingTop: '10px', paddingBottom: '10px' }} />
      <hr />
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', py: '8px' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', flex: 3 }}>
          <div dangerouslySetInnerHTML={{ __html: activeFondsgesellschaft?.seiteninhalt }} />
          {
            activeFondsgesellschaft?.fonds?.map(fonds => (
              <div key={fonds.name}>
                <h5>{fonds.name} - ISIN: {fonds.isin}</h5>
                <div dangerouslySetInnerHTML={{ __html: fonds.seiteninhalt }} />
                {
                  fonds.factsheet &&
                  <a href={fonds.factsheet} target="_blank">zum Factsheet</a>
                }
                <hr />
              </div>
            ))
          }
        </Box>
        {
          activeFondsgesellschaft?.ansprechpartner && activeFondsgesellschaft.ansprechpartner.length > 0 &&
          <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1, ml: '40px' }}>
            <Typography>Ihr Ansprechpartner</Typography>
            {
              activeFondsgesellschaft.ansprechpartner.map((item, index) => (
                <Box key={`ansprechpartner_${index}`} sx={{ display: 'flex', flexDirection: 'column', bgcolor: 'white', p: '20px', mt: '10px' }}>
                  <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                      <Typography variant="h4" sx={{ fontWeight: 'bold' }}>{item.name}</Typography>
                      <Typography>{item.rolleposition}</Typography>
                    </Box>
                    {
                      item.foto &&
                      <Avatar src={item.foto} sx={{ width: 70, height: 70 }} />
                    }
                  </Box>
                  <hr />
                  <div dangerouslySetInnerHTML={{ __html: item.details }} />
                </Box>
              ))
            }
          </Box>
        }
      </Box>
    </MainLayout>
  )
}

export default FondsInfo