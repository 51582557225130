import React from "react";
import { Box, Switch, Typography } from "@mui/material";

const SwitchComponent = ({ label, info, name, disabled, checked, onChange, errorMsg }) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "start" }}>
        <Typography>{label}</Typography>
        {info}
      </Box>
      <Switch name={name} disabled={disabled} checked={checked} onChange={onChange} color="error" />
      {errorMsg && <Typography sx={{ pt: "2px", color: "red" }}>{errorMsg.charAt(0).toUpperCase() + errorMsg.slice(1)}</Typography>}
    </Box>
  );
};

export default SwitchComponent;
