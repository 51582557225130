import React, { useState } from 'react';
import { Inertia } from '@inertiajs/inertia';
import { Box, Button, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { DataGrid, deDE } from '@mui/x-data-grid';
import AdminLayout from '../../../Layouts/AdminLayout';
import ConfirmDialog from '../../../Components/Shared/ConfirmDialog';
import AddIcon from '@mui/icons-material/Add';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import LockIcon from '@mui/icons-material/Lock';

const Index = ({ users }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [idToDelete, setIdToDelete] = useState(undefined);
  const [deleteDialogOpened, setDeleteDialogOpened] = useState(false);

  const showDeleteDialog = (itemId) => {
    setDeleteDialogOpened(true);
    setIdToDelete(itemId);
  };

  const deleteUser = async () => {
    Inertia.delete(route('admin.user.destroy', idToDelete), {
      onStart: () => setIsLoading(true),
      onSuccess: () => setIdToDelete(undefined),
      onFinish: () => setIsLoading(false)
    });
  };

  const theme = createTheme(
    deDE // x-data-grid translations
  );

  const addUser = () => Inertia.get(route('admin.user.create'));

  const editUser = (id) => Inertia.get(route('admin.user.edit', id));

  const toggleUser = (id) => Inertia.get(route('admin.user.toggle', id));

  return (
    <AdminLayout subHeaderTitle="Benutzer" isLoading={isLoading}>
      <Button
        variant="contained"
        sx={{
          bgcolor: '#C94141',
          color: 'white',
          textTransform: 'none',
          minWidth: '120px',
          height: '40px',
          mb: '20px',
          '&:hover': { bgcolor: '#C94141' }
        }}
        onClick={addUser}
        endIcon={<AddIcon />}>
        Benutzer
      </Button>
      {users.length > 0 && (
        <Box sx={{ height: 650 }}>
          <ThemeProvider theme={theme}>
            <DataGrid
              rows={users
                .sort((a, b) => {
                  return b.id - a.id;
                })
                .map((item) => {
                  return {
                    id: item.id,
                    email: item.email,
                    name: item.name,
                    vorname: item.vorname,
                    licence: item.licence_name,
                    licence_type: item.licence_type,
                    created_at: item.created_at
                  };
                })}
              columns={[
                { field: 'id', headerName: 'ID', resizable: false, flex: 1, maxWidth: 80 },
                { field: 'email', headerName: 'Email', resizable: false, flex: 1 },
                { field: 'name', headerName: 'Name', resizable: false, flex: 1 },
                { field: 'vorname', headerName: 'Vorname', resizable: false, flex: 1 },
                { field: 'licence', headerName: 'Lizenz', resizable: false, flex: 1 },
                { field: 'licence_type', headerName: 'Typ', resizable: false, flex: 1 },
                { field: 'created_at', headerName: 'Erstellt am', resizable: false, flex: 1 },
                {
                  field: 'aktion',
                  headerName: 'Aktion',
                  resizable: false,
                  flex: 1,
                  sortable: false,
                  minWidth: 130,
                  renderCell: (cellObject) => {
                    const userItem = users.find((x) => x.id === cellObject.row.id);
                    return (
                      <Box>
                        <IconButton onClick={() => editUser(cellObject.row.id)}>
                          <EditIcon />
                        </IconButton>
                        <IconButton onClick={() => showDeleteDialog(cellObject.row.id)}>
                          <DeleteIcon />
                        </IconButton>
                        <IconButton onClick={() => toggleUser(cellObject.row.id)}>{userItem.enabled ? <LockOpenIcon /> : <LockIcon />}</IconButton>
                      </Box>
                    );
                  }
                }
              ]}
              disableColumnMenu={true}
              disableSelectionOnClick={true}
              rowsPerPageOptions={[10]}
              pageSize={10}
            />
          </ThemeProvider>
        </Box>
      )}
      <ConfirmDialog title="Löschen" open={deleteDialogOpened} setOpen={setDeleteDialogOpened} onConfirm={deleteUser}>
        Soll dieser Benutzer wirklich gelöscht werden?
      </ConfirmDialog>
    </AdminLayout>
  );
};

export default Index;
