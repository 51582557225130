import { Button, Box } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import React, { useEffect, useState } from 'react';
import { read, utils } from 'xlsx';
import DnDFileUpload from '../../../Components/Controls/DnDFileUpload';
import SelectComponent from '../../../Components/Controls/SelectComponent';
import { v4 as uuidv4 } from 'uuid';
import { checkISIN } from '../../../Services/OptimizeExistingService';
import { useSnackbar } from 'notistack';

const UploadComponent = (props) => {
  const { setRows, setOpenCD } = props;
  const [importFilter, setImportFilter] = useState({ data: [], columns: [], column_isin: '', column_gewichtung: '' });
  const [upload, setUpload] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (upload.length > 0) processUpload();
  }, [upload]);

  const getExcelColumnOptions = (jsonArray) => {
    var attributeNames = [];

    jsonArray.forEach((object) => {
      for (var key in object) {
        if (!attributeNames.includes(key)) {
          attributeNames.push(key);
        }
      }
    });

    return attributeNames.map((item) => {
      return { label: item, value: item };
    });
  };

  const processUpload = () => {
    const binaryData = atob(upload[0].filedata.split(',')[1]);

    const dataArray = new Uint8Array(binaryData.length);
    for (let i = 0; i < binaryData.length; i++) {
      dataArray[i] = binaryData.charCodeAt(i);
    }

    const workbook = read(dataArray, { type: 'array' });
    const worksheet = workbook.Sheets[workbook.SheetNames[0]];
    const jsonData = utils.sheet_to_json(worksheet);
    setImportFilter({ data: jsonData, columns: getExcelColumnOptions(jsonData), column_isin: '', column_gewichtung: '' });
  };

  const handleSelectImportFilter = (e, target) => {
    setImportFilter({ ...importFilter, [target]: e.value });
  };

  const fondsListeUebernehmen = async () => {
    const importedRows = (
      await Promise.all(
        importFilter.data.map(async (row) => {
          const isin = row[importFilter.column_isin];
          const gewichtung = getAnteil(row[importFilter.column_gewichtung]);
          if (isin !== undefined && gewichtung !== undefined) {
            const checkResult = await checkISIN(isin);
            if (checkResult.status === 'OK') {
              const fondsname = checkResult.kurzname;
              return { id: uuidv4(), isin: isin, fondsname: fondsname, gewichtung: gewichtung };
            } else {
              enqueueSnackbar('ungültige ISIN: ' + isin, { variant: 'error' });
              return undefined;
            }
          } else {
            return undefined;
          }
        })
      )
    ).filter((result) => result !== undefined);

    setRows(importedRows || []);
    setOpenCD(false);
  };

  const getAnteil = (value) => {
    const sum = importFilter.data.reduce((sum, row) => sum + Number(row[importFilter.column_gewichtung]), 0).toFixed(0);
    if (sum === '1') return (value * 100).toFixed(0);

    return value;
  };

  return (
    <Box sx={{ width: '800px' }}>
      <Grid container="true" spacing={2}>
        <Grid sm={12} md={12} lg={12} xl={12} sx={{ paddingTop: '8px', width: '100%' }}>
          <DnDFileUpload upload={upload} multiple={false} add_description={false} setUpload={setUpload} allowedExtensions={['XLSX', 'XLS']} disabled={false} />
        </Grid>
        <Grid sm={12} md={6} lg={6} xl={6} sx={{ paddingTop: '8px', width: '100%' }}>
          <SelectComponent
            label="ISIN"
            name="column_isin"
            value={{ label: importFilter.column_isin, value: importFilter.column_isin }}
            onChange={(e) => handleSelectImportFilter(e, 'column_isin')}
            options={importFilter.columns}
          />
          {importFilter.column_isin &&
            importFilter.data.map((item, index) => (
              <Box
                key={index}
                sx={{
                  borderBottom: '1px solid #C0C0C0', // Unterstreichung
                  borderColor: '#C0C0C0'
                }}>
                {item[importFilter.column_isin] || '** leere Zelle **'}
              </Box>
            ))}
        </Grid>
        <Grid sm={12} md={6} lg={6} xl={6} sx={{ paddingTop: '8px', width: '100%' }}>
          <SelectComponent
            label="Gewichtung"
            name="column_gewichtung"
            value={{ label: importFilter.column_gewichtung, value: importFilter.column_gewichtung }}
            onChange={(e) => handleSelectImportFilter(e, 'column_gewichtung')}
            options={importFilter.columns}
          />
          {importFilter.column_gewichtung &&
            importFilter.data.map((item, index) => (
              <Box
                key={index}
                sx={{
                  borderBottom: '1px solid #C0C0C0', // Unterstreichung
                  borderColor: '#C0C0C0'
                }}>
                {getAnteil(item[importFilter.column_gewichtung]) || '** leere Zelle **'}
              </Box>
            ))}
        </Grid>
        <Grid sm={12} md={12} lg={12} xl={12} sx={{ paddingTop: '8px', width: '100%' }}>
          <Button
            disabled={!(importFilter.column_gewichtung && importFilter.column_isin)}
            variant="contained"
            sx={{ bgcolor: '#C94141', color: 'white', textTransform: 'none', '&:hover': { bgcolor: '#C94141' } }}
            onClick={(e) => fondsListeUebernehmen()}>
            Fondsliste übernehmen
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default UploadComponent;
