import React, { useEffect } from 'react'
import { Inertia } from '@inertiajs/inertia'
import MainLayout from '../../Layouts/MainLayout'
import { BREADCRUMBS_PORTFOLIO } from '../../Utils/Constants'
import ErgebnisComponent from '../../Components/Shared/ErgebnisComponent'
import { ESGState } from '../../Recoil/Selectors/PortfolioSelectors'
import { useRecoilValue } from 'recoil'
import { newEsgProfilingState, risikoProfilingState } from '../../Recoil/Atoms/PortfolioAtoms'

const ErgebnisESG = ({ visited }) => {
    const newEsgProfiling = useRecoilValue(newEsgProfilingState)
    const esgProfil = useRecoilValue(ESGState)
    const risikoProfiling = useRecoilValue(risikoProfilingState)

    useEffect(() => {
        if (risikoProfiling.question3.answer === undefined || risikoProfiling.question4.answer === undefined) {
            Inertia.get('/')
        }
    }, [])

    const onNext = () => Inertia.get(route('portfolio.etf'))

    const onBack = () => Inertia.get(route(getBackRoute()))

    const getBackRoute = () => {
        if (newEsgProfiling.question2.answer === 'Nein') return 'portfolio.esgProfiling'
        if (newEsgProfiling.question3.answer === 'neutral_nachhaltig') return 'portfolio.esgProfilingScreenTwo'
        if (newEsgProfiling.question7.answer === 'branche') return 'portfolio.esgProfilingScreenThree'
        return 'portfolio.esgProfilingScreenFour'
    }

    return (
        <MainLayout
            breadcrumbs={BREADCRUMBS_PORTFOLIO}
            visitedPages={visited}
            subHeaderTitle="Ergebnis des ESG-Profilings"
            onRightBtnClick={onNext}
            onLeftBtnClick={onBack}
        >
            <ErgebnisComponent ergebnis={esgProfil} />
        </MainLayout>
    )
}

export default ErgebnisESG