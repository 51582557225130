import { usePage } from "@inertiajs/inertia-react";
import React, { useEffect } from "react";
import { useRecoilState } from "recoil";
import BreadcrumbsComponent from "../Components/Shared/BreadcrumbsComponent";
import Footer from "../Components/Shared/Footer";
import Header from "../Components/Shared/Header";
import InfoFooter from "../Components/Shared/InfoFooter";
import LoadingSpinnerComponent from "../Components/Shared/LoadingSpinnerComponent";
import SubHeader from "../Components/Shared/SubHeader";
import { wpState, userLicenceState } from "../Recoil/Atoms/PortfolioAtoms";
import { Container, Box } from "@mui/material";
import { useWindowSize } from "../Hooks/CustomHooks";

const MainLayout = ({
  breadcrumbs,
  visitedPages,
  subHeaderTitle,
  leftBtnName,
  rightBtnName,
  onLeftBtnClick,
  onRightBtnClick,
  infoText,
  rightBtnDisabled,
  createApplicationButtonHidden = true,
  onCreateApplicationBtnClick,
  printButtonHidden = true,
  onPrintBtnClick,
  showInfoFooter,
  isLoading,
  saveButtonHidden = true,
  onSaveBtnClick,
  comparisonButtonHidden = true,
  onComparisonBtnClick,
  children,
}) => {
  const { user } = usePage().props.auth;
  const wordpress = usePage().props.wp;
  const [wp, setWp] = useRecoilState(wpState);
  const [userLicence, setUserLicence] = useRecoilState(userLicenceState);
  const windowSize = useWindowSize();

  const getWidth = () => {
    let result = Number(1600);
    const windowWidth = Number(windowSize.width);
    if (windowWidth < 1500) result = 1330;

    if (windowWidth < 1000) result = 800;
    if (windowWidth < 800) result = windowWidth;
    if (windowWidth < result) result = windowWidth;
    result = result - 220;
    /* console.log("windowSize", windowSize);
    console.log("windowWidth", windowWidth);
    console.log("result", result);*/
    return result + "px";
  };

  useEffect(() => {
    if (user) {
      setUserLicence(user.licence);
    }
    if (wordpress) setWp(wordpress);
  }, []);

  return (
    <div style={{ display: "flex", flexDirection: "column", backgroundColor: "#F7F7F7", minHeight: "100vh" }}>
      <LoadingSpinnerComponent visible={isLoading} />
      <Header />
      <Box style={{ backgroundColor: "#F7F7F7", marginBottom: "20px", marginTop: "64px", marginLeft: "auto", marginRight: "auto" }}>
        <Box
          sx={{
            width: getWidth(),
            alignContent: "center",
            paddingTop: "8px",
          }}
        >
          {breadcrumbs && <BreadcrumbsComponent breadcrumbs={breadcrumbs} visitedPages={visitedPages} />}
          {subHeaderTitle && <SubHeader title={subHeaderTitle} infoText={infoText} />}
          {children}
        </Box>
      </Box>
      <Footer
        leftBtnName={leftBtnName}
        rightBtnName={rightBtnName}
        onLeftBtnClick={onLeftBtnClick}
        onRightBtnClick={onRightBtnClick}
        rightBtnDisabled={rightBtnDisabled}
        createApplicationButtonHidden={createApplicationButtonHidden}
        onCreateApplicationBtnClick={onCreateApplicationBtnClick}
        printButtonHidden={printButtonHidden}
        onPrintBtnClick={onPrintBtnClick}
        saveButtonHidden={saveButtonHidden}
        onSaveBtnClick={onSaveBtnClick}
        comparisonButtonHidden={comparisonButtonHidden}
        onComparisonBtnClick={onComparisonBtnClick}
      />
      <InfoFooter showInfoFooter={showInfoFooter} hideFooter={!onRightBtnClick} />
    </div>
  );
};

export default MainLayout;
