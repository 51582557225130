import React from 'react'
import MainLayout from '../../Layouts/MainLayout'
import { wpState } from '../../Recoil/Atoms/PortfolioAtoms'
import { useRecoilValue } from 'recoil'

const AVV = ({ }) => {
  const wp = useRecoilValue(wpState)

  return (
    <MainLayout subHeaderTitle={wp?.footer.avv.content.name}>
      <div dangerouslySetInnerHTML={{ __html: wp?.footer.avv.content.seiteninhalt }} />
    </MainLayout>
  )
}

export default AVV