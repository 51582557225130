import { selector } from "recoil";
import { calculateKundenProfileSum, getESGProfile, getKundenProfile } from "../../Utils/PortfolioHelpers";
import { isFastLaneState, newEsgProfilingFastLaneState, newEsgProfilingState, risikoProfilingState, vertragsDetailsState, whiteLabelState, excursionState } from "../Atoms/PortfolioAtoms";

export const kundenprofilState = selector({
  key: "kundenprofilState",
  get: ({ get }) => {
    const risikoProfiling = get(risikoProfilingState);
    const whiteLabel = get(whiteLabelState);
    const kundenProfilSum = calculateKundenProfileSum(risikoProfiling);
    const kundenProfil = getKundenProfile(kundenProfilSum, whiteLabel.company);
    return kundenProfil;
  }
});

export const ESGState = selector({
  key: "ESGState",
  get: ({ get }) => {
    const esgProfiling = get(newEsgProfilingState);
    const esgProfile = getESGProfile(esgProfiling);
    const isFastLane = get(isFastLaneState);
    const newEsgProfilingFastLane = get(newEsgProfilingFastLaneState);

    if (isFastLane && typeof newEsgProfilingFastLane !== "undefined") {
      return newEsgProfilingFastLane;
    }

    return esgProfile;
  }
});

export const umsetzungPassivState = selector({
  key: "umsetzungPassivState",
  get: ({ get }) => {
    const esgProfile = get(ESGState);
    return esgProfile.displayEsg !== "nicht wichtig";
  }
});

export const zahlweiseLabelState = selector({
  key: "zahlweiseLabelState",
  get: ({ get }) => {
    const vertragsDetails = get(vertragsDetailsState);
    return vertragsDetails.zahlweise?.value === "einmalig" ? "Laufzeit bei Einmalbeiträgen" : "Beitragszahldauer";
  }
});

export const hasExcursion = selector({
  key: "hasExcursion", // Ein eindeutiger Key für den Selector
  get: ({ get }) => {
    const excursion = get(excursionState);
    return excursion !== undefined; // Gibt true zurück, wenn excursionState nicht undefined ist
  }
});
