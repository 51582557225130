import React from 'react'
import { Grid, Radio, Typography } from '@mui/material'
import { useRecoilState } from 'recoil'
import { newEsgProfilingState } from '../../Recoil/Atoms/PortfolioAtoms'

const QuestionComponent = ({ questionNumber, question, stateName }) => {
    const [newEsgProfiling, setNewEsgProfiling] = useRecoilState(newEsgProfilingState)
    const onRadioBtnChange = (event, stateName) => setNewEsgProfiling({ ...newEsgProfiling, [stateName]: { answer: event.target.value }, question8: { answer: 'Ja' } })
    return (
        <>
            <Grid item xs={9} sx={{ display: "flex", alignItems: "center" }}>
                <Typography>{`${questionNumber}. ${question}`}</Typography>
            </Grid>
            <Grid item xs={1} sx={{ display: "flex", justifyContent: 'center', alignItems: "center" }}>
                <Radio
                    sx={{ color: '#69B977', '&.Mui-checked': { color: '#69B977' } }}
                    checked={newEsgProfiling[stateName].answer === 'unwichtig'}
                    value="unwichtig"
                    onChange={(e) => onRadioBtnChange(e, stateName)} />
            </Grid>
            <Grid item xs={1} sx={{ display: "flex", justifyContent: 'center', alignItems: "center" }}>
                <Radio
                    sx={{ color: '#69B977', '&.Mui-checked': { color: '#69B977' } }}
                    checked={newEsgProfiling[stateName].answer === 'wichtig'}
                    value="wichtig"
                    onChange={(e) => onRadioBtnChange(e, stateName)} />
            </Grid>
            <Grid item xs={1} sx={{ display: "flex", justifyContent: 'center', alignItems: "center" }}>
                <Radio
                    sx={{ color: '#69B977', '&.Mui-checked': { color: '#69B977' } }}
                    checked={newEsgProfiling[stateName].answer === 'sehr wichtig'}
                    value="sehr wichtig"
                    onChange={(e) => onRadioBtnChange(e, stateName)} />
            </Grid>
        </>
    )
}

const QuestionsTableComponent = ({ questions }) => {
    return (
        <Grid container spacing={1} sx={{ pl: '18px' }}>
            {questions.map(item => <QuestionComponent key={item.stateName} questionNumber={item.id} question={item.question} stateName={item.stateName} />)}
        </Grid>
    )
}

export default QuestionsTableComponent