import React, { useState } from 'react'
import { Inertia } from '@inertiajs/inertia'
import { Box, Button, IconButton } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { DataGrid, deDE } from '@mui/x-data-grid';
import AdminLayout from '../../../Layouts/AdminLayout'
import ConfirmDialog from '../../../Components/Shared/ConfirmDialog'
import AddIcon from '@mui/icons-material/Add'

const Index = ({ roles }) => {
    const [isLoading, setIsLoading] = useState(false)
    const [idToDelete, setIdToDelete] = useState(undefined)
    const [deleteDialogOpened, setDeleteDialogOpened] = useState(false)

    const showDeleteDialog = itemId => {
        setDeleteDialogOpened(true)
        setIdToDelete(itemId)
    }

    const deleteRole = async () => {
        Inertia.delete(route('admin.role.destroy', idToDelete), {
            onStart: () => setIsLoading(true),
            onSuccess: () => setIdToDelete(undefined),
            onFinish: () => setIsLoading(false)
        })
    }

    const theme = createTheme(
      deDE, // x-data-grid translations
    );

    const addRole = () => Inertia.get(route('admin.role.create'))

    const editRole = id => Inertia.get(route('admin.role.edit', id))

    return (
        <AdminLayout subHeaderTitle="Rollen" isLoading={isLoading}>
            <Button
                variant="contained"
                sx={{
                    bgcolor: '#C94141',
                    color: 'white',
                    textTransform: 'none',
                    minWidth: '120px',
                    height: '40px',
                    mb: '20px',
                    '&:hover': { bgcolor: '#C94141' }
                }}
                onClick={addRole}
                endIcon={<AddIcon />}
            >
                Rolle
            </Button>
            {
                roles.length > 0 &&
                <Box sx={{ height: 650 }}>
                <ThemeProvider theme={theme}>
                    <DataGrid
                        rows={
                            roles.sort((a, b) => { return b.id - a.id; }).map(item => {
                                return {
                                    id: item.id,
                                    name: item.name,
                                }
                            })
                        }
                        columns={[
                            { field: 'id', headerName: 'ID', resizable: false, flex: 1, maxWidth: 80 },
                            { field: 'name', headerName: 'Name', resizable: false, flex: 1 },
                            {
                                field: 'aktion', headerName: 'Aktion', resizable: false, flex: 1, sortable: false, minWidth: 130,
                                renderCell: (cellObject) => (
                                    <Box>
                                        <IconButton onClick={() => editRole(cellObject.row.id)}>
                                            <EditIcon />
                                        </IconButton>
                                        <IconButton onClick={() => showDeleteDialog(cellObject.row.id)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </Box>
                                )
                            },
                        ]}
                        disableColumnMenu={true}
                        disableSelectionOnClick={true}
                        rowsPerPageOptions={[10]}
                        pageSize={10}
                    />
                    </ThemeProvider>
                </Box>
            }
            <ConfirmDialog
                title="Löschen"
                open={deleteDialogOpened}
                setOpen={setDeleteDialogOpened}
                onConfirm={deleteRole}
            >
                Soll diese Rolle wirklich gelöscht werden?
            </ConfirmDialog>
        </AdminLayout>
    )
}

export default Index