import { Inertia } from '@inertiajs/inertia';
import { usePage } from '@inertiajs/inertia-react';
import { Box, Button, Checkbox, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import InputComponent from '../../Components/Controls/InputComponent';
import LoginLayout from '../../Components/Shared/LoginLayout';
import MainLayout from '../../Layouts/MainLayout';
import { saveLastRouteState, userLicenceState } from '../../Recoil/Atoms/PortfolioAtoms';
import { trackEvent } from '../../Services/PortfolioService';

const Login = ({ canResetPassword }) => {
  const { errors } = usePage().props;
  const [saveLastRoute, setSaveLastRouteState] = useRecoilState(saveLastRouteState);
  const userLicence = useRecoilValue(userLicenceState);
  const [userData, setUserData] = useState({ email: '', password: '', remember: false });
  const { email, password, remember } = userData;
  const { url } = usePage();
  const searchParams = new URLSearchParams(url.split('?')[1]);

  const handleOnChange = (e) => {
    setUserData({ ...userData, [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value });
  };

  const onLogin = () => {
    Inertia.post(
      route('login'),
      { ...userData, saveLastRoute },
      {
        onSuccess: (inertiaPageProps) => {
          trackEvent('Login', userLicence?.id || inertiaPageProps.props.auth.user.licence_id);
          setSaveLastRouteState(false);
          if (searchParams.get('view')) {
            Inertia.get('/archive?view=' + searchParams.get('view'));
          }
        }
      }
    );
  };

  const navigateRegister = () => {
    Inertia.get(route('register'));
  };

  const navigatePasswordForgotten = () => {
    Inertia.get(route('password.request'));
  };

  return (
    <MainLayout>
      <LoginLayout>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography sx={{ color: 'black', fontWeight: 'bold', fontSize: '28px' }}>Login</Typography>
            <Box component="span" sx={{ color: 'black', fontWeight: 'bold', fontSize: '28px' }}>
              s
              <Box component="span" sx={{ color: '#C94141' }}>
                a
              </Box>
              mperform
            </Box>
          </Box>
          <img src="/images/SAM_Logo.png" alt="" width="64px" height="64px" />
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <InputComponent label="E-Mail Adresse" name="email" value={email} onChange={handleOnChange} styles={{ marginBottom: '10px' }} errorMsg={errors.email} />
          <InputComponent type="password" label="Passwort" name="password" value={password} onChange={handleOnChange} errorMsg={errors.password} />
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Checkbox sx={{ ml: '-9px', mr: '5px', '&.Mui-checked': { color: '#C94141' } }} name="remember" checked={remember} onChange={handleOnChange} />
            <Typography>Angemeldet bleiben</Typography>
          </Box>
          <Button
            onClick={onLogin}
            variant="contained"
            sx={{
              mt: '20px',
              bgcolor: '#C94141',
              color: 'white',
              textTransform: 'none',
              height: '40px',
              '&:hover': { bgcolor: '#C94141' }
            }}>
            Anmelden
          </Button>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
          {canResetPassword && (
            <Typography sx={{ cursor: 'pointer', '&:hover': { textDecoration: 'underline' } }} onClick={navigatePasswordForgotten}>
              Passwort vergessen?
            </Typography>
          )}
        </Box>
      </LoginLayout>
    </MainLayout>
  );
};

export default Login;
