import React, { useState } from 'react'
import { Inertia } from '@inertiajs/inertia'
import { Box, Button, IconButton, Link } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { DataGrid, deDE } from '@mui/x-data-grid';
import AdminLayout from '../../../Layouts/AdminLayout'
import ConfirmDialog from '../../../Components/Shared/ConfirmDialog'
import AddIcon from '@mui/icons-material/Add'
import { compareAsc, format, parse, parseISO } from 'date-fns'
import DownloadIcon from '@mui/icons-material/Download'

const Index = ({ insuranceCompanies }) => {
    const [isLoading, setIsLoading] = useState(false)
    const [idToDelete, setIdToDelete] = useState(undefined)
    const [deleteDialogOpened, setDeleteDialogOpened] = useState(false)

    const showDeleteDialog = itemId => {
        setDeleteDialogOpened(true)
        setIdToDelete(itemId)
    }

    const deleteInsuranceCompany = async () => {
        Inertia.delete(route('admin.insurance_company.destroy', idToDelete), {
            onStart: () => setIsLoading(true),
            onSuccess: () => setIdToDelete(undefined),
            onFinish: () => setIsLoading(false)
        })
    }

    const theme = createTheme(
      deDE, // x-data-grid translations
    );

    const addInsuranceCompany = () => Inertia.get(route('admin.insurance_company.create'))

    const editInsuranceCompany = id => Inertia.get(route('admin.insurance_company.edit', id))

    return (
        <AdminLayout subHeaderTitle="Versicherungen" isLoading={isLoading}>
            <Button
                variant="contained"
                sx={{
                    bgcolor: '#C94141',
                    color: 'white',
                    textTransform: 'none',
                    minWidth: '120px',
                    height: '40px',
                    mb: '20px',
                    '&:hover': { bgcolor: '#C94141' }
                }}
                onClick={addInsuranceCompany}
                endIcon={<AddIcon />}
            >
                Versicherung
            </Button>
            <Button
                component={Link}
                href={route('admin.insurance_companies.export')}
                variant="contained"
                sx={{
                    bgcolor: '#C94141',
                    color: 'white',
                    textTransform: 'none',
                    height: '40px',
                    ml: '20px',
                    mb: '20px',
                    '&:hover': { color: 'white', bgcolor: '#C94141' }
                }}
            >
                Versicherungen exportieren
                <DownloadIcon sx={{ ml: '10px' }} />
            </Button>
            {
                insuranceCompanies.length > 0 &&
                <Box sx={{ height: 650 }}>

                <ThemeProvider theme={theme}>
                    <DataGrid
                        rows={
                            insuranceCompanies.sort((a, b) => { return b.id - a.id; }).map(item => {
                                return {
                                    id: item.id,
                                    name: item.name,
                                    wp_category: item.wp_category,
                                    mindestgewicht: item.mindestgewicht,
                                    schrittweite: item.schrittweite,
                                    max_anzahl_fonds: item.max_anzahl_fonds,
                                    ksv_als_rentenfondsersatz: item.ksv_als_rentenfondsersatz,
                                    ksvb_mindestgewicht: item.ksvb_mindestgewicht,
                                    ksvb_schrittweite: item.ksvb_schrittweite,
                                    ksvb_mindestgewicht_laufzeitjahre: item.ksvb_mindestgewicht_laufzeitjahre,
                                    updated_at: item.updated_at,
                                }
                            })
                        }
                        columns={[
                            { field: 'id', headerName: 'ID', resizable: false, flex: 1, maxWidth: 60 },
                            { field: 'name', headerName: 'Name', resizable: false, flex: 1 },
                            { field: 'wp_category', headerName: 'WP Category', resizable: false, flex: 1 },
                            { field: 'mindestgewicht', headerName: 'Mindestgewicht', resizable: false, flex: 1 },
                            { field: 'schrittweite', headerName: 'Schrittweite', resizable: false, flex: 1 },
                            { field: 'max_anzahl_fonds', headerName: 'max. Anzahl Fonds', resizable: false, flex: 1 },
                            { field: 'ksvb_mindestgewicht', headerName: 'konventionelles SV beimischbar - Mindestgewicht', resizable: false, flex: 1 },
                            { field: 'ksvb_schrittweite', headerName: 'konventionelles SV beimischbar - Schrittweite', resizable: false, flex: 1 },
                            {
                                field: 'ksv_als_rentenfondsersatz',
                                headerName: 'konventionelles SV als Rentenfondsersatz', resizable: false, flex: 1,
                                renderCell: (cellObject) => (
                                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                        {cellObject.value === 1 ? 'Ja' : 'Nein'}
                                    </Box>
                                )
                            },
                            {
                                field: 'ksvb_mindestgewicht_laufzeitjahre',
                                headerName: 'konventionelles SV beimischbar - höheres Mindestgewicht spätere Laufzeitjahre',
                                resizable: false, flex: 1,
                                renderCell: (cellObject) => (
                                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                        {cellObject.value === 1 ? 'Ja' : 'Nein'}
                                    </Box>
                                )
                            },
                            {
                                field: 'updated_at', headerName: 'Updated', resizable: false, flex: 1,
                                sortComparator: (v1, v2) => {
                                    const date1 = parse(v1, 'dd.MM.yyyy', new Date())
                                    const date2 = parse(v2, 'dd.MM.yyyy', new Date())
                                    return compareAsc(date1, date2)
                                },
                                renderCell: (cellObject) => (
                                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                        {format(parseISO(cellObject.value), "dd.MM.yyyy")}
                                    </Box>
                                )
                            },
                            {
                                field: 'aktion', headerName: 'Aktion', resizable: false, flex: 1, sortable: false, minWidth: 130,
                                renderCell: (cellObject) => (
                                    <Box>
                                        <IconButton onClick={() => editInsuranceCompany(cellObject.row.id)}>
                                            <EditIcon />
                                        </IconButton>
                                        <IconButton onClick={() => showDeleteDialog(cellObject.row.id)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </Box>
                                )
                            },
                        ]}
                        disableColumnMenu={true}
                        disableSelectionOnClick={true}
                        rowsPerPageOptions={[10]}
                        pageSize={10}
                    />
                    </ThemeProvider>
                </Box>
            }
            <ConfirmDialog
                title="Löschen"
                open={deleteDialogOpened}
                setOpen={setDeleteDialogOpened}
                onConfirm={deleteInsuranceCompany}
            >
                Soll diese Versicherung wirklich gelöscht werden?
            </ConfirmDialog>
        </AdminLayout>
    )
}

export default Index