import React, { useState } from 'react';
import { Box, Button, Grid } from '@mui/material';
import InputComponent from '../../../Components/Controls/InputComponent';
import { Inertia } from '@inertiajs/inertia';
import AdminLayout from '../../../Layouts/AdminLayout';
import SelectComponent from '../../../Components/Controls/SelectComponent';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { v4 as uuidv4 } from 'uuid';

const Create = ({ licences }) => {
  const [keyCode, setKeyCode] = useState(uuidv4());
  const [licence, setLicence] = useState({ label: '', value: '' });
  const licenceOptions = licences.map((x) => {
    return { label: x.name, value: x.id };
  });

  const handleOnChange = (e) => setKeyCode(e.target.value);
  const handleOnSelect = (licence) => setLicence(licence);

  const onCreate = () => Inertia.post(route('admin.key.store'), { key_code: keyCode, licence_id: licence.value });

  const onBack = () => Inertia.get(route('admin.keys'));

  return (
    <AdminLayout subHeaderTitle="Key erstellen">
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Button
          variant="contained"
          sx={{
            bgcolor: 'black',
            color: 'white',
            textTransform: 'none',
            maxWidth: '150px',
            height: '40px',
            mb: '20px',
            '&:hover': { bgcolor: 'black' }
          }}
          startIcon={<KeyboardArrowLeftIcon />}
          onClick={onBack}>
          Zurück
        </Button>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <InputComponent label="Key Code" name="keyCode" value={keyCode} onChange={handleOnChange} />
          </Grid>
          <Grid item xs={12}>
            <SelectComponent label="Lizenz" name="licence" value={licence} onChange={handleOnSelect} options={licenceOptions} />
          </Grid>
        </Grid>
        <Button
          variant="contained"
          sx={{
            bgcolor: '#C94141',
            color: 'white',
            textTransform: 'none',
            minWidth: '120px',
            height: '40px',
            mt: '20px',
            '&:hover': { bgcolor: '#C94141' }
          }}
          onClick={onCreate}>
          Erstellen
        </Button>
      </Box>
    </AdminLayout>
  );
};

export default Create;
